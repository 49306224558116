import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentTypePipe } from './document-type.pipe';
import { FilterYesNoNullValuesPipe } from './filter-yes-no-null-values.pipe';
import { BoolToLocalizedStringPipe } from './bool-to-localized-string.pipe';
import { SplitCurrencyPipe } from './split-currency.pipe';
import { SafePipe } from './safe.pipe';
import { NumberWithSignPipe } from './number-with-sign.pipe';
import { IsAdultPipe } from './is-adult.pipe';
import { IsChildPipe } from './is-child.pipe';
import { IsInfantPipe } from './is-infant.pipe';
import { MomentPipe } from './moment-pipe.pipe';
import { TrainComfortClassPipe, TrainTravelClassPipe } from './train-class-pipe.pipe';

@NgModule({
	imports: [CommonModule],
	declarations: [
		DocumentTypePipe,
		BoolToLocalizedStringPipe,
		FilterYesNoNullValuesPipe,
		SplitCurrencyPipe,
		SafePipe,
		NumberWithSignPipe,
		IsAdultPipe,
		IsChildPipe,
		IsInfantPipe,
		MomentPipe,
		TrainTravelClassPipe,
		TrainComfortClassPipe,
	],
	exports: [
		DocumentTypePipe,
		BoolToLocalizedStringPipe,
		FilterYesNoNullValuesPipe,
		SplitCurrencyPipe,
		SafePipe,
		NumberWithSignPipe,
		IsAdultPipe,
		IsChildPipe,
		IsInfantPipe,
		MomentPipe,
		TrainTravelClassPipe,
		TrainComfortClassPipe,
	],
})
export class UtilitiesModule {}
