<ngx-spinner [name]="'productsLoader'" appRemoveNgxSpinner [isVisible]="isVisible" bdColor="rgba(255,255,255)">
    <ng-container *ngIf="loaderType === 'flight'" [ngTemplateOutlet]="flightLogo"></ng-container>
    <ng-container *ngIf="loaderType === 'hotel'" [ngTemplateOutlet]="hotelLogo"></ng-container>
    <ng-container *ngIf="loaderType === 'train'" [ngTemplateOutlet]="trainLogo"></ng-container>
    <ng-container *ngIf="loaderType === 'car'" [ngTemplateOutlet]="carLogo"></ng-container>
    <div class="d-flex justify-content-center">
        <img *ngIf="logoUrl" class="loader-logo text-center" [src]="logoUrl" width="120" alt="logo">
    </div>
    <h2 class="text-center mr-1 ml-1 search-info" [innerHTML]="loaderText"></h2>
</ngx-spinner>


<ng-template #flightLogo>
    <app-flight-spinner></app-flight-spinner>
</ng-template>

<ng-template #hotelLogo>
    <app-hotel-spinner></app-hotel-spinner>
</ng-template>

<ng-template #trainLogo>
    <app-train-spinner></app-train-spinner>
</ng-template>

<ng-template #carLogo>
    <app-car-spinner></app-car-spinner>
</ng-template>