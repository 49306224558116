import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFieldFormComponent } from './custom-field-form.component';
import { UtilsModule } from '@shared/utils/utils.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFieldDirective } from './dynamic-field.directive';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { InputComponent } from './input/input.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { SelectComponent } from './select/select.component';
import { TextareaComponent } from './textarea/textarea.component';
import { DatetimepickerComponent } from './datetimepicker/datetimepicker.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { ReactiveValidationMessageModule } from '../reactive-validation-message/reactive-validation-message.module';
import { DateTimePickerModule } from '../date-time-picker/date-time-picker.module';
import { ButtonComponent } from './button/button.component';
import { ManageCustomFieldsModule } from '../manage-custom-fields/manage-custom-fields.module';
import { ErrorValidationMessagesComponent } from './error-validation-messages/error-validation-messages.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';

@NgModule({
    imports: [
        CommonModule,
        UtilsModule,
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatRadioModule,
        ReactiveValidationMessageModule,
        DateTimePickerModule,
        BsDatepickerModule.forRoot(),
        ManageCustomFieldsModule,
        MultiSelectModule
    ],
    declarations: [
        CustomFieldFormComponent,
        DynamicFieldDirective,
        CheckboxComponent,
        DatepickerComponent,
        DatetimepickerComponent,
        InputComponent,
        RadioButtonComponent,
        SelectComponent,
        TextareaComponent,
        ButtonComponent,
        ErrorValidationMessagesComponent,
        MultiselectComponent
    ],
    exports: [CustomFieldFormComponent]
})
export class CustomFieldFormModule {}
