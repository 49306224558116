import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlightHistoryListComponent } from './flight-history-list.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FlightSearchHistoryService } from '@app/shared/services/flight/flight-search-history.service';
import { UtilsModule } from '@shared/utils/utils.module';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    UtilsModule,
     TooltipModule.forRoot()
  ],
  declarations: [FlightHistoryListComponent],
  exports: [FlightHistoryListComponent],
  providers: [FlightSearchHistoryService]
})
export class FlightHistoryListModule { }
