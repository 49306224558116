import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountriesComponent } from '@app/shared/components/countries/countries.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { UtilsModule } from '@shared/utils/utils.module';

@NgModule({
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, DropdownModule, UtilsModule
  ],
  declarations: [CountriesComponent],
  exports : [CountriesComponent]
})
export class CountriesModule { }
