import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { formatMask } from '@app/shared/helpers/formatMask';
import * as moment from 'moment';

export function GreaterThanTodayDateValidator(): ValidatorFn {
    return (formControl: AbstractControl): ValidationErrors | null => {
        let futureDateBool = false;
        if (formControl.value) {
            const dateFromFormControlValue = moment(formControl.value, 'MM/YYYY').endOf('month');
            if (dateFromFormControlValue.isValid() && dateFromFormControlValue.isSameOrAfter(moment().endOf('day'))) {
                futureDateBool = true;
            }
            return !futureDateBool ? { greaterThanTodayDate: {} } : null;
        } else {
            return null;
        }
    }
}

export function LocalMaskGreaterThanTodayDateValidator(): ValidatorFn {
    return (formControl: AbstractControl): ValidationErrors | null => {
        let futureDateBool = false;
        if (formControl.value) {
            const dateFromFormControlValue = moment(formControl.value, formatMask()).endOf('day');
            if (dateFromFormControlValue.isValid() && dateFromFormControlValue.isSameOrAfter(moment().endOf('day'))) {
                futureDateBool = true;
            }
            return !futureDateBool ? { greaterThanTodayDate: {} } : null;
        } else {
            return null;
        }
    }
}
