import { Component, Injector, OnInit, Input } from '@angular/core';
import { ThemesLayoutBaseComponent } from '../themes/themes-layout-base.component';
import { AbpSessionService } from 'abp-ng2-module';
import { AppConsts } from '@shared/AppConsts';

@Component({
    selector: 'chat-toggle-button',
    templateUrl: './chat-toggle-button.component.html',
})
export class ChatToggleButtonComponent
    extends ThemesLayoutBaseComponent
    implements OnInit
{
    unreadChatMessageCount = 0;
    chatConnected = false;
    isHost = false;

    @Input() customStyle = 'btn btn-icon btn-dropdown btn-clean btn-lg mr-1';
    isImpersonatedLogin: boolean;

    public constructor(
        injector: Injector,
        private abpSessionService: AbpSessionService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.registerToEvents();
        this.isHost = !this.abpSessionService.tenantId;
    }

    registerToEvents() {
        this.isImpersonatedLogin =
            this.abpSessionService.impersonatorUserId > 0;

        if (this.isImpersonatedLogin) {
            this.chatConnected = true;
        } else {
            window.addEventListener('chatwoot:ready', () => {
                this.chatConnected = true;
                this.addChatListener();
            });
        }
    }

    addChatListener() {
        window.addEventListener(
            'message',
            (e) => {
                if (e.origin.indexOf(AppConsts.chatBaseUrl) !== -1) {
                    if (e.data && e.data.startsWith('chatwoot-widget:')) {
                        const resultJson = JSON.parse(
                            e.data.replace('chatwoot-widget:', '')
                        );
                        if (resultJson.unreadMessageCount) {
                            this.unreadChatMessageCount =
                                resultJson.unreadMessageCount;
                        } else {
                            this.unreadChatMessageCount = 0;
                        }
                    }
                }
            },
            false
        );
    }

    chatOpen(): void {
        this.unreadChatMessageCount = 0;
    }
}
