import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { CountriesModule } from '@app/shared/components/countries/countries.module';
import { ReactiveValidationMessageModule } from '@app/shared/components/reactive-validation-message/reactive-validation-message.module';
import { InputMaskModule } from 'primeng/inputmask';
import { TravelDocumentComponent } from './travel-document.component';
import { UtilsModule } from '@shared/utils/utils.module';

@NgModule({
    imports: [
        CommonModule,
        CountriesModule,
        FormsModule,
        ReactiveFormsModule,
        InputMaskModule,
        ReactiveValidationMessageModule,
        MatCheckboxModule,
        UtilsModule
    ],
    declarations: [TravelDocumentComponent],
    exports: [TravelDocumentComponent]
})
export class TravelDocumentModule { }
