<div class="hotel-loader-container">
    <div class="hotel-loader" app-animation-controller><svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 654.4 495.3">
            <g class='hotel'>
                <path class="hotel--h"
                    d="M255.1 98.3l5.3-2v7.7l3-1.1v-7.7l5.4-2.1v22.8l-5.4 2v-8.4l-3 1.1v8.4l-5.3 1.9V98.3zm3.1 18.6v-8.6l7.3-2.8v8.6l.7-.2V96.7l-.7.3v7.8l-7.3 2.8v-7.8l-.6.2v17.1l.6-.2z" />
                <path class="hotel--o"
                    d="M270 103.9c0-1.6.2-3.3.7-4.9.5-1.6 1.1-3.2 1.9-4.6.8-1.4 1.7-2.6 2.8-3.7 1.1-1.1 2.2-1.8 3.5-2.3 1.2-.5 2.4-.6 3.5-.4 1.1.2 2.1.7 2.9 1.5.8.8 1.5 1.8 2 3.1s.7 2.8.7 4.5c0 1.7-.2 3.3-.7 5-.5 1.7-1.1 3.2-2 4.6-.8 1.4-1.8 2.6-2.9 3.7-1.1 1-2.3 1.8-3.5 2.2-1.2.5-2.4.6-3.5.3-1.1-.2-2-.7-2.8-1.5-.8-.8-1.4-1.8-1.9-3.1-.4-1.3-.7-2.8-.7-4.4zm2.4-.9c0 1.2.2 2.3.5 3.2.3.9.8 1.7 1.4 2.3.6.6 1.3 1 2.1 1.1.8.2 1.6.1 2.5-.3.9-.3 1.8-.9 2.6-1.6.8-.8 1.5-1.7 2.1-2.7.6-1 1.1-2.2 1.4-3.4.3-1.2.5-2.4.5-3.6 0-1.2-.2-2.3-.5-3.3-.3-.9-.8-1.7-1.4-2.3-.6-.6-1.3-.9-2.1-1.1-.8-.2-1.7-.1-2.6.3-.9.4-1.8.9-2.5 1.7-.8.8-1.5 1.7-2.1 2.7-.6 1-1.1 2.1-1.4 3.3-.3 1.3-.5 2.5-.5 3.7zm6.6 5.4c-.8.3-1.6.4-2.3.2-.7-.1-1.3-.5-1.9-1-.5-.5-1-1.2-1.3-2-.3-.8-.5-1.8-.5-2.9 0-1.1.2-2.2.5-3.2.3-1.1.7-2.1 1.3-3 .5-.9 1.2-1.7 1.9-2.4s1.5-1.2 2.3-1.5c.8-.3 1.6-.4 2.3-.3.7.1 1.3.5 1.9 1 .5.5 1 1.2 1.3 2 .3.8.5 1.8.5 2.9 0 1.1-.2 2.2-.5 3.3-.3 1.1-.7 2.1-1.3 3-.5.9-1.2 1.7-1.9 2.4-.8.7-1.5 1.2-2.3 1.5zm-3.6-6.5c0 .7.1 1.3.3 1.8.2.5.4.9.8 1.2.3.3.7.5 1.1.6.4.1.9 0 1.4-.1.5-.2 1-.5 1.4-.9.4-.4.8-.9 1.1-1.5.3-.6.6-1.2.8-1.8.2-.7.3-1.3.3-2s-.1-1.3-.3-1.8c-.2-.5-.4-.9-.8-1.2-.3-.3-.7-.5-1.1-.6-.4-.1-.9 0-1.4.2-.5.2-1 .5-1.4.9-.4.4-.8.9-1.1 1.5-.3.6-.6 1.2-.8 1.8-.2.5-.3 1.2-.3 1.9z" />
                <path class="hotel--t"
                    d="M291.8 90.1l-3.2 1.2v-6.1l12.3-4.8v6.2l-3.3 1.3v17.3l-5.8 2.1V90.1zm3.2 13.2V86.4l3.8-1.5v-.8l-8.2 3.2v.8l3.8-1.5v16.9l.6-.2z" />
                <path class="hotel--e"
                    d="M301.8 80.1l10.6-4.1v6.2l-4.9 1.9v2.6l4.8-1.8v5.9l-4.8 1.8v2.7l5.1-1.9v6.2l-10.8 4V80.1zm8.8 17.4v-.9l-5.4 2v-7.7l5.1-1.9v-.9l-5.1 2v-7.6l5.4-2.1v-.9l-6.1 2.4v17.9l6.1-2.3z" />
                <path class="hotel--l"
                    d="M314.2 75.2l6.1-2.4v17.6l5.1-1.9v6.3L314.2 99V75.2zm9.7 17.4v-.9l-6.2 2.3V76.8l-.7.2v18.1l6.9-2.5z" />
            </g>
            <g class='building'>
                <path class="facade--front" d="M329.6 67.7l72.8-29.8v29l-72.8 26.2z" />
                <path class="facade--side" d="M454.9 92.4l-24.8-17.7.1 11.8-27.8-19.6v-29l52.5 40.6z" />
                <path class="building--front" d="M430.1 353.8l-196 .3V134.5l196-69.9z" />
                <path class="building--side" d="M488.5 124.4l-46-32c-2-1.4-4.7 0-4.7 2.5v259l50.7.1V124.4z" />
                <path class="building--side" d="M488.5 116.6L438 80.3c-3.3-2.4-7.9 0-7.9 4.1v6.2-26l58.4 43.5v8.5z" />
            </g>
            <g class='windows opening'>
                <path class="window" d="M402.4 122l-25.8 7.7v-28l25.8-8.6z" />
                <path class="window" d="M352.3 136.9l-22.7 6.7v-26.3l22.7-7.6z" />
                <path class="window" d="M308.2 149.9l-20.1 6v-24.8l20.1-6.7z" />
                <path class="window" d="M251.1 143.4l18-5.9v24l-18 5.3z" />
                <path class="window" d="M269.1 209.6l-18 4v-23.4l18-4.6z" />
                <path class="window" d="M308.2 200.9l-20.1 4.5v-24.7l20.1-5.3z" />
                <path class="window" d="M352.3 191.1l-22.7 5.1v-26.3l22.7-5.9z" />
                <path class="window" d="M402.4 180l-25.8 5.7v-28l25.8-6.7z" />
                <path class="window" d="M402.4 237.9l-25.8 3.9v-28l25.8-4.8z" />
                <path class="window" d="M352.3 245.4l-22.7 3.4v-26.3l22.7-4.2z" />
                <path class="window" d="M308.2 252l-20.1 2.9v-24.7l20.1-3.7z" />
                <path class="window" d="M269.1 257.8l-18 2.6V237l18-3.3z" />
                <path class="window" d="M269.1 305.9l-18 1.3v-23.4l18-2z" />
                <path class="window" d="M308.2 303l-20.1 1.5v-24.8l20.1-2.2z" />
                <path class="window" d="M352.3 299.7l-22.7 1.7v-26.3l22.7-2.6z" />
                <path class="window" d="M402.4 294.5l-25.8 2v-26.7l25.8-2.9z" />
            </g>
            <g class='lobby'>
                <path class="window" d="M362.4 313.5l40-2.2v28.4l-40 .8z" />
                <path class="window" d="M278.2 318.3l-27.1 1.5v22.5l27.1-.5z" />
                <path class="door" d="M318.7 315.5l-30.6 1.6v37l30.6-.2z" />
                <path class="door" d="M352.3 313.5l-32.1 1.8V354h32.1z" />
            </g>
            <g class='awning'>
                <path class="awning-bottom" d="M179.7 307.2l182.5-15.4 53.2 7.4-177.8 12.3z" />
                <path class="awning-front" d="M362.2 291.8l-182.5 15.4v-4.4L362.2 286z" />
                <path class="awning--side" d="M362.2 291.8l53.2 7.4v-5.3l-53.2-7.9z" />
                <path class="pole" d="M189.8 307.1c-1.2 0-1.6.1-1.6.1v46.9h1.6v-.1h.9v-46.9c0 .1.2 0-.9 0z" />
                <path class="pole" d="M362.2 294c-1.2 0-1.9.2-1.9.2V354h3.2v-59.7c-.1-.1-.1-.3-1.3-.3z" />
            </g>
            <path class="ground" d="M181.2 353.6h320v1h-320z" />
        </svg>
    </div>
</div>
