import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[app-animation-controller]',
})
export class AnimationControllerDirective implements OnInit {

    private windowsNumber = 0;
    private maxWindows: number;

    constructor(private element: ElementRef<HTMLElement>, private renderer: Renderer2) {
    }

    ngOnInit(): void {
        this.windowsAnimation();
    }

    private windowsAnimation(reopen?: boolean) {
        const windows: HTMLElement[] = Array.from(this.element.nativeElement.querySelectorAll('.windows > .window'));
        const windowsElement: HTMLElement = this.element.nativeElement.querySelector('.windows');

        if (reopen) {
            this.windowsNumber = 0;
            this.renderer.addClass(windowsElement, 'opening');
        }

        this.maxWindows = windows.length;
        if (!reopen) {
            for (let window of windows) {
                window.addEventListener('animationend', () => {
                    this.windowsNumber++;
                    if (this.windowsNumber === this.maxWindows) {
                        setTimeout(() => {
                            this.renderer.removeClass(
                                windowsElement,
                                'opening'
                            );
                            setTimeout(() => {
                                this.windowsAnimation(true);
                            }, 1500);
                        }, 1500);
                    }
                });
            }
        }
    }
}
