import { Injectable, EventEmitter, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FlightSerpItem, FieldsEmailInput, AvailabilityItineraryMulticityDTO, IFlightSerpItem, FlightSerpShareServiceProxy, MessageToCustomerInput, MessageToAgentInput } from '@shared/service-proxies/service-proxies';

@Injectable()
export class ShareSerpService {
    @Output() fieldsEmailEmitter = new EventEmitter<FieldsEmailInput>();
    @Output() enableFlightShareModeEmitter = new EventEmitter<boolean>();
    @Output() selectedFlightShareItemEmitter = new EventEmitter<FlightSerpItem>();
    @Output() shareFlightSelectedItemsArrayEmitter = new EventEmitter<FlightSerpItem[]>();
    @Output() openResponseShareModalEmitter = new EventEmitter<any>(); // Il tipo è any perché può restituire un object o un boolean

    //share variables
    enableFlightShareMode: boolean = false;
    shareFlightSelectedItemsArray: Array<FlightSerpItem> = [];
    selectedFlightShareItem: FlightSerpItem;
    emailFields: FieldsEmailInput;

    constructor(private flightSerpShare: FlightSerpShareServiceProxy) { }

    //shareMode section
    getFlightShareMode(): Observable<any> {
        return of(this.enableFlightShareMode);
    }

    initSelectedToShare(arrayItems) {
        arrayItems.map(item => {
            item.selectedToShare = false;
        });
    }

    resetShareVariables() {
        this.enableFlightShareMode = false;
        this.shareFlightSelectedItemsArray = [];
        this.selectedFlightShareItem = null;
        this.enableFlightShareModeEmitter.emit(this.enableFlightShareMode);
        this.shareFlightSelectedItemsArrayEmitter.emit(this.shareFlightSelectedItemsArray);
        this.selectedFlightShareItemEmitter.emit(this.selectedFlightShareItem);
    }

    setFlightEnableShareMode(value: boolean) {
        this.enableFlightShareMode = value;
        this.enableFlightShareModeEmitter.emit(this.enableFlightShareMode);
        if (!this.enableFlightShareMode) {
            this.resetShareVariables();
        }
    }

    setEmailFields(emailFields: FieldsEmailInput) {
        this.emailFields = emailFields;
        this.fieldsEmailEmitter.emit(emailFields);
    }

    getEmailFields(): Observable<any> {
        return of(this.emailFields);
    }

    addToFlightShareSelectedArray(shareSelectedItem: FlightSerpItem) {
        this.shareFlightSelectedItemsArray.push(shareSelectedItem);
        this.shareFlightSelectedItemsArrayEmitter.emit(this.shareFlightSelectedItemsArray);
    }

    private getAllFlightShareItem(data: Array<AvailabilityItineraryMulticityDTO>): Array<FlightSerpItem> {
        const flightSerpArray: Array<FlightSerpItem> = [];
        data.map(itinerary => {
            flightSerpArray.push(new FlightSerpItem({
                groupItineraryId: itinerary.idGroup,
                itineraryId: itinerary.id,
                pricingInformationId: itinerary.selectedPricingInformation.id
            }));
        });
        return flightSerpArray;
    }

    /**
     * Select all element on flight search
     * @param data the data to find all item
     */
    selectAllElement(data: Array<AvailabilityItineraryMulticityDTO>) {
        this.shareFlightSelectedItemsArray = this.getAllFlightShareItem(data);
        this.shareFlightSelectedItemsArrayEmitter.emit(this.shareFlightSelectedItemsArray);
    }

    /**
     * get all selected item
     */
    getAllSelectedItem() {
        return this.shareFlightSelectedItemsArray;
    }

    getSelectedflightShareItem(): FlightSerpItem {
        return this.selectedFlightShareItem;
    }
    /**
     * Deselect all element on flight search
     */
    deselectAllElement() {
        this.shareFlightSelectedItemsArray = [];
        this.shareFlightSelectedItemsArrayEmitter.emit(this.shareFlightSelectedItemsArray);
    }

    /**
     * check if an item of the serp is selected
     * @param flightSerpItem the data provided to find the item
     */
    isItemSelected(flightSerpItem: IFlightSerpItem): boolean {
        return this.shareFlightSelectedItemsArray.filter(element => element.groupItineraryId === flightSerpItem.groupItineraryId &&
            element.itineraryId === flightSerpItem.itineraryId &&
            element.pricingInformationId === flightSerpItem.pricingInformationId)
            .length > 0;
    }

    changePricingInformationId(idGroup: string, idItinerary: string, idPricingInformation: string) {
        let findIndexItem = this.shareFlightSelectedItemsArray.findIndex(item => item.groupItineraryId === idGroup && item.itineraryId === idItinerary);
        if (findIndexItem > -1) {
            this.shareFlightSelectedItemsArray[findIndexItem].pricingInformationId = idPricingInformation;
            this.shareFlightSelectedItemsArrayEmitter.emit(this.shareFlightSelectedItemsArray);
        }
    }

    removeFlightShareSelectedItem(idGroup: string, idItinerary: string, idPricingInformation: string) {
        let findIndexItem = this.shareFlightSelectedItemsArray.findIndex(item => item.groupItineraryId === idGroup && item.itineraryId === idItinerary && item.pricingInformationId === idPricingInformation);
        if (findIndexItem > -1) {
            this.shareFlightSelectedItemsArray.splice(findIndexItem, 1);
            this.shareFlightSelectedItemsArrayEmitter.emit(this.shareFlightSelectedItemsArray);
        }
    }

    setSelectedFlightSerpItem(idGroup: string, idItinerary: string, idPricingInformation: string) {
        this.selectedFlightShareItem = new FlightSerpItem();
        this.selectedFlightShareItem.itineraryId = idItinerary;
        this.selectedFlightShareItem.groupItineraryId = idGroup;
        this.selectedFlightShareItem.pricingInformationId = idPricingInformation;
        this.selectedFlightShareItemEmitter.emit(this.selectedFlightShareItem);
    }

    clearSelectedFlightSerpItem() {
        this.selectedFlightShareItem = null;
        this.selectedFlightShareItemEmitter.emit(this.selectedFlightShareItem);
    }

    openResponseShareModal(url?) {
        if (url) {
            this.openResponseShareModalEmitter.emit({ url });
        } else {
            this.openResponseShareModalEmitter.emit(true);
        }
    }

    closeResponseShareModal() {
        this.openResponseShareModalEmitter.emit(false);
    }

    /**
     * method to generate the link to share on the serp
     */
    generateLink(message: MessageToCustomerInput): Observable<string> {
        return this.flightSerpShare.createShareSerpLink(message);
    }

    generateCheckoutLink(message: MessageToAgentInput): Observable<string> {
        return this.flightSerpShare.createCheckoutLink(message);
    }
}
