import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgxSpinnerComponent } from 'ngx-spinner';

@Directive({
    selector: '[appRemoveNgxSpinner]'
})
export class RemoveNgxSpinnerDirective implements OnChanges {
    @Input() isVisible: boolean;

    constructor(private spinner: NgxSpinnerComponent) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isVisible.currentValue) {
            const spinnerElement = this.spinner.getClass(this.spinner.type, this.spinner.size);
            const spinnerClass = `.${spinnerElement.replace(' ', '.')}`;
            const interval = setInterval(() => {
                const spinner = document.querySelector(spinnerClass);
                if (spinner) {
                    const classes = spinnerElement.split(' ');
                    classes.map(className => {
                        spinner.classList.remove(className);
                    });
                    clearInterval(interval);
                }
            }, 10);
        }
    }
}
