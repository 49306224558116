import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';
import { HotelServiceProxyExtended, ReservationServiceProxyExtended, CarServiceProxyExtended, TripServiceProxyExtended } from './service-proxies-extension';
import { ApiInterceptor } from '@shared/service-proxies/api.interceptor';

@NgModule({
    providers: [
        ApiServiceProxies.AgenciesServiceProxy,
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.CenterOfCostsServiceProxy,
        ApiServiceProxies.CompanyHQServiceProxy,
        ApiServiceProxies.OrganizationUnitTypesServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.DashboardCustomizationServiceProxy,
        ApiServiceProxies.ReservationServiceProxy,
        ApiServiceProxies.CountryServiceProxy,
        ApiServiceProxies.FeeServiceProxy,
        ApiServiceProxies.HotelServiceProxy,
        ApiServiceProxies.RegistrationAgenciesServiceProxy,
        ApiServiceProxies.AirlineCompanyServiceProxy,
        ApiServiceProxies.AgencyDashboardServiceProxy,
        ApiServiceProxies.AgencyMasterDashboardServiceProxy,
        ApiServiceProxies.ValidatingCarrierServiceProxy,
        ApiServiceProxies.PaymentProfileServiceProxy,
        ApiServiceProxies.StateServiceProxy,
        ApiServiceProxies.FlightSerpShareServiceProxy,
        ApiServiceProxies.FeedAccountantSettingsServiceProxy,
        ApiServiceProxies.CompanyServiceProxy,
        ApiServiceProxies.UserProfilesServiceProxy,
        ApiServiceProxies.TripServiceProxy,
        ApiServiceProxies.ApprovalProcessServiceProxy,
        ApiServiceProxies.CommonServiceProxy,
        ApiServiceProxies.BookingToolTenantSettingsServiceProxy,
        ApiServiceProxies.AgencyFeeServiceProxy,
        HotelServiceProxyExtended,
        ReservationServiceProxyExtended,
        CarServiceProxyExtended,
        TripServiceProxyExtended,
        ApiServiceProxies.EventSyncServiceProxy,
        ApiServiceProxies.AirportsServiceProxy,
        ApiServiceProxies.AircraftsServiceProxy,
        ApiServiceProxies.AirlinesServiceProxy,
        ApiServiceProxies.AirlineRoutesServiceProxy,
        ApiServiceProxies.TerminalServiceProxy,
        ApiServiceProxies.TrainServiceProxy,
        ApiServiceProxies.TrainSerpShareServiceProxy,
        ApiServiceProxies.StationsServiceProxy,
        ApiServiceProxies.RegionServiceProxy,
        ApiServiceProxies.StructuresServiceProxy,
        ApiServiceProxies.CarServiceProxy,
        ApiServiceProxies.CarLocationsServiceProxy,
        ApiServiceProxies.FlightServiceProxy,
        ApiServiceProxies.AssistedBookingFlightServiceProxy,
        ApiServiceProxies.AssistedBookingTrainServiceProxy,
        ApiServiceProxies.AssistedBookingHotelServiceProxy,
        ApiServiceProxies.AssistedBookingCarServiceProxy,
        ApiServiceProxies.AssistedBookingTripServiceProxy,
        ApiServiceProxies.ReasonServiceProxy,
        ApiServiceProxies.TripExternalApprovalServiceProxy,
        ApiServiceProxies.TrainSettingsServiceProxy,
        ApiServiceProxies.SelfAgencyServiceProxy,
        ApiServiceProxies.AnalyticsDashboardServiceProxy,
        ApiServiceProxies.WebhookEventServiceProxy,
        ApiServiceProxies.WebhookSubscriptionServiceProxy,
        ApiServiceProxies.WebhookSendAttemptServiceProxy,
        ApiServiceProxies.UserDelegationServiceProxy,
        ApiServiceProxies.DynamicPropertyServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyServiceProxy,
        ApiServiceProxies.DynamicPropertyValueServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }