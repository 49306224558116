import { Injectable } from '@angular/core';
import { ApplicationInsights, ITelemetryItem, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { AppConsts } from '@shared/AppConsts';

@Injectable({
	providedIn: 'root',
})
export class MyMonitoringService {
	appInsights: ApplicationInsights;
	constructor() {}

	loadAppInsights() {
		this.appInsights = new ApplicationInsights({
			config: {
				instrumentationKey: AppConsts.instrumentationKey,
				enableAutoRouteTracking: true, // option to log all route changes
				distributedTracingMode: DistributedTracingModes.AI,
      			enableCorsCorrelation: true,
			},
		});

		this.appInsights.loadAppInsights();

		var telemetryInitializer = (envelope : ITelemetryItem) => {
			envelope.tags["ai.cloud.role"] = "webapp";
			envelope.tags["environment"] = AppConsts.environmentName;
		};
		this.appInsights.addTelemetryInitializer(telemetryInitializer);
	}

	setUserId(userId: string, username: string) {
		if (this.appInsights) {
			this.appInsights.setAuthenticatedUserContext(username, userId.toString());
		}
	}

	logPageView(name?: string, url?: string) {
		// option to call manually
		if (this.appInsights) {
			this.appInsights.trackPageView({
				name: name,
				uri: url,
			});
		}
	}

	logEvent(name: string, properties?: { [key: string]: any }) {
		if (this.appInsights) {
			this.appInsights.trackEvent({ name: name }, properties);
		}
	}

	logMetric(name: string, average: number, properties?: { [key: string]: any }) {
		if (this.appInsights) {
			this.appInsights.trackMetric({ name: name, average: average }, properties);
		}
	}

	logException(exception: Error, severityLevel?: number) {
		if (this.appInsights) {
			this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
		}

		console.error(exception);
	}

	logTrace(message: string, properties?: { [key: string]: any }) {
		if (this.appInsights) {
			this.appInsights.trackTrace({ message: message }, properties);
		}
	}
}
