import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfigDto, InputTypeDto } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
})
export class InputComponent implements OnInit {
    @Input() field: FieldConfigDto;
    @Input() group: UntypedFormGroup;
    @Input() readonly: boolean;

    inputType: string;

    constructor() {}

    ngOnInit() {
        this.getInputType();
    }

    updateState() {
        if(this.readonly) {
            this.group.get(this.field.name).disable();
        }
    }

    getInputType() {
        const inputType = Object.entries(InputTypeDto);
        this.inputType = inputType.find((type) => (type[1] as InputTypeDto) === this.field.inputType)[0].toLowerCase();
    }
}
