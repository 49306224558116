import { EventEmitter, Injectable } from '@angular/core';
import { AirportEmitter, DateEmitter, KeyValuePair, RegularFlightModel } from '@app/shared/models/ClientFlightModels';
import { FormSearchFlightClientService } from '@app/shared/services/flight/form-search-flight.service';
import { flightPaxType, SelectionType } from '@shared/AppBusiness';
import { AppConsts } from '@shared/AppConsts';
import { AirportDTO, AvailabilityRequestMulticityDTO, CompaniesFilterTypeDto, FareRestrictionsTypeDto, LuggageFilterTypeDto, MulticityDTO, PassengerTypeDTO, TripTypeDTO } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { DateRangeEmitter } from '../../models/ClientFlightModels';


@Injectable()
export class AvailabilityRequestMulticityDTOService {
    availabilityRequestMulticityDTO: AvailabilityRequestMulticityDTO = new AvailabilityRequestMulticityDTO();
    regularFlightModel = new RegularFlightModel();
    multicityFlightModel: MulticityDTO[] = [];
    multicityFlightEmitter = new EventEmitter<MulticityDTO[]>();
    originAirportEmitter = new EventEmitter<AirportEmitter>();
    destinationAirportEmitter = new EventEmitter<AirportEmitter>();
    departureDateEmitter = new EventEmitter<DateEmitter>();
    returnDateEmitter = new EventEmitter<DateEmitter>();
    totalPassengerEmitter = new EventEmitter<number>();
    cabinEmitter = new EventEmitter<KeyValuePair<string, string>>();
    paxCompositionArrayEmitter = new EventEmitter<PassengerTypeDTO[]>();
    paxCompositionEmitter = new EventEmitter<PassengerTypeDTO>();
    tripTypeEmitter = new EventEmitter<number>();
    nearAirportsEmitter = new EventEmitter<boolean>();
    excludeNightStopsEmitter = new EventEmitter<boolean>();
    fareRestrictionsEmitter = new EventEmitter<boolean>();
    negotiatedFaresOnlyEmitter = new EventEmitter<boolean>();
    companiesFilterTypeEmitter = new EventEmitter<CompaniesFilterTypeDto>();
    availabilityRequestMulticityDTOEmitter = new EventEmitter<AvailabilityRequestMulticityDTO>();
    regularFlightEmitter = new EventEmitter<RegularFlightModel>();
    dateRangeEmitter = new EventEmitter<DateRangeEmitter>();
    accountCodeEmitter = new EventEmitter<string>();
    customerCodeEmitter = new EventEmitter<string>();
    directOnlyEmitter = new EventEmitter<boolean>();

    constructor(private _formSearchFlightService: FormSearchFlightClientService) {
        this.setAvailabilityRequestMulticityDTO();
    }
    initDefaultAvailabilityRequestMulticity() {
        this.availabilityRequestMulticityDTO.cabin = 'Y';
        this.availabilityRequestMulticityDTO.tripType = TripTypeDTO.RoundTrip;
        this.availabilityRequestMulticityDTO.nearAirports = false;
        this.availabilityRequestMulticityDTO.luggageFilterType = LuggageFilterTypeDto.None;
        this.availabilityRequestMulticityDTO.fareRestrictions = FareRestrictionsTypeDto.Undefined;
        this.availabilityRequestMulticityDTO.excludeNightStops = false;
        this.availabilityRequestMulticityDTO.companiesFilterType = CompaniesFilterTypeDto.IncludeCompaniesOnly;
        this.createPaxComposition();
        this.availabilityRequestMulticityDTO.legs = new Array<MulticityDTO>();
        this.createRegularFlightModel();
        this.createMulticityFlightModel();
    }
    setNearAirports(value: boolean) {
        this.availabilityRequestMulticityDTO.nearAirports = value;
        this.nearAirportsEmitter.emit(value);
    }

    setExcludeNightStops(value: boolean) {
        this.availabilityRequestMulticityDTO.excludeNightStops = value;
        this.excludeNightStopsEmitter.emit(value);
    }

    setFareRestrictions(value) {
        this.availabilityRequestMulticityDTO.fareRestrictions = value;
        this.fareRestrictionsEmitter.emit(value);
    }

    setNegotiatedFaresOnly(value: boolean) {
        this.availabilityRequestMulticityDTO.negotiatedFaresOnly = value;
        this.negotiatedFaresOnlyEmitter.emit(value);
    }

    setAccountCode(accountCode) {
        this.availabilityRequestMulticityDTO.accountCode = accountCode;
        this.accountCodeEmitter.emit(accountCode);
    }

    setCustomerCode(customerCode) {
        this.availabilityRequestMulticityDTO.customerCode = customerCode;
        this.customerCodeEmitter.emit(customerCode);
    }

    setTripType(value) {
        this.availabilityRequestMulticityDTO.tripType = value;
        this.tripTypeEmitter.emit(value);
    }
    getTripType() {
        return of(this.availabilityRequestMulticityDTO.tripType);
    }
    setCabin(cabin) {
        this.availabilityRequestMulticityDTO.cabin = cabin;
        this.cabinEmitter.emit(cabin);
    }
    setAvailabilityRequestMulticityDTO(requestMulticityDTO?) {
        if (requestMulticityDTO) {
            this.setNearAirports(requestMulticityDTO.nearAirports);
            this.setTripType(requestMulticityDTO.tripType);
            this.setCabin(requestMulticityDTO.cabin);
            this.setExcludeNightStops(requestMulticityDTO.excludeNightStops);
            this.setDirectOnly(requestMulticityDTO.directOnly);
            this.setAirAlliancesFilter(requestMulticityDTO.alliancesFilter);
            this.setCompaniesFilterType(requestMulticityDTO.companiesFilterType);
            if (!!requestMulticityDTO.customerCode && requestMulticityDTO.customerCode !== '') {
                this.setCustomerCode(requestMulticityDTO.customerCode);
            }
            if (!!requestMulticityDTO.accountCode && requestMulticityDTO.accountCode !== '') {
                this.setAccountCode(requestMulticityDTO.accountCode);
            }
            this.setFareRestrictions(requestMulticityDTO.fareRestrictions);
            this.setNegotiatedFaresOnly(requestMulticityDTO.negotiatedFaresOnly);
            this.availabilityRequestMulticityDTO.luggageFilterType = requestMulticityDTO.luggageFilterType;
            this.availabilityRequestMulticityDTO.companiesFilter = requestMulticityDTO.companiesFilter;
            if (requestMulticityDTO.tripType === TripTypeDTO.Multicity) {
                this.setMulticityFlightModel(requestMulticityDTO.legs);
            } else {
                this.createRegularFlightModel(requestMulticityDTO.legs);
            }
            this.checkPaxComposition(requestMulticityDTO);
        } else {
            this.initDefaultAvailabilityRequestMulticity();
        }
        this.availabilityRequestMulticityDTOEmitter.emit();
    }
    getAvailabilityFlightRequest(): Observable<any> {
        return of(this.availabilityRequestMulticityDTO);
    }
    getMulticityFlightModel(): Observable<any> {
        return of(this.multicityFlightModel);
    }
    getRegularFlight(): Observable<any> {
        return of(this.regularFlightModel);
    }
    //regularFlightModel  and multicityFlightModel sections
    createRegularFlightModel(multicityModel?: MulticityDTO[]) {
        this.regularFlightModel = new RegularFlightModel();
        if (!!!multicityModel || multicityModel.length === 0) {
            this.regularFlightModel.departureDate = moment().add(AppConsts.configSearchFormFlight.daysToAdd, 'day');
            this.regularFlightModel.returnDate =
                this.availabilityRequestMulticityDTO.tripType === TripTypeDTO.OneWay
                    ? undefined
                    : moment().add(AppConsts.configSearchFormFlight.daysToAdd, 'day');
            this.regularFlightModel.arrivalRangeStartTime = null;
            this.regularFlightModel.arrivalRangeEndTime = null;
            this.regularFlightModel.originAirport = new AirportDTO();
            this.regularFlightModel.destinationAirport = new AirportDTO();
        } else {
            this.regularFlightModel.originAirportCode = multicityModel[0].originAirportCode;
            this.regularFlightModel.destinationAirportCode = multicityModel[0].destinationAirportCode;
            this.regularFlightModel.originAirport = multicityModel[0].originAirport;
            this.regularFlightModel.destinationAirport = multicityModel[0].destinationAirport;
            this.regularFlightModel.departureDate = multicityModel[0].departureDate;
            if (typeof multicityModel[0].rangeType !== 'undefined' || multicityModel[0].rangeType !== null) {
                this.regularFlightModel.departureRangeType = multicityModel[0].rangeType;
            }
            let departureDateEmitter = new DateEmitter();
            departureDateEmitter.index = -1;
            departureDateEmitter.date = multicityModel[0].departureDate;
            departureDateEmitter.rangeTimeType = this.regularFlightModel.departureRangeType;
            this.departureDateEmitter.emit(departureDateEmitter);
            this.regularFlightModel.departureRangeStartTime = multicityModel[0].rangeStartTime;
            this.regularFlightModel.departureRangeEndTime = multicityModel[0].rangeEndTime;
            if (this.availabilityRequestMulticityDTO.tripType === TripTypeDTO.RoundTrip) {
                this.regularFlightModel.returnDate = multicityModel[1].departureDate;
                this.regularFlightModel.arrivalRangeStartTime = multicityModel[1].rangeStartTime;
                this.regularFlightModel.arrivalRangeEndTime = multicityModel[1].rangeEndTime;
                if (typeof multicityModel[1].rangeType !== 'undefined' || multicityModel[1].rangeType !== null) {
                    this.regularFlightModel.arrivalRangeType = multicityModel[1].rangeType;
                }
                let returnDateEmitter = new DateEmitter();
                returnDateEmitter.index = -1;
                returnDateEmitter.date = multicityModel[1].departureDate;
                returnDateEmitter.minDate = multicityModel[0].departureDate;
                returnDateEmitter.rangeTimeType = this.regularFlightModel.arrivalRangeType;
                this.returnDateEmitter.emit(returnDateEmitter);
            }
            let airportOrigin = new AirportEmitter();
            airportOrigin.index = -1;
            airportOrigin.airport = multicityModel[0].originAirport;
            this.originAirportEmitter.emit(airportOrigin);
            let airportDestination = new AirportEmitter();
            airportDestination.index = -1;
            airportDestination.airport = multicityModel[0].destinationAirport;
            this.destinationAirportEmitter.emit(airportDestination);
        }
    }
    createMulticityFlightModel(multicityFlightModel?: MulticityDTO[]) {
        if (multicityFlightModel === undefined || multicityFlightModel.length === 0) {
            this.multicityFlightModel = [];
            let multicityDefault = this.createNewLeg();
            this.multicityFlightModel.push(multicityDefault);
        } else {
            this.setMulticityFlightModel(multicityFlightModel);
        }
    }
    createNewLeg(): MulticityDTO {
        let multicityDefault = new MulticityDTO();
        multicityDefault.originAirport = new AirportDTO();
        multicityDefault.destinationAirport = new AirportDTO();
        multicityDefault.departureDate = moment().add(AppConsts.configSearchFormFlight.daysToAddMulticity, 'day');
        multicityDefault.rangeStartTime = multicityDefault.rangeEndTime = null;
        multicityDefault.rangeType = 0;
        return multicityDefault;
    }
    setMulticityFlightModel(legs) {
        legs.forEach((leg, index) => {
            let legToAdd = new MulticityDTO();
            if (leg.originAirport && leg.destinationAirport) {
                this.multicityFlightModel[index] = leg;
            }
        });
        this.multicityFlightEmitter.emit(this.multicityFlightModel);
    }
    setRegularFlightModel(regularRequest) {
        this.regularFlightModel.originAirportCode = regularRequest.originAirportCode;
        this.regularFlightModel.originAirport = regularRequest.originAirport;
        this.regularFlightModel.destinationAirportCode = regularRequest.destinationAirportCode;
        this.regularFlightModel.destinationAirport = regularRequest.destinationAirport;
        this.regularFlightModel.departureDate = regularRequest.departureDate;
        this.regularFlightModel.departureRangeStartTime = regularRequest.departureRangeStartTime;
        this.regularFlightModel.departureRangeEndTime = regularRequest.departureRangeEndTime;
        if (this.availabilityRequestMulticityDTO.tripType === TripTypeDTO.RoundTrip) {
            this.regularFlightModel.returnDate = regularRequest.departureDate;
            this.regularFlightModel.arrivalRangeStartTime = regularRequest.arrivalRangeStartTime;
            this.regularFlightModel.arrivalRangeEndTime = regularRequest.arrivalRangeEndTime;
        } else {
            this.regularFlightModel.returnDate = undefined;
        }
    }
    setOriginAirport(originAirportEmitter: AirportEmitter) {
        if (originAirportEmitter.index === -1) {
            this.regularFlightModel.originAirport = originAirportEmitter.airport;
            this.regularFlightModel.originAirportCode = !!originAirportEmitter.airport ? originAirportEmitter.airport.airportCode : '';
            this.originAirportEmitter.emit(originAirportEmitter);
        } else {
            this.multicityFlightModel[originAirportEmitter.index].originAirport = originAirportEmitter.airport;
            this.originAirportEmitter.emit(originAirportEmitter);
        }
    }

    setDestinationAirport(destinationAirportEmitter: AirportEmitter) {
        if (destinationAirportEmitter.index === -1) {
            this.regularFlightModel.destinationAirport = destinationAirportEmitter.airport;
            this.regularFlightModel.destinationAirportCode = !!destinationAirportEmitter.airport
                ? destinationAirportEmitter.airport.airportCode
                : '';
            this.destinationAirportEmitter.emit(destinationAirportEmitter);
        } else {
            this.multicityFlightModel[destinationAirportEmitter.index].destinationAirport = destinationAirportEmitter.airport;
            this.multicityFlightModel[destinationAirportEmitter.index].destinationAirportCode = !!destinationAirportEmitter.airport
                ? destinationAirportEmitter.airport.airportCode
                : '';
            this.destinationAirportEmitter.emit(destinationAirportEmitter);
        }
    }

    setDepartureDate(dateEmitter: DateEmitter) {
        if (dateEmitter.index === -1) {
            this.regularFlightModel.departureDate = moment(dateEmitter.date);
            this.departureDateEmitter.emit(dateEmitter);
        } else {
            this.multicityFlightModel[dateEmitter.index].departureDate = moment(dateEmitter.date);
            this.departureDateEmitter.emit(dateEmitter);
        }
    }
    setReturnDate(dateEmitter: DateEmitter) {
        if (dateEmitter.index === -1) {
            if (dateEmitter.date) {
                this.regularFlightModel.returnDate = moment(dateEmitter.date);
            } else {
                this.regularFlightModel.returnDate = undefined;
            }
            this.returnDateEmitter.emit(dateEmitter);
        }
    }
    //paxComposition section
    checkPaxComposition(availabilityRequestClientDTO: AvailabilityRequestMulticityDTO) {
        let paxArray = new Array<PassengerTypeDTO>();
        if (availabilityRequestClientDTO.passengers !== undefined) {
            this.availabilityRequestMulticityDTO.passengers = availabilityRequestClientDTO.passengers;
            paxArray = availabilityRequestClientDTO.passengers;
            this.checkPaxComp(paxArray);
            this.paxCompositionArrayEmitter.emit(paxArray);
        } else {
            this.createPaxComposition(paxArray);
        }
    }
    checkPaxComp(paxArray) {
        for (let i = 0; i < paxArray.length; i++) {
            let checkType = this._formSearchFlightService.arrayPax.find(item => item.type == paxArray[i].code);
            if (!checkType) {
                this._formSearchFlightService.setPaxSelectionType(SelectionType.advanced);
                break;
            }
        }
    }
    createPaxComposition(paxComposition?: Array<PassengerTypeDTO>) {
        if (paxComposition === undefined || paxComposition.length === 0) {
            this.availabilityRequestMulticityDTO.passengers = new Array<PassengerTypeDTO>();
            if (AppConsts.configPax.selectionType === SelectionType.default) {
                let pax = new PassengerTypeDTO();
                pax.code = flightPaxType.ADT;
                pax.quantity = 1;
                this.availabilityRequestMulticityDTO.passengers.push(pax);
                let pax2 = new PassengerTypeDTO();
                pax2.code = flightPaxType.CHD;
                pax2.quantity = 0;
                this.availabilityRequestMulticityDTO.passengers.push(pax2);
                let pax3 = new PassengerTypeDTO();
                pax3.code = flightPaxType.INF;
                pax3.quantity = 0;
                this.availabilityRequestMulticityDTO.passengers.push(pax3);
            } else {
                let pax = new PassengerTypeDTO();
                pax.code = flightPaxType.ADT;
                pax.quantity = 1;
                this.availabilityRequestMulticityDTO.passengers.push(pax);
            }
        } else {
            this.availabilityRequestMulticityDTO.passengers = paxComposition;
        }
        this.paxCompositionArrayEmitter.emit(this.availabilityRequestMulticityDTO.passengers);
    }
    setPaxComposition(paxComposition) {
        this.availabilityRequestMulticityDTO.passengers = paxComposition;
        this.paxCompositionArrayEmitter.emit(this.availabilityRequestMulticityDTO.passengers);
    }
    setPaxNumber(paxObj) {
        let paxInPaxComposition = this.availabilityRequestMulticityDTO.passengers.find(pax => pax.code === paxObj.code);
        if (paxInPaxComposition === undefined) {
            this.availabilityRequestMulticityDTO.passengers.push(paxObj);
            this.paxCompositionArrayEmitter.emit(this.availabilityRequestMulticityDTO.passengers);
        } else {
            this.availabilityRequestMulticityDTO.passengers.find(pax => pax.code === paxObj.code).quantity = paxObj.quantity;
            this.availabilityRequestMulticityDTO.passengers.find(pax => pax.code === paxObj.code).ages = paxObj.ages
            this.paxCompositionEmitter.emit(paxObj);
        }
    }
    calcTotalPassenger(): void {
        let totalPassengers = 0;
        this.availabilityRequestMulticityDTO.passengers.forEach(item => (totalPassengers += item.quantity));
        this.totalPassengerEmitter.emit(totalPassengers);
    }
    fillFromRegularFlightModel() {
        this.availabilityRequestMulticityDTO.legs = [];
        let departureLeg = new MulticityDTO();
        departureLeg.originAirportCode = this.regularFlightModel.originAirportCode;
        departureLeg.originAirport = this.regularFlightModel.originAirport;
        departureLeg.destinationAirportCode = this.regularFlightModel.destinationAirportCode;
        departureLeg.destinationAirport = this.regularFlightModel.destinationAirport;
        departureLeg.departureDate = this.regularFlightModel.departureDate;
        departureLeg.rangeStartTime = this.regularFlightModel.departureRangeStartTime;
        departureLeg.rangeEndTime = this.regularFlightModel.departureRangeEndTime;
        if (typeof this.regularFlightModel.departureRangeType !== 'undefined' && this.regularFlightModel.departureRangeType !== null) {
            departureLeg.rangeType = this.regularFlightModel.departureRangeType;
        }
        this.availabilityRequestMulticityDTO.legs.push(departureLeg);
        if (this.availabilityRequestMulticityDTO.tripType === TripTypeDTO.RoundTrip) {
            let returnLeg = new MulticityDTO();
            returnLeg.destinationAirportCode = this.regularFlightModel.originAirportCode;
            returnLeg.destinationAirport = this.regularFlightModel.originAirport;
            returnLeg.originAirportCode = this.regularFlightModel.destinationAirportCode;
            returnLeg.originAirport = this.regularFlightModel.destinationAirport;
            returnLeg.departureDate = this.regularFlightModel.returnDate;
            returnLeg.rangeStartTime = this.regularFlightModel.arrivalRangeStartTime;
            returnLeg.rangeEndTime = this.regularFlightModel.arrivalRangeEndTime;
            if (typeof this.regularFlightModel.arrivalRangeType !== 'undefined' && this.regularFlightModel.arrivalRangeType !== null) {
                returnLeg.rangeType = this.regularFlightModel.arrivalRangeType;
            }
            this.availabilityRequestMulticityDTO.legs.push(returnLeg);
        }
    }
    fillFromMulticityFLightModel() {
        this.availabilityRequestMulticityDTO.legs = [];
        this.multicityFlightModel.forEach(leg => {
            this.availabilityRequestMulticityDTO.legs.push(leg);
        });
    }
    fillLegs() {
        if (this.availabilityRequestMulticityDTO.tripType === TripTypeDTO.Multicity) {
            this.fillFromMulticityFLightModel();
        } else {
            this.fillFromRegularFlightModel();
        }
    }
    setDepartureRangeTime(dateRange: DateRangeEmitter) {
        if (dateRange.index === -1) {
            if (dateRange.field === 'departure') {
                this.regularFlightModel.departureRangeStartTime = dateRange.dateRangeTime.start;
                this.regularFlightModel.departureRangeEndTime = dateRange.dateRangeTime.end;
                this.regularFlightModel.departureRangeType = dateRange.rangeTimeType;
            } else {
                this.regularFlightModel.arrivalRangeStartTime = dateRange.dateRangeTime.start;
                this.regularFlightModel.arrivalRangeEndTime = dateRange.dateRangeTime.end;
                this.regularFlightModel.arrivalRangeType = dateRange.rangeTimeType;
            }
        } else {
            this.multicityFlightModel[dateRange.index].rangeStartTime = dateRange.dateRangeTime.start;
            this.multicityFlightModel[dateRange.index].rangeEndTime = dateRange.dateRangeTime.end;
            this.multicityFlightModel[dateRange.index].rangeType = dateRange.rangeTimeType;
        }
        this.dateRangeEmitter.emit(dateRange);
    }
    resetAdvancedFields() {
        this.multicityFlightModel.forEach(leg => {
            leg.rangeStartTime = undefined;
            leg.rangeEndTime = undefined;
            leg.rangeType = undefined;
        });
        this.multicityFlightEmitter.emit(this.multicityFlightModel);
        this.regularFlightModel.departureRangeStartTime = null;
        this.regularFlightModel.departureRangeEndTime = null;
        this.regularFlightModel.departureRangeType = null;
        this.regularFlightModel.arrivalRangeStartTime = null;
        this.regularFlightModel.arrivalRangeEndTime = null;
        this.regularFlightModel.arrivalRangeType = null;
        this.regularFlightEmitter.emit(this.regularFlightModel);
        this.availabilityRequestMulticityDTO.nearAirports = false;
        this.availabilityRequestMulticityDTO.companiesFilter = [];
        this.availabilityRequestMulticityDTO.companiesFilterType = CompaniesFilterTypeDto.IncludeCompaniesOnly;
        this.availabilityRequestMulticityDTO.accountCode = null;
        this.availabilityRequestMulticityDTO.customerCode = null;
        this.availabilityRequestMulticityDTO.excludeNightStops = false;
        this.availabilityRequestMulticityDTO.alliancesFilter = [];
        this.availabilityRequestMulticityDTO.fareRestrictions = FareRestrictionsTypeDto.Undefined;
        this.availabilityRequestMulticityDTO.luggageFilterType = LuggageFilterTypeDto.None;
        this.availabilityRequestMulticityDTOEmitter.emit();
    }

    setCompaniesFilter(companiesFilter) {
        this.availabilityRequestMulticityDTO.companiesFilter = companiesFilter;
        this.availabilityRequestMulticityDTOEmitter.emit();
    }

    setAirAlliancesFilter(alliancesFilter) {
        this.availabilityRequestMulticityDTO.alliancesFilter = alliancesFilter;
        this.availabilityRequestMulticityDTOEmitter.emit();
    }

    setCompaniesFilterType(companiesFilterType) {
        this.availabilityRequestMulticityDTO.companiesFilterType = companiesFilterType;
        this.companiesFilterTypeEmitter.emit(companiesFilterType);
    }

    setLuggageFilterType(luggageFilterType) {
        this.availabilityRequestMulticityDTO.luggageFilterType = luggageFilterType;
        this.availabilityRequestMulticityDTOEmitter.emit();
    }

    setDirectOnly(directOnly) {
        this.availabilityRequestMulticityDTO.directOnly = directOnly;
        this.directOnlyEmitter.emit(directOnly);
    }
}
