import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarShoppingTableComponent } from './calendar-shopping-table.component';
import { UtilsModule } from '@shared/utils/utils.module';

@NgModule({
  imports: [
    CommonModule,
    UtilsModule
  ],
  declarations: [CalendarShoppingTableComponent],
  exports: [CalendarShoppingTableComponent]
})
export class CalendarShoppingTableModule { }
