import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TwoStepSerpOrderComponent } from './two-step-serp-order.component';
import { UtilsModule } from '@shared/utils/utils.module';

@NgModule({
  imports: [
    CommonModule, UtilsModule
  ],
  declarations: [TwoStepSerpOrderComponent],
  exports: [TwoStepSerpOrderComponent]
})
export class TwoStepSerpOrderModule { }
