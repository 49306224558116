import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricingInformationComponent } from './pricing-information.component';
import { PassengerFarePenaltyPipe } from '@app/shared/pipes/passenger-fare-penalty.pipe';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UtilsModule } from '@shared/utils/utils.module';
import { UtilitiesModule } from '@app/shared/pipes/utilities.module';

@NgModule({
    imports: [
        CommonModule,
        UtilsModule,
        UtilitiesModule,
        ModalModule.forRoot()
    ],
    declarations: [PricingInformationComponent,
        PassengerFarePenaltyPipe],
    exports: [PricingInformationComponent]
})
export class PricingInformationModule { }
