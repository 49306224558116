import { ValidatorTypeDto } from '@app/api/models';

const validationsWithValue: Array<IValidatorInformation> = [
    {
        value: ValidatorTypeDto.Min,
        type: 'number',
    },
    {
        value: ValidatorTypeDto.Max,
        type: 'number',
    },
    {
        value: ValidatorTypeDto.MinLength,
        type: 'number',
    },
    {
        value: ValidatorTypeDto.MaxLength,
        type: 'number',
    },
    {
        value: ValidatorTypeDto.Pattern,
        type: 'text',
    }
];

export const validatorsWithValue = Object.freeze(validationsWithValue);

export interface IValidatorInformation {
    value: ValidatorTypeDto;
    type: 'number' | 'text';
}
