import { Injectable, Output, EventEmitter } from '@angular/core';
import { TrainSerpItem, FieldsEmailInput, TrainSerpShareServiceProxy, AvailabilityItineraryMulticityDTO, ITrainSerpItem, TrainMessageToCustomerInput, TrainMessageToAgentInput, PricingInformationDTO } from '@shared/service-proxies/service-proxies';
import { Observable, of } from 'rxjs';
import { TrainAvailabilityType } from '@app/shared/models/client-train-models';
import * as _ from 'lodash';
import { IAvailabilityItineraryMulticityDTOExtended } from '@shared/interfaces/availability-itinerary-muticity-extended.interface';

@Injectable()
export class TrainShareSerpService {
    @Output() fieldsEmailEmitter = new EventEmitter<FieldsEmailInput>();
    @Output() enableTrainShareModeEmitter = new EventEmitter<boolean>();
    @Output() selectedTrainOutwardShareItemEmitter = new EventEmitter<TrainSerpItem>();
    @Output() selectedTrainReturnShareItemEmitter = new EventEmitter<TrainSerpItem>();
    @Output() shareTrainOutwardSelectedItemsArrayEmitter = new EventEmitter<TrainSerpItem[]>();
    @Output() shareTrainReturnSelectedItemsArrayEmitter = new EventEmitter<TrainSerpItem[]>();
    @Output() openResponseShareModalEmitter = new EventEmitter<any>(); // Il tipo è any perché può restituire un object o un boolean

    enableTrainShareMode: boolean = false;
    shareTrainOutwardSelectedItemsArray: Array<TrainSerpItem> = [];
    shareTrainReturnSelectedItemsArray: Array<TrainSerpItem> = [];
    selectedTrainOutwardShareItem: TrainSerpItem;
    selectedTrainReturnShareItem: TrainSerpItem;
    emailFields: FieldsEmailInput;

    constructor(private trainSerpShare: TrainSerpShareServiceProxy) { }

    //shareMode section
    getTrainShareMode(): Observable<any> {
        return of(this.enableTrainShareMode);
    }

    initSelectedToShare(arrayItems: IAvailabilityItineraryMulticityDTOExtended[]) {
        arrayItems.map(item => {
            item.selectedToShare = false;
        });
    }

    resetShareVariables() {
        this.enableTrainShareMode = false;
        this.shareTrainOutwardSelectedItemsArray = [];
        this.shareTrainReturnSelectedItemsArray = [];
        this.selectedTrainOutwardShareItem = null;
        this.selectedTrainReturnShareItem = null;
        this.enableTrainShareModeEmitter.emit(this.enableTrainShareMode);
        this.shareTrainOutwardSelectedItemsArrayEmitter.emit(this.shareTrainOutwardSelectedItemsArray);
        this.shareTrainReturnSelectedItemsArrayEmitter.emit(this.shareTrainReturnSelectedItemsArray);
        this.selectedTrainOutwardShareItemEmitter.emit(this.selectedTrainOutwardShareItem);
        this.selectedTrainReturnShareItemEmitter.emit(this.selectedTrainReturnShareItem);
    }

    setTrainEnableShareMode(value) {
        this.enableTrainShareMode = value;
        this.enableTrainShareModeEmitter.emit(this.enableTrainShareMode);
        if (!this.enableTrainShareMode) {
            this.resetShareVariables();
        }
    }

    setEmailFields(emailFields: FieldsEmailInput) {
        this.emailFields = emailFields;
        this.fieldsEmailEmitter.emit(emailFields);
    }

    getEmailFields(): Observable<FieldsEmailInput> {
        return of(this.emailFields);
    }

    addToTrainShareSelectedArray(shareSelectedItem: TrainSerpItem, trainTripType: TrainAvailabilityType = TrainAvailabilityType.outward) {
        if (trainTripType === TrainAvailabilityType.return) {
            this.shareTrainReturnSelectedItemsArray.push(shareSelectedItem);
            this.shareTrainReturnSelectedItemsArrayEmitter.emit(this.shareTrainReturnSelectedItemsArray);
        } else {
            this.shareTrainOutwardSelectedItemsArray.push(shareSelectedItem);
            this.shareTrainOutwardSelectedItemsArrayEmitter.emit(this.shareTrainOutwardSelectedItemsArray);
        }
    }

    private getAllTrainShareItem(data: Array<AvailabilityItineraryMulticityDTO>): Array<TrainSerpItem> {
        const TrainSerpArray: Array<TrainSerpItem> = [];
        data.map(itinerary => {
            TrainSerpArray.push(new TrainSerpItem({
                groupItineraryId: itinerary.idGroup,
                itineraryId: itinerary.id,
                pricingInformationIds: this.getPricingInfoIds(itinerary.selectedPricingInformation)
            }));
        });
        return TrainSerpArray;
    }

    getPricingInfoIds(selectedPricingInfo: PricingInformationDTO) {
        return selectedPricingInfo.ticket.map(tkt => tkt.pricingInformation.id);
    }

    /**
     * Select all element on Train search
     * @param data the data to find all item
     */
    selectAllOutwardElement(data: Array<AvailabilityItineraryMulticityDTO>) {
        this.shareTrainOutwardSelectedItemsArray = this.getAllTrainShareItem(data);
        this.shareTrainOutwardSelectedItemsArrayEmitter.emit(this.shareTrainOutwardSelectedItemsArray);
    }

    selectAllReturnElement(data: Array<AvailabilityItineraryMulticityDTO>) {
        this.shareTrainReturnSelectedItemsArray = this.getAllTrainShareItem(data);
        this.shareTrainReturnSelectedItemsArrayEmitter.emit(this.shareTrainReturnSelectedItemsArray);
    }

    /**
     * get all selected item
     */
    getAllOutwardSelectedItem() {
        return this.shareTrainOutwardSelectedItemsArray;
    }

    getAllReturnSelectedItem() {
        return this.shareTrainReturnSelectedItemsArray;
    }

    getSelectedTrainOutwardShareItem(): TrainSerpItem {
        return this.selectedTrainOutwardShareItem;
    }

    getSelectedTrainReturnShareItem(): TrainSerpItem {
        return this.selectedTrainReturnShareItem;
    }

    /**
     * Deselect all element on Train search
     */
    deselectAllElement() {
        this.shareTrainOutwardSelectedItemsArray = [];
        this.shareTrainOutwardSelectedItemsArrayEmitter.emit(this.shareTrainReturnSelectedItemsArray);
        this.shareTrainReturnSelectedItemsArray = [];
        this.shareTrainReturnSelectedItemsArrayEmitter.emit(this.shareTrainReturnSelectedItemsArray);
    }

    /**
     * check if an item of the serp is selected
     * @param TrainSerpItem the data provided to find the item
     */
    isItemSelected(TrainSerpItem: ITrainSerpItem, trainAvailabilityType: TrainAvailabilityType = TrainAvailabilityType.outward): boolean {
        if (trainAvailabilityType === TrainAvailabilityType.outward) {
            return this.shareTrainOutwardSelectedItemsArray.filter(element => element.groupItineraryId === TrainSerpItem.groupItineraryId &&
                element.itineraryId === TrainSerpItem.itineraryId &&
                _.isEqual(element.pricingInformationIds, TrainSerpItem.pricingInformationIds))
                .length > 0;
        } else {
            return this.shareTrainReturnSelectedItemsArray.filter(element => element.groupItineraryId === TrainSerpItem.groupItineraryId &&
                element.itineraryId === TrainSerpItem.itineraryId &&
                _.isEqual(element.pricingInformationIds, TrainSerpItem.pricingInformationIds))
                .length > 0;
        }
    }

    changePricingInformationId(idGroup: string, idItinerary: string, selectedPricing: PricingInformationDTO, trainAvailabilityType: TrainAvailabilityType = TrainAvailabilityType.outward) {
        let priceIds = this.getPricingInfoIds(selectedPricing);
        if (trainAvailabilityType === TrainAvailabilityType.outward) {
            let findIndexItem = this.shareTrainOutwardSelectedItemsArray.findIndex(item => item.groupItineraryId === idGroup && item.itineraryId === idItinerary);
            if (findIndexItem > -1) {
                this.shareTrainOutwardSelectedItemsArray[findIndexItem].pricingInformationIds = priceIds;
                this.shareTrainOutwardSelectedItemsArrayEmitter.emit(this.shareTrainOutwardSelectedItemsArray);
            }
        } else {
            let findIndexItem = this.shareTrainReturnSelectedItemsArray.findIndex(item => item.groupItineraryId === idGroup && item.itineraryId === idItinerary);
            if (findIndexItem > -1) {
                this.shareTrainReturnSelectedItemsArray[findIndexItem].pricingInformationIds = priceIds;
                this.shareTrainReturnSelectedItemsArrayEmitter.emit(this.shareTrainReturnSelectedItemsArray);
            }
        }
    }

    removeTrainShareSelectedItem(idGroup: string, idItinerary: string, trainAvailabilityType: TrainAvailabilityType = TrainAvailabilityType.outward) {
        if (trainAvailabilityType === TrainAvailabilityType.outward) {
            let findIndexItem = this.shareTrainOutwardSelectedItemsArray.findIndex(item => item.groupItineraryId === idGroup && item.itineraryId === idItinerary);
            if (findIndexItem > -1) {
                this.shareTrainOutwardSelectedItemsArray.splice(findIndexItem, 1);
                this.shareTrainOutwardSelectedItemsArrayEmitter.emit(this.shareTrainOutwardSelectedItemsArray);
            }
        } else {
            let findIndexItem = this.shareTrainReturnSelectedItemsArray.findIndex(item => item.groupItineraryId === idGroup && item.itineraryId === idItinerary);
            if (findIndexItem > -1) {
                this.shareTrainReturnSelectedItemsArray.splice(findIndexItem, 1);
                this.shareTrainReturnSelectedItemsArrayEmitter.emit(this.shareTrainReturnSelectedItemsArray);
            }
        }
    }

    setSelectedTrainSerpItem(idGroup: string, idItinerary: string, idPricingInformation: string[], trainAvailabilityType: TrainAvailabilityType = TrainAvailabilityType.outward) {
        if (trainAvailabilityType === TrainAvailabilityType.outward) {
            this.selectedTrainOutwardShareItem = new TrainSerpItem();
            this.selectedTrainOutwardShareItem.itineraryId = idItinerary;
            this.selectedTrainOutwardShareItem.groupItineraryId = idGroup;
            this.selectedTrainOutwardShareItem.pricingInformationIds = idPricingInformation;
            this.selectedTrainOutwardShareItemEmitter.emit(this.selectedTrainOutwardShareItem);
        } else {
            this.selectedTrainReturnShareItem = new TrainSerpItem();
            this.selectedTrainReturnShareItem.itineraryId = idItinerary;
            this.selectedTrainReturnShareItem.groupItineraryId = idGroup;
            this.selectedTrainReturnShareItem.pricingInformationIds = idPricingInformation;
            this.selectedTrainReturnShareItemEmitter.emit(this.selectedTrainReturnShareItem);
        }
    }

    clearSelectedTrainSerpItem(trainAvailabilityType: TrainAvailabilityType = TrainAvailabilityType.outward) {
        if (trainAvailabilityType === TrainAvailabilityType.outward) {
            this.selectedTrainOutwardShareItem = null;
            this.selectedTrainOutwardShareItemEmitter.emit(this.selectedTrainOutwardShareItem);
        } else {
            this.selectedTrainReturnShareItem = null;
            this.selectedTrainReturnShareItemEmitter.emit(this.selectedTrainReturnShareItem);
        }
    }

    openResponseShareModal(url?) {
        if (url) {
            this.openResponseShareModalEmitter.emit({ url });
        } else {
            this.openResponseShareModalEmitter.emit(true);
        }
    }

    closeResponseShareModal() {
        this.openResponseShareModalEmitter.emit(false);
    }

    checkSelectionArrayToShare(array: Array<IAvailabilityItineraryMulticityDTOExtended>) {
        array.map(item => {
            let trainItem = new TrainSerpItem({
                groupItineraryId: item.idGroup,
                itineraryId: item.id,
                pricingInformationIds: this.getPricingInfoIds(item.selectedPricingInformation)
            });
            item.selectedToShare = this.isItemSelected(trainItem);
        });
    }

    /**
     * method to generate the link to share on the serp
     */
    generateLink(message: TrainMessageToCustomerInput): Observable<string> {
        return this.trainSerpShare.createShareSerpLink(message);
    }

    generateCheckoutLink(message: TrainMessageToAgentInput): Observable<string> {
        return this.trainSerpShare.createCheckoutLink(message);
    }
}
