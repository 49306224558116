import { Directive, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, PLATFORM_ID, AfterViewInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

declare const window: any;
const defaultUtilScript = 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.1/js/utils.js';

@Directive({
    selector: '[phoneInput]',
})
export class PhoneInputDirective implements OnInit {
    @Input('phoneValue') phoneValue: string;
    @Output() phoneValueChange: EventEmitter<string> = new EventEmitter<string>();

    @Input('ng2TelInputOptions') ng2TelInputOptions: any = {};
    @Output('hasError') hasError: EventEmitter<boolean> = new EventEmitter();
    @Output('ng2TelOutput') ng2TelOutput: EventEmitter<string> = new EventEmitter<string>();
    @Output('countryChange') countryChange: EventEmitter<any> = new EventEmitter();
    @Output('intlTelInputObject') intlTelInputObject: EventEmitter<any> = new EventEmitter();

    ngTelInput: any;

    constructor(private el: ElementRef,
        @Inject(PLATFORM_ID) private platformId: string) {
    }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.ng2TelInputOptions = {
                ...this.ng2TelInputOptions,
                utilsScript: this.getUtilsScript(this.ng2TelInputOptions)
            };
            this.ngTelInput = window.intlTelInput(this.el.nativeElement, {
                ...this.ng2TelInputOptions
            });

            this.el.nativeElement.addEventListener("countrychange", () => {
                this.countryChange.emit(this.ngTelInput.getSelectedCountryData());
            });

            this.ngTelInput.setNumber(this.phoneValue ? this.phoneValue : "");
            this.phoneValueChange.emit(this.phoneValue);

            this.intlTelInputObject.emit(this.ngTelInput);
        }
        this.onBlur();
    }

    @HostListener('blur') onBlur() {
        let isInputValid: boolean = this.isInputValid();
        let isInputEmpty: boolean = this.isInputEmpty();
        if (isInputEmpty || isInputValid) {
            let region = this.ngTelInput.getSelectedCountryData();
            let telOutput = this.ngTelInput.getNumber();
            this.phoneValue = telOutput;
            this.phoneValueChange.emit(this.phoneValue);
            this.hasError.emit(isInputEmpty || isInputValid);
            if (this.phoneValue) {
                var regexp = new RegExp(`^\\+${region.dialCode}`);
                telOutput = `+${region.dialCode} ${telOutput.replace(regexp, '')}`;
                this.el.nativeElement.value = telOutput;
                this.ng2TelOutput.emit(telOutput);
            }
        } else {
            this.hasError.emit(isInputValid);
        }
    }

    isInputValid(): boolean {
        return this.ngTelInput.isValidNumber();
    }

    isInputEmpty(): boolean {
        return this.ngTelInput.getNumber() === '' || this.ngTelInput.getNumber() === null || this.ngTelInput.getNumber() === 'undefined' || this.ngTelInput.getNumber() === undefined;
    }

    setCountry(country: any) {
        this.ngTelInput.setCountry(country);
    }

    getUtilsScript(options: any) {
        return options.utilsScript || defaultUtilScript;
    }
}
