import { Pipe, PipeTransform, Injector } from '@angular/core';
import { ValidatorTypeDto } from '@app/api/models';
import { AppComponentBase } from '@shared/common/app-component-base';

@Pipe({
    name: 'validationName',
})
export class GetValidationNamePipe extends AppComponentBase implements PipeTransform {
    constructor(injector: Injector) {
        super(injector);
    }

    transform(value: ValidatorTypeDto, args?: any): string {
        let validatorTypeDto: Array<any> = Object.entries(ValidatorTypeDto);
        validatorTypeDto.splice(0, (validatorTypeDto.length / 2));
        const validationValue = validatorTypeDto.find((validation) => validation[1] === value ? validation[0] : undefined);
        return validationValue[0];
    }
}
