/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AgencyProfilePaymentDto } from '../models/agency-profile-payment-dto';
import { AirlinePaymentProfileDto } from '../models/airline-payment-profile-dto';
import { AjaxResponse } from '../models/ajax-response';
import { AjaxResponseOfFileDto } from '../models/ajax-response-of-file-dto';
import { AjaxResponseOfGetPaymentProfileForEditOutput } from '../models/ajax-response-of-get-payment-profile-for-edit-output';
import { AjaxResponseOfPagedResultDtoOfGetPaymentProfileForView } from '../models/ajax-response-of-paged-result-dto-of-get-payment-profile-for-view';
import { ChangeEnabledDto } from '../models/change-enabled-dto';
import { CreatePaymentProfileInputDto } from '../models/create-payment-profile-input-dto';
import { EditPaymentProfileInputDto } from '../models/edit-payment-profile-input-dto';
import { GetAllPaymentProfilesInputDto } from '../models/get-all-payment-profiles-input-dto';

@Injectable({
  providedIn: 'root',
})
export class PaymentProfileService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPaymentsToExcel
   */
  static readonly GetPaymentsToExcelPath = '/api/services/app/PaymentProfile/GetPaymentsToExcel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentsToExcel()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentsToExcel$Response(params?: {
    Filter?: string;
    IsOnlyProfilesNotExpired?: boolean;
    AirlineFilter?: Array<AirlinePaymentProfileDto>;
    Agency?: Array<AgencyProfilePaymentDto>;
    CardHolderNameFilter?: string;
    Code?: string;
    DisplayNumberFilter?: string;
    ExpirationMonthFilter?: string;
    ExpirationYearFilter?: string;
    IsEnabledFilter?: boolean;
    SupplierFilter?: Array<string>;
    IsChecked?: boolean;
    Sorting?: string;
    SkipCount?: number;
    MaxResultCount?: number;
  }): Observable<StrictHttpResponse<AjaxResponseOfFileDto>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentProfileService.GetPaymentsToExcelPath, 'get');
    if (params) {
      rb.query('Filter', params.Filter, {});
      rb.query('IsOnlyProfilesNotExpired', params.IsOnlyProfilesNotExpired, {});
      rb.query('AirlineFilter', params.AirlineFilter, {});
      rb.query('Agency', params.Agency, {});
      rb.query('CardHolderNameFilter', params.CardHolderNameFilter, {});
      rb.query('Code', params.Code, {});
      rb.query('DisplayNumberFilter', params.DisplayNumberFilter, {});
      rb.query('ExpirationMonthFilter', params.ExpirationMonthFilter, {});
      rb.query('ExpirationYearFilter', params.ExpirationYearFilter, {});
      rb.query('IsEnabledFilter', params.IsEnabledFilter, {});
      rb.query('SupplierFilter', params.SupplierFilter, {});
      rb.query('IsChecked', params.IsChecked, {});
      rb.query('Sorting', params.Sorting, {});
      rb.query('SkipCount', params.SkipCount, {});
      rb.query('MaxResultCount', params.MaxResultCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfFileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPaymentsToExcel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentsToExcel(params?: {
    Filter?: string;
    IsOnlyProfilesNotExpired?: boolean;
    AirlineFilter?: Array<AirlinePaymentProfileDto>;
    Agency?: Array<AgencyProfilePaymentDto>;
    CardHolderNameFilter?: string;
    Code?: string;
    DisplayNumberFilter?: string;
    ExpirationMonthFilter?: string;
    ExpirationYearFilter?: string;
    IsEnabledFilter?: boolean;
    SupplierFilter?: Array<string>;
    IsChecked?: boolean;
    Sorting?: string;
    SkipCount?: number;
    MaxResultCount?: number;
  }): Observable<AjaxResponseOfFileDto> {

    return this.getPaymentsToExcel$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfFileDto>) => r.body as AjaxResponseOfFileDto)
    );
  }

  /**
   * Path part for operation paymentProfileGetAll
   */
  static readonly PaymentProfileGetAllPath = '/api/services/app/PaymentProfile/GetAll';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentProfileGetAll()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentProfileGetAll$Response(params?: {
    body?: GetAllPaymentProfilesInputDto
  }): Observable<StrictHttpResponse<AjaxResponseOfPagedResultDtoOfGetPaymentProfileForView>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentProfileService.PaymentProfileGetAllPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfPagedResultDtoOfGetPaymentProfileForView>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentProfileGetAll$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentProfileGetAll(params?: {
    body?: GetAllPaymentProfilesInputDto
  }): Observable<AjaxResponseOfPagedResultDtoOfGetPaymentProfileForView> {

    return this.paymentProfileGetAll$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfPagedResultDtoOfGetPaymentProfileForView>) => r.body as AjaxResponseOfPagedResultDtoOfGetPaymentProfileForView)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentProfileGetAll$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  paymentProfileGetAll$Xml$Response(params?: {
    body?: GetAllPaymentProfilesInputDto
  }): Observable<StrictHttpResponse<AjaxResponseOfPagedResultDtoOfGetPaymentProfileForView>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentProfileService.PaymentProfileGetAllPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfPagedResultDtoOfGetPaymentProfileForView>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentProfileGetAll$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  paymentProfileGetAll$Xml(params?: {
    body?: GetAllPaymentProfilesInputDto
  }): Observable<AjaxResponseOfPagedResultDtoOfGetPaymentProfileForView> {

    return this.paymentProfileGetAll$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfPagedResultDtoOfGetPaymentProfileForView>) => r.body as AjaxResponseOfPagedResultDtoOfGetPaymentProfileForView)
    );
  }

  /**
   * Path part for operation getPaymentProfileForEdit
   */
  static readonly GetPaymentProfileForEditPath = '/api/services/app/PaymentProfile/GetPaymentProfileForEdit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentProfileForEdit()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentProfileForEdit$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfGetPaymentProfileForEditOutput>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentProfileService.GetPaymentProfileForEditPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfGetPaymentProfileForEditOutput>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPaymentProfileForEdit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentProfileForEdit(params?: {
    id?: string;
  }): Observable<AjaxResponseOfGetPaymentProfileForEditOutput> {

    return this.getPaymentProfileForEdit$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfGetPaymentProfileForEditOutput>) => r.body as AjaxResponseOfGetPaymentProfileForEditOutput)
    );
  }

  /**
   * Path part for operation createPaymentProfile
   */
  static readonly CreatePaymentProfilePath = '/api/services/app/PaymentProfile/CreatePaymentProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPaymentProfile()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPaymentProfile$Response(params?: {
    body?: CreatePaymentProfileInputDto
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentProfileService.CreatePaymentProfilePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createPaymentProfile$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPaymentProfile(params?: {
    body?: CreatePaymentProfileInputDto
  }): Observable<AjaxResponse> {

    return this.createPaymentProfile$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPaymentProfile$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createPaymentProfile$Xml$Response(params?: {
    body?: CreatePaymentProfileInputDto
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentProfileService.CreatePaymentProfilePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createPaymentProfile$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createPaymentProfile$Xml(params?: {
    body?: CreatePaymentProfileInputDto
  }): Observable<AjaxResponse> {

    return this.createPaymentProfile$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

  /**
   * Path part for operation editPaymentProfile
   */
  static readonly EditPaymentProfilePath = '/api/services/app/PaymentProfile/EditPaymentProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editPaymentProfile()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editPaymentProfile$Response(params?: {
    body?: EditPaymentProfileInputDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentProfileService.EditPaymentProfilePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editPaymentProfile$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editPaymentProfile(params?: {
    body?: EditPaymentProfileInputDto
  }): Observable<void> {

    return this.editPaymentProfile$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editPaymentProfile$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  editPaymentProfile$Xml$Response(params?: {
    body?: EditPaymentProfileInputDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentProfileService.EditPaymentProfilePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editPaymentProfile$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  editPaymentProfile$Xml(params?: {
    body?: EditPaymentProfileInputDto
  }): Observable<void> {

    return this.editPaymentProfile$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation paymentProfileDelete
   */
  static readonly PaymentProfileDeletePath = '/api/services/app/PaymentProfile/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentProfileDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentProfileDelete$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentProfileService.PaymentProfileDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentProfileDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentProfileDelete(params?: {
    id?: string;
  }): Observable<AjaxResponse> {

    return this.paymentProfileDelete$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

  /**
   * Path part for operation paymentProfileDeleteRange
   */
  static readonly PaymentProfileDeleteRangePath = '/api/services/app/PaymentProfile/DeleteRange';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentProfileDeleteRange()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentProfileDeleteRange$Response(params?: {
    ids?: Array<string>;
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentProfileService.PaymentProfileDeleteRangePath, 'delete');
    if (params) {
      rb.query('ids', params.ids, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentProfileDeleteRange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentProfileDeleteRange(params?: {
    ids?: Array<string>;
  }): Observable<AjaxResponse> {

    return this.paymentProfileDeleteRange$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

  /**
   * Path part for operation paymentProfileSetEnabled
   */
  static readonly PaymentProfileSetEnabledPath = '/api/services/app/PaymentProfile/SetEnabled';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentProfileSetEnabled()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentProfileSetEnabled$Response(params?: {
    body?: ChangeEnabledDto
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentProfileService.PaymentProfileSetEnabledPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentProfileSetEnabled$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentProfileSetEnabled(params?: {
    body?: ChangeEnabledDto
  }): Observable<AjaxResponse> {

    return this.paymentProfileSetEnabled$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentProfileSetEnabled$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  paymentProfileSetEnabled$Xml$Response(params?: {
    body?: ChangeEnabledDto
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentProfileService.PaymentProfileSetEnabledPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentProfileSetEnabled$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  paymentProfileSetEnabled$Xml(params?: {
    body?: ChangeEnabledDto
  }): Observable<AjaxResponse> {

    return this.paymentProfileSetEnabled$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

  /**
   * Path part for operation paymentProfileSetEnabledRange
   */
  static readonly PaymentProfileSetEnabledRangePath = '/api/services/app/PaymentProfile/SetEnabledRange';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentProfileSetEnabledRange()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentProfileSetEnabledRange$Response(params?: {
    enable?: boolean;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentProfileService.PaymentProfileSetEnabledRangePath, 'post');
    if (params) {
      rb.query('enable', params.enable, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentProfileSetEnabledRange$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentProfileSetEnabledRange(params?: {
    enable?: boolean;
    body?: Array<string>
  }): Observable<AjaxResponse> {

    return this.paymentProfileSetEnabledRange$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentProfileSetEnabledRange$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  paymentProfileSetEnabledRange$Xml$Response(params?: {
    enable?: boolean;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentProfileService.PaymentProfileSetEnabledRangePath, 'post');
    if (params) {
      rb.query('enable', params.enable, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentProfileSetEnabledRange$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  paymentProfileSetEnabledRange$Xml(params?: {
    enable?: boolean;
    body?: Array<string>
  }): Observable<AjaxResponse> {

    return this.paymentProfileSetEnabledRange$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

}
