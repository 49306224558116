import { Injectable, EventEmitter } from '@angular/core';
import {
    PassengerTypeDTO, TripTypeDTO,
} from '@shared/service-proxies/service-proxies';
import { FlightPassengerService } from '@app/shared/services/flight/flight-passenger.service';
import {
    Group,
    FlightClassType,
    KeyValuePair,
    Passenger,
    DateRange,
    IDateRange
} from '@app/shared/models/ClientFlightModels';
import { SelectionType } from '@shared/AppBusiness';

@Injectable()
export class FormSearchFlightClientService {
    group: Group;
    flightClasses: FlightClassType;
    arrayPax: Array<Passenger> = new Array<Passenger>();
    arrayAdvancedPax: Array<Passenger> = new Array<Passenger>();
    flightTypes: Array<KeyValuePair<string, number>>;
    selectionTypeEmitter = new EventEmitter<SelectionType>();
    isSearching = new EventEmitter<boolean>();
    timeArrayOptions: Array<KeyValuePair<IDateRange, string>>;
    customTimeOptionsArray: Array<KeyValuePair<IDateRange, string>>;

    constructor(private _flightPassengerService: FlightPassengerService) {
        this.flightTypes = [];
        this.timeArrayOptions = [];
        this.customTimeOptionsArray = [];
        this.flightTypes.push(new KeyValuePair<string, number>('Flight_type_oneway', TripTypeDTO.OneWay));
        this.flightTypes.push(new KeyValuePair<string, number>('Flight_type_roundtrip', TripTypeDTO.RoundTrip));
        this.flightTypes.push(new KeyValuePair<string, number>('Flight_type_multicity', TripTypeDTO.Multicity));
        this.flightClasses = _flightPassengerService.flightClasses;
        this.arrayPax = _flightPassengerService.arrayPax;
        this.arrayAdvancedPax = _flightPassengerService.arrayAdvancedPax;
        this.initTimeArrayOptions();
        this.initTimeCustomOptions();
    }
    initTimeArrayOptions() {
        for (let i = 0; i < 24; i++) {
            let startString = (i <= 9 ? '0' : '') + i + ':00';
            this.timeArrayOptions.push(new KeyValuePair(new DateRange(startString), startString));
        }
    }
    initTimeCustomOptions() {
        this.customTimeOptionsArray.push(new KeyValuePair(new DateRange('00:00', '04:00'), 'Flight_FormSearchFlight_RangeTime_Night'));
        this.customTimeOptionsArray.push(new KeyValuePair(new DateRange('04:00', '08:00'), 'Flight_FormSearchFlight_RangeTime_EarlyMorning'));
        this.customTimeOptionsArray.push(new KeyValuePair(new DateRange('08:00', '12:00'), 'Flight_FormSearchFlight_RangeTime_LateMorning'));
        this.customTimeOptionsArray.push(new KeyValuePair(new DateRange('12:00', '16:00'), 'Flight_FormSearchFlight_RangeTime_EarlyAfternoon'));
        this.customTimeOptionsArray.push(new KeyValuePair(new DateRange('16:00', '20:00'), 'Flight_FormSearchFlight_RangeTime_LateAfternoon'));
        this.customTimeOptionsArray.push(new KeyValuePair(new DateRange('20:00', '00:00'), 'Flight_FormSearchFlight_RangeTime_Evening'));
    }
    getTimeArrayOptions() {
        return this.timeArrayOptions;
    }
    getCustomTimeArrayOptions() {
        return this.customTimeOptionsArray;
    }
    setPaxSelectionType(value) {
        this._flightPassengerService.SelectionType = value;
        this.selectionTypeEmitter.emit(value);
        this.isSearching.emit();
    }
    getPaxSelectionType() {
        return this._flightPassengerService.SelectionType;
    }
    setDefaultPassengerList(paxComposition: Array<PassengerTypeDTO>) {
        this._flightPassengerService.setDefaultPassengerList(paxComposition);
    }
}
