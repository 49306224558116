import { CarAvailabilityPagedRequestDto, CarPricingInformationDto } from '@shared/service-proxies/service-proxies';

export class MyCarAvailabilityPagedRequestDto extends CarAvailabilityPagedRequestDto {
    toJSON(data?: any) {
        let myData = super.toJSON(data);
        myData['pickUpDate'] = this.pickUpDate ? this.pickUpDate.format('YYYY-MM-DD') : <any>undefined;
        myData['dropOffDate'] = this.dropOffDate ? this.dropOffDate.format('YYYY-MM-DD') : <any>undefined;
        return myData;
    }

}

export interface PackagesInformation {
    packageName: string;
    fareName: string;
    totalPrice: number;
}

export interface FareInformations {
    packageName: string;
    pricingInformation: CarPricingInformationDto;
}
