import { Component, OnInit } from '@angular/core';
import { AgenciesServiceProxy, GetAgencyForView } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'app-filter-agency',
    templateUrl: './filter-agency.component.html',
})
export class FilterAgencyComponent implements OnInit {
    agencies: GetAgencyForView[];
    selectedAgency: string;

    constructor(private agenciesService: AgenciesServiceProxy) {}

    ngOnInit() {
        this.agenciesService.getAll(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined
        ).subscribe((result) => {
            this.agencies = result.items;
        });
    }

    onChange() {
        abp.event.trigger('app.dashboardFilters.dateRangePicker.onAgencyChange', this.selectedAgency);
    }
}
