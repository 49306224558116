/* tslint:disable */
/* eslint-disable */
export enum ValidatorTypeDto {
  Required = 1,
  Min = 2,
  Max = 3,
  Email = 4,
  MinLength = 5,
  MaxLength = 6,
  Pattern = 7
}
