import { Component, OnInit, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ICustomFieldType } from '@shared/AppBusiness';
import { accountModuleAnimation } from '@shared/animations/routerTransition';

@Component({
    selector: 'app-user-profile-custom-fields',
    templateUrl: './user-profile-custom-fields.component.html',
    animations: [accountModuleAnimation()]
})
export class UserProfileCustomFieldsComponent extends AppComponentBase
    implements OnInit {
    customFieldType: ICustomFieldType = 'userProfile';

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit() {}
}
