import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverOutViewportDirective } from './popover-out-viewport.directive';

@NgModule({
    declarations: [PopoverOutViewportDirective],
    imports: [ CommonModule ],
    exports: [PopoverOutViewportDirective]
})
export class PopoverOutViewportModule {}
