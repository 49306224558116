import { Injectable } from '@angular/core';
import {
    AvailabilityRequestMulticityDTO,
    FlightServiceProxy,
    CalendarShoppingDto,
    PricedItineraryDto,
    OriginDestinationOptionDto,
} from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { head } from 'lodash';
import { SearchResultService } from '@app/shared/services/flight/search-result.service';

@Injectable()
export class CalendarShoppingService {

    constructor(private flightService: FlightServiceProxy, private searchResultService: SearchResultService) {}

    getCalendarShopping(request: AvailabilityRequestMulticityDTO): Promise<CalendarShoppingDto> {
        const initialRequest = this.searchResultService.typeRequestData(request);
        return this.flightService
            .calendarShopping(initialRequest)
            .toPromise();
    }

    getReturnDates(calendarShopping: CalendarShoppingDto): Array<moment.Moment> {
        const returnDates: Array<moment.Moment> = [];
        if (calendarShopping &&
             calendarShopping.pricedItineraries &&
             calendarShopping.pricedItineraries[0]) {
                calendarShopping.pricedItineraries[0].originDestinationOptions.map(el => {
                    returnDates.push(el.returnDate);
                });
        }
        return returnDates;
    }

    isReturnAvailable(pricedItinerary: PricedItineraryDto, returnDate: moment.Moment): boolean {
        const returnElement = pricedItinerary.originDestinationOptions.filter(el => el.returnDate.isSame(returnDate, 'day'));
        if (returnElement.length > 0) {
            return true;
        }else {
            return false;
        }
    }

    /**
     * get the PricedItinerary object by a specific date
     * @param calendarShopping the calendar shopping response
     * @param departureDate
     * @returns The selected priced itinerary or undefined if it doesn't exist
     */
    getPricedItinerary(calendarShopping: CalendarShoppingDto, departureDate: moment.Moment): PricedItineraryDto {
        const pricedItinerary = head(calendarShopping.pricedItineraries.filter(el => el.departureDate.isSame(departureDate, 'day')));
        return pricedItinerary;
    }

    /**
     * get the origin destination option by passing the return date
     * @param originDestinationOptions
     * @param returnDate
     */
    getOriginDestinationOptionByReturnDate(originDestinationOptions: Array<OriginDestinationOptionDto>, returnDate: moment.Moment): OriginDestinationOptionDto {
        const originDestination = head(originDestinationOptions.filter(el => el.returnDate.isSame(returnDate)));
        return originDestination;
    }

    /**
     * get the first valid return by departure date
     */
    getFirstValidElementByDeparture(calendarShopping: CalendarShoppingDto, departureDate: moment.Moment): OriginDestinationOptionDto {
        const departure = this.getPricedItinerary(calendarShopping, departureDate);
        return head(departure.originDestinationOptions.filter(el => el.price > 0));
    }

    /**
     * get the first valid return by return date
     */
    getFirstValidElementByReturn(calendarShopping: CalendarShoppingDto, returnDate: moment.Moment): PricedItineraryDto {
        let element: PricedItineraryDto;

        calendarShopping.pricedItineraries.map(el => {
            if (this.checkDate(calendarShopping, el.departureDate, returnDate) && !element) {
                element = el;
            }
        });

        return element;
    }

    getReturnOption(calendarShopping: CalendarShoppingDto, departureDate: moment.Moment, returnDate: moment.Moment): OriginDestinationOptionDto {
        const departureItinerary: PricedItineraryDto = this.getPricedItinerary(calendarShopping, departureDate);

        const returnSelected: OriginDestinationOptionDto = head(departureItinerary.originDestinationOptions.filter(el => el.returnDate.isSame(returnDate)));
        return returnSelected;
    }

    /**
     * check if two dates are valid together
     * @param calendarShopping
     * @param departureDate
     * @param returnDate
     */
    checkDate(calendarShopping: CalendarShoppingDto, departureDate: moment.Moment, returnDate: moment.Moment): boolean {
        const departureItinerary: PricedItineraryDto = this.getPricedItinerary(calendarShopping, departureDate);

        const returnSelected: OriginDestinationOptionDto = head(departureItinerary.originDestinationOptions.filter(el => el.returnDate.isSame(returnDate) && el.price > 0));

        if (returnSelected) {
            return true;
        }else {
            return false;
        }
    }

    /**
     * check if the selected return is valid in relation to the selected departure date
     * @param calendarShopping
     * @param departureDate
     * @param returnDate
     */
    checkReturnDate(calendarShopping: CalendarShoppingDto, departureDate: moment.Moment, returnDate: moment.Moment): moment.Moment {
        const returnSelected: boolean = this.checkDate(calendarShopping, departureDate, returnDate);

        if (returnSelected) {
            return undefined;
        }else {
            return this.getFirstValidElementByDeparture(calendarShopping, departureDate).returnDate;
        }
    }

    /**
     * check if the selected outbound is valid in relation to the selected return date
     * @param calendarShopping
     * @param returnDate
     * @param departureDate
     */
    checkOutboundDate(calendarShopping: CalendarShoppingDto, returnDate: moment.Moment, departureDate: moment.Moment): moment.Moment {
        if (this.checkDate(calendarShopping, departureDate, returnDate)) {
            return undefined;
        } else {
            return this.getFirstValidElementByReturn(calendarShopping, returnDate).departureDate;
        }
    }
    /**
     * check if an itinerary has valid option
     */
    itineraryHasValidOptions(calendarShopping: CalendarShoppingDto, departureDate: moment.Moment): boolean {
        const itinerary = this.getPricedItinerary(calendarShopping, departureDate);

        if (itinerary) {
            const validOptions = itinerary.originDestinationOptions.filter(el => el.price > 0);

            if (validOptions.length > 0) {
                return true;
            } else {
                return false;
            }
        }
    }

    returnDateHasValidOption(calendarShopping: CalendarShoppingDto, returnDate: moment.Moment) {
        let valid = false;
        calendarShopping.pricedItineraries.map(el => {
            const option = this.getReturnOption(calendarShopping, el.departureDate, returnDate);
            if (valid !== true) {
                valid = option && (option && option.price > 0);
            }
        });

        return valid;
    }

    getFirstValidElement(calendarShopping: CalendarShoppingDto): OriginDestinationOptionDto {
        let option: OriginDestinationOptionDto;
        calendarShopping.pricedItineraries.map(itinerary => {
            itinerary.originDestinationOptions.map(destinationOption => {
                if (!option && destinationOption.price > 0) {
                    option = destinationOption;
                }
            });
        });

        return option;
    }

    checkRoundTripDate(calendarShopping: CalendarShoppingDto, departureDate: moment.Moment, returnDate: moment.Moment) {
        const returnOption = this.getReturnOption(calendarShopping, departureDate, returnDate);
        if ((!returnOption) || (returnOption && returnOption.price === 0)) {
            return this.getFirstValidElement(calendarShopping);
        } else {
            return undefined;
        }
    }
}
