<ng-container [formGroup]="travelDocumentGroup">
    <div class="row">
        <div class="col-12">
            <mat-checkbox formControlName="isDefault" color="primary" >{{"TravelDocumentDto_SetAsDefault" | localize}}</mat-checkbox>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-4">
            <label> {{'TravelDocument_Modify_title' | localize}}*</label>
            <select name="apisDocType" formControlName="documentType" class="custom-select form-control">
                <option [ngValue]="null" selected>{{'Flight_Checkout_SelectDocumentType' | localize}}</option>
                <option [ngValue]="'P'">{{'PersonNameData_DocumentType_P' | localize}}</option>
                <option [ngValue]="'A'">{{'PersonNameData_DocumentType_A' | localize}}</option>
                <option [ngValue]="'C'">{{'PersonNameData_DocumentType_C' | localize}}</option>
                <option [ngValue]="'T'">{{'PersonNameData_DocumentType_T' | localize}}</option>
                <option [ngValue]="'F'">{{'PersonNameData_DocumentType_F' | localize}}</option>
                <option [ngValue]="'M'">{{'PersonNameData_DocumentType_M' | localize}}</option>
                <option [ngValue]="'N'">{{'PersonNameData_DocumentType_N' | localize}}</option>
                <option [ngValue]="'V'">{{'PersonNameData_DocumentType_V' | localize}}</option>
                <option [ngValue]="'D'">{{'PersonNameData_DocumentType_D' | localize}}</option>
            </select>
            <app-reactive-validation-message [reactiveFormControl]="travelDocumentGroup.controls['documentType']" [errorName]='"required"' [reactiveFormVar]="formTemplate"></app-reactive-validation-message>
        </div>
        <div class="col-12 col-sm-12 col-md-4">
            <div class="form-group m-form__group">
                <label> {{'TravelDocumentDto_DocumentNumber' | localize}}*</label>
                <input type="text" name="documentNumber_{{index}}" formControlName="documentNumber" class="form-control" placeholder="{{'TravelDocumentDto_DocumentNumber' | localize}}" />
                <app-reactive-validation-message [reactiveFormControl]="travelDocumentGroup.controls['documentNumber']" [errorName]='"required"' [reactiveFormVar]="formTemplate"></app-reactive-validation-message>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4">
            <div class="form-group m-form__group">
                <label> {{'TravelDocumentDto_IssueCountry' | localize}}*</label>
                <app-countries name="issueCountry" (selectedCountryChanged)="updateIssueCountry($event)" [countryList]="countryList" [waitForCountryList]="true" [codSelectedCountry]="travelDocumentGroup.controls['issueCountry'].value"></app-countries>
                <app-reactive-validation-message [reactiveFormControl]="travelDocumentGroup.controls['issueCountry']" [errorName]='"required"' [reactiveFormVar]="formTemplate"></app-reactive-validation-message>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-4">
            <div class="form-group m-form__group">
                <label> {{'TravelDocumentDto_IssuingDate' | localize}}*</label>
                <p-inputMask name="issuingDate_{{index}}" formControlName="issuingDate" (onBlur)="validateDate($event, 'issuingDate')" mask="99/99/9999" slotChar="{{formatMask()}}" placeholder="{{formatMask()}}" required></p-inputMask>
                <app-reactive-validation-message [reactiveFormControl]="travelDocumentGroup.controls['issuingDate']" [errorName]='"required"' [reactiveFormVar]="formTemplate"></app-reactive-validation-message>
                <app-reactive-validation-message [reactiveFormControl]="travelDocumentGroup.controls['issuingDate']" [errorName]='"validDate"' [reactiveFormVar]="formTemplate"></app-reactive-validation-message>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4">
            <div class="form-group m-form__group">
                <label> {{'TravelDocumentDto_ExpirationDate' | localize}}*</label>
                <p-inputMask name="expirationDate_{{index}}" formControlName="expirationDate" (onBlur)="validateDate($event,'expirationDate')" mask="99/99/9999" slotChar="{{formatMask()}}" placeholder="{{formatMask()}}" required></p-inputMask>
                <app-reactive-validation-message [reactiveFormControl]="travelDocumentGroup.controls['expirationDate']" [errorName]='"required"' [reactiveFormVar]="formTemplate"></app-reactive-validation-message>
                <app-reactive-validation-message [reactiveFormControl]="travelDocumentGroup.controls['expirationDate']" [errorName]='"greaterThanTodayDate"' [reactiveFormVar]="formTemplate"></app-reactive-validation-message>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4">
            <div class="form-group m-form__group">
                <label> {{'TravelDocumentDto_NationalityCountry' | localize}}*
                </label>
                <app-countries name="nationalityCountry" (selectedCountryChanged)="updateNationalityCountry($event)" [countryList]="countryList" [waitForCountryList]="true" [codSelectedCountry]="travelDocumentGroup.controls['nationalityCountry'].value"></app-countries>
                <app-reactive-validation-message [reactiveFormControl]="travelDocumentGroup.controls['nationalityCountry']" [errorName]='"required"' [reactiveFormVar]="formTemplate"></app-reactive-validation-message>
            </div>
        </div>
    </div>    
</ng-container>
