import { SelectionType } from '@shared/AppBusiness';
import * as moment from 'moment';

export class AppConsts {

    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    static remoteServiceBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static cdnBaseUrl: string;
    static chatBaseUrl: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static subscriptionExpireNootifyDayCount: number;
    static elasticEndpoint: string;
    static instrumentationKey: string;
    static environmentName: string;

    static localeMappings = {
        "angular": [
          {
            "from": "pt-BR",
            "to": "pt"
          },
          {
            "from": "zh-CN",
            "to": "zh"
          },
          {
            "from": "he-IL",
            "to": "he"
          },
          {
            "from": "es-MX",
            "to": "es"
          },
          {
            "from": "vi",
            "to": "en"
          }
        ],
        "moment": [
          {
            "from": "es-MX",
            "to": "es"
          },
          {
            "from": "zh-Hans",
            "to": "zh-cn"
          },
          {
            "from": "vi",
            "to": "en-gb"
          }
        ],
        "recaptcha": [
          {
            "from": "zh-Hans",
            "to": "zh-cn"
          },
          {
            "from": "es-MX",
            "to": "es"
          }
        ]
      };

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'BookingTool'
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token'
    };

    static readonly grid = {
        defaultPageSize: 10
    };

    static readonly configPax = {
        selectionType: SelectionType.default
    };

    static readonly configSearchFormFlight = {
        maxPaxNumber: 7,
        daysToAdd: 1,
        daysToAddMulticity: 1,
        maxLegMulticity: 4
    };

    static readonly configSearchFormTrain = {
        maxPaxNumber: 10,
        steppingTime: 15,
        daysToAdd: 1,
        addHours : 3,
        subtractHours : 0
    };

    static readonly configSearchFormCar = {
        daysToAdd: 7,
    };

    static readonly configTrainSerp = {
        itemsPerPage : 10
    };

    static readonly HISTORY_MIN_VERSION = '20200427';


    static DefaultDepartureDate = moment()
        .add('1', 'day')
        .toDate();

    static MinDepartureDate = moment().toDate();

    static MaxDepartureDate = moment()
        .add('1', 'year')
        .toDate();

    static DefaultReturnDate = moment()
        .add('1', 'day')
        .toDate();

    static MinReturnDate = moment().toDate();
    static MaxReturnDate = moment()
        .add('1', 'year')
        .toDate();

    static DefaultNumberOfAdults = 1;
    static MinNumberOfAdults = 1;
    static MaxNumberOfAdults = 9;
    static DefaultNumberOfChildren = 0;
    static MinNumberOfChildren = 0;
    static MaxNumberOfChildren = 9;
    static DefaultNumberOfInfants = 0;
    static MinNumberOfInfants = 0;
    static MaxNumberOfInfants = 4;

    public static Classes = [
        {
            Code: 'Y',
            DisplayName: 'Economy'
        },
        {
            Code: 'S',
            DisplayName: 'Premium Economy'
        },
        {
            Code: 'C',
            DisplayName: 'Business'
        },
        {
            Code: 'F',
            DisplayName: 'First'
        }
    ];

    public static TrainClassesNtv = [
      {
        Code: "S",
        DisplayName: "Smart"
      },
      {
        Code: "P",
        DisplayName: "Prima"
      },
      {
        Code: "T",
        DisplayName: "Comfort"
      },
      {
        Code: "C",
        DisplayName: "Club Executive"
      },
      {
        Code: "S1-S2",
        DisplayName: "Salotto"
      },
    ];

    public static TrainClassesFs = [
      {
        Code: "S",
        DisplayName: "Standard"
      },
      {
        Code: "P",
        DisplayName: "Premium"
      },
      {
        Code: "B",
        DisplayName: "Business"
      },
      {
        Code: "E",
        DisplayName: "Executive"
      }
    ];

    public static TrainClassesRegionali = [
      {
        Code: "S",
        DisplayName: "Seconda Classe"
      },
      {
        Code: "P",
        DisplayName: "Prima Classe"
      }
    ];


    static DefaultClass = {
        Code: 'Y',
        DisplayName: 'Economy'
    };

    static readonly MinimumUpgradePaymentAmount = 1;
}

