import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LegTravelItemComponent } from './leg-travel-item.component';
import { LegDetailsModule } from '../travel-item/leg-details/leg-details.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UtilsModule } from '@shared/utils/utils.module';

@NgModule({
  imports: [
    CommonModule,
    LegDetailsModule,
    TooltipModule.forRoot(),
    UtilsModule
  ],
  declarations: [LegTravelItemComponent],
  exports: [LegTravelItemComponent]
})
export class LegTravelItemModule { }
