<div class="card card-custom kt-portlet--height-fluid widget-order-type" [busyIf]="isLoading">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">
                {{'WidgetOrderType' | localize}}
            </h3>
        </div>
    </div>
    <div class="card-body" [perfectScrollbar]>
        <ngx-charts-pie-chart *ngIf="orderTypeData?.length > 0" [results]="orderTypeData" [legend]="true"
            [labels]="true" [animations]="true" [legendTitle]="'Common_Legend' | localize">
        </ngx-charts-pie-chart>
    </div>

    <div class="text-center chart" [hidden]="orderTypeData?.length > 0">
        <small class="text-muted">{{"NoData" | localize}}</small>
    </div>
</div>
