import { FlightComponent } from './flight.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { WizardService } from '@app/shared/services/flight/wizard.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UtilsModule } from '@shared/utils/utils.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TooltipModule.forRoot(),
    UtilsModule
  ],
  declarations: [FlightComponent],
  exports: [FlightComponent],
  providers: [WizardService]
})
export class FlightWizardModule { }
