import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlightCalendarShoppingComponent } from './flight-calendar-shopping.component';
import { CalendarChartModule } from '@app/shared/components/calendar-chart/calendar-chart.module';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { FormsModule } from '@angular/forms';
import { CalendarShoppingTableModule } from './calendar-shopping-table/calendar-shopping-table.module';
import { CalendarShoppingService } from './calendar-shopping-table/services/calendar-shopping.service';
import { MatIconModule } from '@angular/material/icon';
import { PercentageBarElementPositionDirective } from './calendar-shopping-table/directives/percentage-bar-element-position.directive';
import { UtilsModule } from '@shared/utils/utils.module';
import { UtilitiesModule } from '@app/shared/pipes/utilities.module';

@NgModule({
  imports: [
    CommonModule,
    CalendarChartModule,
    ButtonsModule.forRoot(),
    FormsModule,
    CalendarShoppingTableModule,
    MatIconModule,
    UtilsModule,
    UtilitiesModule
  ],
  declarations: [FlightCalendarShoppingComponent, PercentageBarElementPositionDirective],
  exports: [FlightCalendarShoppingComponent],
  providers: [CalendarShoppingService]
})
export class FlightCalendarShoppingModule { }
