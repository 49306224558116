<div class="train-container">
    <div class="train">
        <div class="smallcloudcontainer">
            <div class="smallcloud" style="top:-50px;">
            </div>
        </div>

        <div class="cloud">
            <div class="cloudshapes pulse1" style="top:-10px; left:52px;">
            </div>
            <div class="cloudshapes pulse1" style="top:-46px; left:66px; width:30px; height:30px;">
            </div>
            <div class="cloudshapes pulse1" style="top:-58px; left:20px; width: 55px; height: 55px;">
            </div>
            <div class="cloudshapes pulse2" style="top:-30px; left:60px; width:40px; height:40px;">
            </div>
            <div class="cloudshapes pulse3" style="top:-2px; left:69px;">
            </div>
        </div>

        <div class="trainbody">
        </div>
        <div class="bigwheel" style="top:67px; left:18px;">
            <div class="innerwheelbig" style="top:0px; left:0px;">
            </div>
        </div>
        <div class="smallwheel" style="top:78px; left:54px;">
            <div class="innerwheelsmall" style="top:0px; left:0px;">
            </div>
        </div>
        <div class="smallwheel" style="top:78px; left:74px;">
            <div class="innerwheelsmall" style="top:0px; left:0px;">
            </div>
            <div class="pivot" style="top: 2px; left: -12px;">
                <div class="pivot-inner">
                    <div class="round" style="top:-2px; left:-1px;">
                        <div class="round-inner"></div>
                    </div>
                    <div class="round" style="top:-2px; left:19px;">
                        <div class="round-inner"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
