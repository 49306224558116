import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UtilsModule } from '@shared/utils/utils.module';
import { WidgetNewsComponent } from './widget-news.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { CarouselModule } from 'primeng/carousel';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { ButtonModule, DialogModule } from 'primeng';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
	imports: [
		CommonModule,
		MatInputModule,
		UtilsModule,
		MatFormFieldModule,
		MatIconModule,
		MatButtonModule,
		FormsModule,
		CarouselModule,
		ReactiveFormsModule,
		MatCardModule,
		MatChipsModule,
		DialogModule,
		ButtonModule,
		ModalModule.forRoot(),
	],
	declarations: [WidgetNewsComponent],
})
export class WidgetNewsModule {}
