import { NgModel, FormControl, AbstractControl } from '@angular/forms';

export function hasError(event, formControl: NgModel) {
    if (formControl.value !== '' && formControl.value !== null && formControl.value !== 'undefined' && formControl.value !== undefined) {
        let hasError = !event;
        if (hasError === true) {
            formControl.control.setErrors({ telInput: hasError });
        } else {
            formControl.control.setErrors(null);
        }
    }
}

export function hasErrorReactive(event, formControl: AbstractControl ) {
    if (formControl.value !== '' && formControl.value !== null && formControl.value !== 'undefined' && formControl.value !== undefined) {
        let hasError = !event;
        if (hasError === true) {
            formControl.setErrors({ telInput: hasError });
        } else {
            formControl.setErrors(null);
        }
    }
}

export function hasErrorReactiveAbstract(event, formControl: AbstractControl) {
    if (formControl.value !== '' && formControl.value !== null && formControl.value !== 'undefined' && formControl.value !== undefined) {
        let hasError = !event;
        if (hasError === true) {
            formControl.setErrors({ telInput: hasError });
        } else {
            formControl.setErrors(null);
        }
    }
}

