<div appBsModal #changeProfilePictureModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #changeProfilePictureModalForm="ngForm" (ngSubmit)="save()">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span>{{"ChangeProfilePicture" | localize}}</span>
                    </h4>
                    <button type="button" class="close" [attr.aria-label]="l('Close')" (click)="close()">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="Profile_UseGravatarProfilePicture" class="checkbox">
                            <input id="Profile_UseGravatarProfilePicture" type="checkbox" name="UseGravatarProfilePicture"
                            [(ngModel)]="useGravatarProfilePicture">
                            <span></span>
                            {{"UseGravatarProfilePicture" | localize}}
                        </label>
                    </div>
                    <div class="form-group">
                        <input type="file" (change)="fileChangeEvent($event)" [disabled]="useGravatarProfilePicture" />
                        <span class="help-block m-b-none" [hidden]="useGravatarProfilePicture">{{"ProfilePicture_Change_Info" | localize:maxProfilPictureBytesUserFriendlyValue}}</span>
                    </div>
                    <image-cropper
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="true"
                        [aspectRatio]="4 / 4"
                        [resizeToWidth]="128"
                        format="png"
                        (imageCropped)="imageCroppedFile($event)"
                        [hidden]="useGravatarProfilePicture"
                    ></image-cropper>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light-primary font-weight-bold" (click)="close()" [disabled]="saving">{{"Cancel" | localize}}</button>
                    <button type="submit" class="btn btn-primary font-weight-bold" [disabled]="!changeProfilePictureModalForm.form.valid || saving"><i class="fa fa-save"></i> <span>{{"Save" | localize}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
