import { Injectable } from '@angular/core';

import { Params } from '@angular/router';
import * as moment from 'moment';

@Injectable()
export class QueryStringBuilderService {
    static BuildParametersFromSearch<T>(obj: T): Params {
        let params: Params = {};
        if (obj == null) {
            return params;
        }

        QueryStringBuilderService.PopulateSearchParams(params, '', obj);

        return params;
    }

    private static PopulateArray<T>(params: Params, prefix: string, val: Array<T>) {
        // tslint:disable-next-line:forin
        for (let index in val) {
            let key = prefix + '[' + index + ']';
            let value: any = val[index];
            QueryStringBuilderService.PopulateSearchParams(params, key, value);
        }
    }

    private static PopulateObject<T>(params: Params, prefix: string, val: T) {
        const objectKeys = Object.keys(val);
        if (prefix) {
            prefix = prefix + '.';
        }

        for (let objKey of objectKeys) {
            let value = val[objKey];
            let key = prefix + objKey;

            if (typeof value !== 'undefined' &&
                (typeof value === 'string' ? value.toString() !== '' : true) ) {
                QueryStringBuilderService.PopulateSearchParams(params, key, value);
            }
        }
    }

    private static PopulateSearchParams<T>(params: Params, key: string, value: any) {
        if (value instanceof Array) {
            QueryStringBuilderService.PopulateArray(params, key, value);
        } else if (value instanceof moment || moment.isMoment(value)) {
            params = Object.assign(params, { [key]: moment(value).format('YYYY-MM-DD') });
        } else if (value instanceof Object) {
            QueryStringBuilderService.PopulateObject(params, key, value);
        } else {
            if (value != null) {
                params = Object.assign(params, { [key]: value.toString() });
            } else {
                params = Object.assign(params, { [key]: '' });
            }
        }
    }

    static fromQueryStringToJSON(querystring) {
        let qsObj = new Object();
        if (querystring) {
            // tslint:disable-next-line:forin
            for (let key in querystring) {
                this.dictionaryToJson(key, querystring[key], qsObj);
            }
        }
        return qsObj;
    }

    private static dictionaryToJson(k, v, qsObj) {
        let a = qsObj[k];
        if (typeof a === 'undefined') {
            if (k.indexOf('[') !== -1) {
                let isArrayRegExp = new RegExp('([^\\.\\[\\]]*)\\[([0-9])\\]\\.?(.*)');

                let groups = isArrayRegExp.exec(k);

                let arrayName = groups[1];
                let arrayIdx = groups[2];

                let suffix = groups[3];

                let prefixArr = qsObj[arrayName];
                if (typeof prefixArr === 'undefined') {
                    prefixArr = [];
                    qsObj[arrayName] = prefixArr;
                }

                if (suffix.length > 0) {
                    let prefixObj = qsObj[arrayName][arrayIdx];
                    if (typeof prefixObj === 'undefined') {
                        prefixObj = {};
                        qsObj[arrayName][arrayIdx] = prefixObj;
                    }

                    this.dictionaryToJson(suffix, v, prefixObj);
                } else {
                    prefixArr.push(v);
                }
            } else if (k.indexOf('.') !== -1) {
                let prefix = k.substring(0, k.indexOf('.'));
                let suffix = k.substring(k.indexOf('.') + 1);

                let prefixObj = qsObj[prefix];

                if (typeof prefixObj === 'undefined') {
                    prefixObj = new Object();
                    qsObj[prefix] = prefixObj;
                }

                if (suffix.length > 0) {
                    this.dictionaryToJson(suffix, v, prefixObj);
                } else {
                    prefixObj = v;
                }
            } else {
                qsObj[k] = v;
            }
        } else if (a instanceof Array) {
            a.push(v);
        }
    }
}
