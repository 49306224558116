<div class="card card-custom">
    <div class="card-header align-items-center border-0">
        <h3 class="card-title align-items-start flex-column">
            <span class="font-weight-bolder text-dark">
                 Daily Sales
            </span>
        </h3>
    </div>
    <div class="card-body" [perfectScrollbar]>
        <div class="row">
            <div class="col" style="max-height: 200px">
                <ngx-charts-bar-vertical [results]="dailySalesLineChart.chartData"
                                         [scheme]="dailySalesLineChart.scheme">
                    <ng-template #tooltipTemplate let-model="model">
                        <span>Day {{model.name}}</span>
                        <br />
                        <span>{{model.value}}</span>
                    </ng-template>
                </ngx-charts-bar-vertical>
            </div>
        </div>
    </div>
</div>