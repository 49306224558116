<div class="card card-custom kt-portlet--height-fluid sales-schedule-companies" [busyIf]="isLoading">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">
                {{'WidgetSalesBySchedulesAndCompanies' | localize}}
            </h3>
        </div>
    </div>
    <div class="card-body" [perfectScrollbar]>
        <ngx-charts-pie-chart *ngIf="data?.length > 0" [results]="data" [doughnut]="true" [legend]="true"
            [labels]="true" [animations]="true" [legendTitle]="'Common_Legend' | localize">
        </ngx-charts-pie-chart>

        <div class="text-center chart" [hidden]="data?.length > 0">
            <small class="text-muted">{{"NoData" | localize}}</small>
        </div>
    </div>
</div>
