import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { AppConsts } from '@shared/AppConsts';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-date-time-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent implements OnInit, OnChanges {
    @Input() originalDate: moment.Moment;
    @Input() minDate: Date = moment().toDate();
    @Input() hoursStep = 1;
    @Input() minutesStep = AppConsts.configSearchFormTrain.steppingTime;
    @Input() autoClose = true;
    @Input() isAnimated = true;
    @Input() disabled;
    @Input() mousewheel ? = false;
    @Output() onDateChange: EventEmitter<moment.Moment>;
    @Output() onClose: EventEmitter<void>;
    @Input() openEvent ?: Observable<boolean>;
    @Input() isTrainPostSaleAction: boolean;

    date: Date;
    datePickerConfig: Partial<BsDatepickerConfig>;
    isMeridian;
    selectTime = false;

    @ViewChild('dateTimepickerDropdown') dateTimepickerDropdown: BsDropdownDirective;

    constructor(private localeService: BsLocaleService) {
        this.onDateChange = new EventEmitter();
        this.onClose = new EventEmitter();
        this.localeService.use(abp.localization.currentLanguage.name);
        this.datePickerConfig = NgxBootstrapDatePickerConfigService.getDatepickerConfig();
        this.datePickerConfig.containerClass += ` shadow-none`;
        this.datePickerConfig.dateInputFormat = `L - LT`;
        this.datePickerConfig.isAnimated = this.isAnimated;
    }

    ngOnInit() {
        if (this.openEvent) {
            this.openEvent.subscribe(val => {
                if (val && !this.disabled) {
                    this.dateTimepickerDropdown.show();
                }
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.originalDate) {
            if (changes.originalDate.currentValue && moment.isMoment(changes.originalDate.currentValue)) {
                this.date = changes.originalDate.currentValue.toDate();
            } else {
                this.date = undefined;
            }

            if (!changes.originalDate.firstChange &&
                (moment.isMoment(changes.originalDate.currentValue) &&
                    !changes.originalDate.currentValue.isSame(changes.originalDate.previousValue))
                    && this.dateTimepickerDropdown.isOpen) {
                this.selectTime = true;
            }

            if ((changes.originalDate.firstChange || typeof this.isMeridian === 'undefined') && moment.isMoment(this.originalDate)) {
                const formatTime = this.originalDate.format('LT').toLowerCase();

                if (formatTime.includes('am') || formatTime.includes('pm')) {
                    this.isMeridian = true;
                } else {
                    this.isMeridian = false;
                }
            }
        }
    }

    dateChange(event: moment.Moment) {
        this.originalDate = event;
        if (event)
            this.date = this.originalDate.toDate();
        else if (event === null)
            this.date = null;
        this.onDateChange.emit(this.originalDate);
    }

    onEmptyValue(event: Event) {
        if (!(event.target as any).value) {
            this.originalDate = undefined;
            this.date = undefined;
            this.onDateChange.emit(null);
        }
    }

    checkIfIsDisabled(event) {
        if (this.disabled) {
            event.preventDefault();
            event.stopPropagation();
            this.dateTimepickerDropdown.hide();
        }
    }

    onDropdownHidden() {
       this.onClose.emit();
       this.selectTime = false;
    }

    preventClose(event: MouseEvent) {
        event.stopPropagation();
    }
}
