import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class PwaService {
    installPrompt;
    showInstallPrompt: EventEmitter<boolean>;
    constructor() {
        this.showInstallPrompt = new EventEmitter();
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            this.installPrompt = e;
            this.showInstallPrompt.emit();
        });
        window.addEventListener('appinstalled', (e) => {
            console.log('INSTALL: Success');
        });
    }
}
