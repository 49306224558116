import { Pipe, PipeTransform, Injector } from '@angular/core';
import { InputTypeDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';

@Pipe({
    name: 'inputTypeName',
})
export class InputTypeNamePipe extends AppComponentBase implements PipeTransform {

    constructor(injector: Injector) {
        super(injector);
    }

    transform(value: any): any {
        let inputTypeDto: Array<any> = Object.entries(InputTypeDto);
        inputTypeDto.splice(0, inputTypeDto.length / 2);
        const inputType = inputTypeDto.find((type) =>
            type[1] === value ? type[0] : undefined
        );
        return inputType[0];
    }
}
