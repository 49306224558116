import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { LinearChartDto, AnalyticsDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { WidgetComponentBaseComponent } from '../widget-component-base';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-widget-sales-by-company',
    templateUrl: './widget-sales-by-company.component.html',
})
export class WidgetSalesByCompanyComponent extends WidgetComponentBaseComponent implements OnInit, OnDestroy {

    data: Array<LinearChartDto>;
    isLoading: boolean;
    selectedDateRange: moment.Moment[] = [
        moment().add(-3, 'months').startOf('day'),
        moment().endOf('day'),
    ];

    constructor(injector: Injector, private analyticsService: AnalyticsDashboardServiceProxy) {
        super(injector);
    }

    ngOnInit() {
        this.subDateRangeFilter();
    }

    ngOnDestroy() {
        this.unSubDateRangeFilter();
    }

    onDateRangeFilterChange = (dateRange) => {
        this.runDelayed(() => {
            if (
                !dateRange ||
                dateRange.length !== 2 ||
                (this.selectedDateRange[0] === dateRange[0] &&
                    this.selectedDateRange[1] === dateRange[1])
            ) {
                return;
            }

            this.selectedDateRange = dateRange;
            this.getData(this.selectedDateRange);
        });
    }

    getData(dateRange: Array<moment.Moment>) {
        this.isLoading = true;
        this.analyticsService
            .getSalesByAgency(dateRange[0], dateRange[1])
            .pipe(finalize(() => {
                this.isLoading = false;
            }))
            .subscribe((data) => {
                this.data = data;
                this.isLoading = false;
            });
    }

    subDateRangeFilter() {
        abp.event.on(
            'app.dashboardFilters.dateRangePicker.onDateChange',
            this.onDateRangeFilterChange
        );
    }

    unSubDateRangeFilter() {
        abp.event.off(
            'app.dashboardFilters.dateRangePicker.onDateChange',
            this.onDateRangeFilterChange
        );
    }

    formatXAxis(val) {
        return '€ ' + val;
    }
}
