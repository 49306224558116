import { Component, OnInit, Injector, AfterViewInit, Renderer2 } from '@angular/core';
import { MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppSessionService } from '@shared/common/session/app-session.service';

@Component({
    selector: '[app-install-pwa-prompt]',
    templateUrl: './install-pwa-prompt.component.html',
})
export class InstallPwaPromptComponent extends AppComponentBase implements OnInit, AfterViewInit {
    matSnackbarRef: MatSnackBarRef<InstallPwaPromptComponent>;
    constructor(injector: Injector, private appSessionService: AppSessionService, private renderer: Renderer2) {
        super(injector);
    }

    ngOnInit() {
    }

    onAction() {
        this.matSnackbarRef.dismissWithAction();
    }

    dismiss() {
        this.matSnackbarRef.dismiss();
    }

    getTenancyName() {
        return this.appSessionService.tenancyName.toLowerCase();
    }

    getInstallText(): string {
        if (this.appSessionService.tenancyName) {
            return this.l('PWA_InstallOnYourDevice').replace('{0}', this.appSessionService.tenancyName);
        } else {
            return this.l('PWA_InstallOnYourDevice').replace('{0}', this.l('Application'));
        }
    }

    ngAfterViewInit(): void {
        this.renderer.setStyle(document.querySelector('.cdk-overlay-pane .install-prompt'), 'width', '100%');
    }
}
