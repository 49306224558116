import { Component, OnInit, Input, Injector } from '@angular/core';
import { FieldConfigDto, CompanyServiceProxy, FieldConfigInputDto, TagTypeDto, UserProfilesServiceProxy, TripServiceProxy } from '@app/../shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { finalize } from 'rxjs/operators';
import { ICustomFieldType } from '@shared/AppBusiness';
import { CompanySettingsService } from '@app/corporate/company-settings/company-settings.service';

@Component({
    selector: 'app-manage-custom-fields',
    templateUrl: './manage-custom-fields.component.html',
    styleUrls: ['./manage-custom-fields.component.scss']
})
export class ManageCustomFieldsComponent extends AppComponentBase implements OnInit {
    @Input() fieldConfig: Array<FieldConfigDto>;
    @Input() type: ICustomFieldType;
    mode: 'create' | 'edit' | 'list';
    currentFieldConfig: FieldConfigDto;
    isSavable: boolean;
    iAlreadyExist: boolean;
    private service: CompanyServiceProxy | UserProfilesServiceProxy | TripServiceProxy;

    constructor(injector: Injector,
        private companySP: CompanyServiceProxy,
        private userProfileSP: UserProfilesServiceProxy,
        private tripSP: TripServiceProxy,
        private companySettingsService: CompanySettingsService) {
        super(injector);
        this.fieldConfig = [];
        this.mode = 'list';
        this.type = 'company';
    }

    get tagType() {
        return TagTypeDto;
    }

    ngOnInit() {
        if (this.type === 'company') {
            this.service = this.companySP;
        } else if(this.type === 'userProfile') {
            this.service = this.userProfileSP;
        }else if(this.type === 'trip') {
            this.service = this.tripSP;
        }

        this.spinnerService.show();
        this.service.getFieldsConfig()
        .pipe(finalize(() => this.spinnerService.hide()))
        .subscribe((fieldConfig) => {
            this.fieldConfig = fieldConfig;
        });

        this.companySettingsService.customFieldDeselected.subscribe(el => {
            if (el) {
                this.mode = 'list';
            }
        });
    }

    onCustomFieldSubmit(customField: FieldConfigDto) {
        if (!customField.id && !this.iAlreadyExist) {
            this.fieldConfig.push(customField);
        } else {
            const index = this.fieldConfig.findIndex(
                (field) => field.id === customField.id
            );
            if (index >= 0) {
                this.fieldConfig[index] = customField;
            }
            this.iAlreadyExist = false;
        }

        this.mode = 'list';
        this.isSavable = true;
    }

    onCustomFieldCancel() {
        this.currentFieldConfig = null;
        this.mode = 'list';
    }

    createField() {
        this.currentFieldConfig = new FieldConfigDto();
        this.mode = 'create';
    }

    editField(name: string) {
        const field = this.fieldConfig.find(field => field.name === name);
        if (field) {
            this.iAlreadyExist = true;
            this.currentFieldConfig = field;
            this.mode = 'edit';
        }
    }

    removeField(name: string) {
        const fieldIndex = this.fieldConfig.findIndex(field => field.name === name);
        if (fieldIndex >= 0) {
            this.fieldConfig.splice(fieldIndex, 1);
            //this.isSavable = true;
            this.saveCustomFields();
        }
    }

    saveCustomFields() {
        this.createOrUpdateFiledConfig(new FieldConfigInputDto({
            fieldsConfig: this.fieldConfig,
        }));
        this.isSavable = false;
    }

    private createOrUpdateFiledConfig(fieldConfig: FieldConfigInputDto) {
        this.spinnerService.show();
        this.service
            .createOrUpdateFieldConfig(fieldConfig)
            .pipe(finalize(() => this.spinnerService.hide()))
            .subscribe((company) => {
                this.fieldConfig = company;
            });

    }
}
