<ng-template #updateModal>
    <div class="modal-body text-center">
        <p class="mb-2">{{'PWA_LoadNewVersionMessage' | localize}}</p>

        <button type="button" class="btn btn-secondary mr-2" (click)="updateEmitter.emit(false); closeModal()">
            {{'Common_Cancel' | localize}}
        </button>

        <button type="button" class="btn btn-primary" (click)="updateEmitter.emit(true)">
            <i class="fas fa-sync"></i>
            <span>{{'PWA_UpdateApplication' | localize}}</span>
        </button>
    </div>
</ng-template>
