import { NgModule } from '@angular/core';

import { DefaultDropdownSizeDirective } from './default-dropdown-size.directive';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule],
    exports: [DefaultDropdownSizeDirective],
    declarations: [DefaultDropdownSizeDirective],
})
export class DefaultDropdownSizeModule { }
