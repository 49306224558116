import { flightPaxType } from '@shared/AppBusiness';
import { AirportDTO, ApplicationUserDto, AvailabilityRequestMulticityDTO, MulticityDTO, PersonNameDataDTO, RangeTimeTypeDto } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';

export class RegularFlightModel {
    originAirportCode: string | undefined;
    destinationAirportCode: string | undefined;
    departureDate: moment.Moment | undefined;
    returnDate: moment.Moment | undefined;
    originAirport: AirportDTO | undefined;
    destinationAirport: AirportDTO | undefined;
    departureRangeStartTime: string | undefined;
    departureRangeEndTime: string | undefined;
    arrivalRangeStartTime: string | undefined;
    arrivalRangeEndTime: string | undefined;
    departureRangeType: RangeTimeTypeDto | undefined;
    arrivalRangeType: RangeTimeTypeDto | undefined;
}

export class Group {
    name: string;
    key: string;
    group: Array<any> = [];
    constructor(name = '', key?: string, group?: Array<any>) {
        this.name = name;
        this.key = key;
        this.group = group;
    }
}

export class KeyValuePair<T, R> {
    key: T;
    value: R;
    constructor(key: T, value: R) {
        this.key = key;
        this.value = value;
    }
}

export class FlightClassType {
    classes: Array<KeyValuePair<string, string>> = [];
    constructor() {
        this.classes.push(new KeyValuePair<string, string>('Y', 'Economy'));
        this.classes.push(new KeyValuePair<string, string>('S', 'Premium'));
        this.classes.push(new KeyValuePair<string, string>('C', 'Business'));
        this.classes.push(new KeyValuePair<string, string>('F', 'First'));
    }
}

export class PaxComposition {
    type: flightPaxType;
    num: number;

    constructor(type: flightPaxType = flightPaxType.ADT, num: number = 0) {
        this.type = type;
        this.num = num;
    }
}

export class ClientAvailabilityRequestMulticityDTO {
    timestamp: number;
    searchData: AvailabilityRequestMulticityDTO;
}

export class Passenger {
    num: number;
    type: flightPaxType;
    age: Array<number> | undefined;
    value: string;
    max: number;
    min: number;
    disabled = false;

    constructor(type: flightPaxType, value = '', num: number = 1, max: number = 7) {
        this.num = num;
        this.type = type;
        this.value = value;
        this.min = this.type === flightPaxType.ADT ? 1 : 0;
        this.max = max;
    }

    public setDisabled(bool: boolean) {
        this.disabled = bool;
    }
}
/**classe che estende MulticityDTO per risolvere il problema del formato delle date serializzato dal proxy sempre come ZULU.**/
export class MyMulticityDTO extends MulticityDTO {
    toJSON(data?: any) {
        let mydata = super.toJSON(data);
        mydata['departureDate'] = this.departureDate ? this.departureDate.format('YYYY-MM-DD') : <any>undefined;
        return mydata;
    }
}

export class AirportEmitter {
    index: number;
    airport: AirportDTO;
}

export class DateEmitter {
    index: number;
    date: moment.Moment;
    minDate: moment.Moment;
    rangeTimeType?;
}

export interface IDateRange {
    start: string;
    end: string;
}

export class DateRange implements IDateRange {
    start: string;
    end: string;
    constructor(start: string = null, end: string = null) {
        this.start = start;
        this.end = end;
    }
}

export class MyPersonNameDataDTO extends PersonNameDataDTO {
    toJSON(data?: any) {
        let mydata = super.toJSON(data);
        mydata['birthDate'] = this.birthDate ? this.birthDate.format('YYYY-MM-DD') : <any>undefined;
        mydata['expirationDate'] = this.expirationDate ? this.expirationDate.format('YYYY-MM-DD') : <any>undefined;
        mydata['extraOffers'] = this['extraOffers'];
        return mydata;
    }
}

export class MyApplicationUserDto extends ApplicationUserDto {
    toJSON(data?: any) {
        let mydata = super.toJSON(data);
        mydata['birthDate'] = this.birthDate ? this.birthDate.format('YYYY-MM-DD') : <any>undefined;
        return mydata;
    }
}

export class DateRangeEmitter {
    index: number;
    dateRangeTime: DateRange;
    field: string;
    rangeTimeType;
    constructor(index, field, dateRangeTime = new DateRange(), rangeTimeType = null) {
        this.index = index;
        this.dateRangeTime = dateRangeTime;
        this.field = field;
        this.rangeTimeType = rangeTimeType;
    }
}

export class MyAvailabilityRequestMulticityDTO extends AvailabilityRequestMulticityDTO{

    toJSON(data?: any) {
        let mydata = super.toJSON(data);
        mydata['legs'] = [];
        if (Array.isArray( this.legs )) {
            for (let item of this.legs)
            mydata['legs']!.push(new MyMulticityDTO(item).toJSON());
        }
        return mydata;
    }
}
