import { Injectable } from '@angular/core';
import { IAvailabilityItineraryMulticityDTOExtended } from '@shared/interfaces/availability-itinerary-muticity-extended.interface';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class FlightOrderService {
    constructor() {}

    /**
     * Sort element by a specified criteria
     * @param criteria the criterion for which the data set must be ordered
     * @param originalData the flight search results set
     * @param mode indicates whether the sort must be in ascending or descending order
     */
    sortBy(
        criteria: 'totalPrice' | 'duration' | 'departureTimesWayTrip',
        originalData: Array<IAvailabilityItineraryMulticityDTOExtended>,
        mode: 'asc' | 'desc' = 'asc'
    ) {

        originalData = originalData.sort((a, b) => {
            if (mode === 'asc') {
                return (a[criteria] as any) - (b[criteria] as any);
            }
            return (b[criteria] as any) - (a[criteria] as any);
        });
        return originalData;
    }

    /**
     * Sort the flight results by price
     * @param originalData the flight search results set
     * @param mode indicates whether the sort must be in ascending or descending order
     */
    sortByPrice(
        originalData: Array<IAvailabilityItineraryMulticityDTOExtended>,
        mode: 'asc' | 'desc' = 'asc'
    ) {
        return this.sortBy('totalPrice', originalData, mode);
    }

    /**
     * Sort the flight results by price
     * @param originalData the flight search results set
     * @param mode indicates whether the sort must be in ascending or descending order
     */
    sortByDuration(
        originalData: Array<IAvailabilityItineraryMulticityDTOExtended>,
        mode: 'asc' | 'desc' = 'asc'
    ) {
        return this.sortBy('duration', originalData, mode);
    }

    sortByDepartureDateTime(
        originalData: Array<IAvailabilityItineraryMulticityDTOExtended>,
        mode: 'asc' | 'desc' = 'asc'
    ) {
        return this.sortBy('departureTimesWayTrip', originalData, mode);
    }

    /**
     * Sort the flight results by schedule count
     * @param data The flight search results set
     * @param mode Indicates whether the sort must be in ascending or descending order
     * @param legNumber The leg to be considered for the ordering
     */
    sortBySchedule(
        data: Array<IAvailabilityItineraryMulticityDTOExtended>,
        mode: 'asc' | 'desc' = 'asc',
        legNumber: number = 0
    ) {
        data.sort((a, b) => {
            if (mode === 'asc') {
                return (
                    a.legs[legNumber].schedule.length -
                    b.legs[legNumber].schedule.length
                );
            }
            return (
                b.legs[legNumber].schedule.length -
                a.legs[legNumber].schedule.length
            );
        });

        return data;
    }

    /**
     * Sort elements by time
     * @param criteria the criterion for which the data set must be ordered
     * @param originalData the flight search results set
     * @param mode indicates whether the sort must be in ascending or descending order2
     * @param legNumber The leg to be considered for the ordering
     */
    private sortByTime(
        criteria: 'departureDateTime' | 'arrivalDateTime',
        data: Array<IAvailabilityItineraryMulticityDTOExtended>,
        mode: 'asc' | 'desc' = 'asc',
        legNumber
    ) {
        return data.sort((a, b) => {
            if (mode === 'asc') {
                return (
                    moment(a.legs[legNumber][criteria])
                        .utc()
                        .valueOf() -
                    moment(b.legs[legNumber][criteria])
                        .utc()
                        .valueOf()
                );
            }
            return (
                moment(b.legs[legNumber][criteria])
                    .utc()
                    .valueOf() -
                moment(a.legs[legNumber][criteria])
                    .utc()
                    .valueOf()
            );
        });
    }

    /**
     * Sort the flight results by departure time
     * @param data The flight search results set
     * @param mode Indicates whether the sort must be in ascending or descending order
     * @param legNumber The leg to be considered for the ordering
     */
    sortByDepartureTime(
        data: Array<IAvailabilityItineraryMulticityDTOExtended>,
        mode: 'asc' | 'desc' = 'asc',
        legNumber: number = 0
    ) {
        return this.sortByTime('departureDateTime', data, mode, legNumber);
    }

    /**
     * Sort the flight results by arrival time
     * @param data The flight search results set
     * @param mode Indicates whether the sort must be in ascending or descending order
     * @param legNumber The leg to be considered for the ordering
     */
    sortByArrivalTime(
        data: Array<IAvailabilityItineraryMulticityDTOExtended>,
        mode: 'asc' | 'desc' = 'asc',
        legNumber: number = 0
    ) {
        return this.sortByTime('arrivalDateTime', data, mode, legNumber);
    }
}
