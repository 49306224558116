import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { ReactiveValidationMessageModule } from '@app/shared/components/reactive-validation-message/reactive-validation-message.module';
import { TravelDocumentModule } from '@app/shared/components/travel-document/travel-document.module';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { UtilitiesModule } from '@app/shared/pipes/utilities.module';
import { CheckoutService } from '@app/shared/services/flight/checkout.service';
import { UtilsModule } from '@shared/utils/utils.module';
import { PhoneInputModule } from '@app/shared/directives/phone-input/phone-input.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { UserProfileComponent } from './user-profile.component';
import { VatNumberInputModule } from '@app/shared/components/vat-number-input/vat-number-input.module';
import { UserProfileCustomFieldsComponent } from './user-profile-custom-fields/user-profile-custom-fields.component';
import { ManageCustomFieldsModule } from '@app/shared/components/manage-custom-fields/manage-custom-fields.module';
import { CustomFieldFormModule } from '@app/shared/components/custom-field-form/custom-field-form.module';
import { AppCommonModule } from '@app/shared/common/app-common.module';
import { CountriesModule } from '@app/shared/components/countries/countries.module';

@NgModule({
    imports: [
        CommonModule,
        AppCommonModule,
        UtilsModule,
        FormsModule,
        ReactiveFormsModule,
        AccordionModule.forRoot(),
        DropdownModule,
        MatRadioModule,
        InputMaskModule,
        PhoneInputModule,
        UtilitiesModule,
        ReactiveValidationMessageModule,
        TravelDocumentModule,
        MatCheckboxModule,
        VatNumberInputModule,
        ManageCustomFieldsModule,
        CustomFieldFormModule,
        CountriesModule,
        MatAutocompleteModule
    ],
    declarations: [UserProfileComponent, UserProfileCustomFieldsComponent],
    exports: [UserProfileComponent, UserProfileCustomFieldsComponent],
    providers: [CheckoutService]
})
export class UserProfileModule { }
