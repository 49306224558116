<div [class]="footerStyle" id="kt_footer">
    <div [class]="containerClass + ' d-flex flex-column flex-md-row align-items-center justify-content-between'">
        <div class="text-dark order-2 order-md-1">
            <a class="nav-link pl-0 pr-5" id="version-popover" [outsideClick]="true" [popover]="versionPopoverTemplate"
                container="body" popoverTitle="Version Info" placement="top">
                {{appName}}
            </a>
            <span class="text-muted font-weight-bold mr-2" *ngIf="tenantName"> | {{tenantName}}</span>
        </div>
        <div class="nav nav-dark nav nav-dark order-1 order-md-2">
            <a [routerLink]="['/app/main/terms']" [queryParams]="{section: 'termsCondition'}" class="nav-link pl-0 pr-5"
                [innerHTML]="l('Footer_T_&_C')"></a>
            <a [routerLink]="['/app/main/terms']" [queryParams]="{section: 'privacy'}" class="nav-link pl-0 pr-5">
                {{'Common_Privacy' | localize}}
            </a>
            <a [routerLink]="['/app/main/terms']" [queryParams]="{section: 'about'}" class="nav-link pl-0 pr-5">
                {{'Footer_About' | localize}}
            </a>
            <a [routerLink]="['/app/main/terms']" [queryParams]="{section: 'cookie'}" class="nav-link pl-0 pr-5">
                Cookie Policy
            </a>
            <a class="nav-link pl-0 pr-5" id="help-popover" [outsideClick]="true" [popover]="helpPopoverTemplate"
                container="body" [popoverTitle]="l('Footer_Support_Centre')" placement="top">
                <i class="flaticon-info m--icon-font-size-lg3"></i>
            </a>
        </div>
    </div>
</div>

<ng-template #versionPopoverTemplate>
    <div>
        API: v{{appSession.application.version}} <br />
        Client: v{{webAppGuiVersion}} <br />
        Release date: {{releaseDate}}
    </div>
</ng-template>


<ng-template #helpPopoverTemplate>
    <div [innerHTML]="l('Footer_help_contact')"></div>
</ng-template>
