import { Component, OnInit, Injector, Input } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { NgForm, UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'app-reactive-validation-message',
    templateUrl: './reactive-validation-message.component.html',
})
export class ReactiveValidationMessageComponent extends AppComponentBase implements OnInit {
    @Input() reactiveFormControl: UntypedFormControl;
    @Input() errorName = '';
    @Input() reactiveFormVar: NgForm;
    @Input() activateTouched = false;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
    }
}
