import { Component, OnInit, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, NgForm, Validators } from '@angular/forms';
import { TravelDocumentDto, CountryListDto } from '@shared/service-proxies/service-proxies';
import { formatMask } from '@app/shared/helpers/formatMask';
import * as moment from 'moment';
import { ValidDateValidator } from '@app/shared/validators/valid-date-validator';
import { LocalMaskGreaterThanTodayDateValidator } from '@app/shared/validators/greater-than-today-date-validator';

@Component({
    selector: 'app-travel-document',
    templateUrl: './travel-document.component.html'
})
export class TravelDocumentComponent extends AppComponentBase implements OnInit, OnChanges {
    @Input() index: number;
    @Input() countryList: CountryListDto[];
    @Input() travelDocumentGroupInput: UntypedFormGroup;
    @Input() formTemplate: NgForm;

    travelDocumentGroup: UntypedFormGroup;

    constructor(injector: Injector, private formBuilder: UntypedFormBuilder) {
        super(injector);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.travelDocumentGroupInput && changes.travelDocumentGroupInput.currentValue !== undefined) {
            this.travelDocumentGroup = changes.travelDocumentGroupInput.currentValue;
            this.initTravelDocument();
        }
    }

    ngOnInit() {
        if (this.travelDocumentGroupInput) {
            this.travelDocumentGroup = this.travelDocumentGroupInput;
        } else {
            this.initTravelDocument();
        }
    }

    initTravelDocument() {
        let travelDocument = new TravelDocumentDto();
        this.travelDocumentGroup = this.formBuilder.group({
            documentType: [travelDocument.documentType, Validators.required],
            documentNumber: [travelDocument.documentNumber, Validators.required],
            expirationDate: [moment(travelDocument.expirationDate, 'YYYY-MM-DD').format(formatMask()), [Validators.required, LocalMaskGreaterThanTodayDateValidator()]],
            nationalityCountry: [travelDocument.nationalityCountry, Validators.required],
            issueCountry: [travelDocument.issueCountry, Validators.required],
            issuingDate: [moment(travelDocument.issuingDate, 'YYYY-MM-DD').format(formatMask()), [Validators.required, ValidDateValidator()]]
        });
    }

    formatMask(): string {
        return formatMask();
    }

    validateDate(event, formName) {
        if (!moment(this.travelDocumentGroup.controls[formName].value, 'L').isValid()) {
            (this.travelDocumentGroup.controls[formName] as UntypedFormControl).setValue(null);
            event.target.value = '';
        }
    }

    updateIssueCountry(e) {
        this.travelDocumentGroup.controls['issueCountry'].setValue(e);
    }

    updateNationalityCountry(e) {
        this.travelDocumentGroup.controls['nationalityCountry'].setValue(e);
    }
}
