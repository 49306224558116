import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import {
    AgencyMasterDashboardServiceProxy,
    AnalyticsDashboardServiceProxy,
} from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { WidgetComponentBaseComponent } from '../widget-component-base';

@Component({
    selector: 'app-widget-analytics-top-stats',
    templateUrl: './widget-analytics-top-stats.component.html',
})
export class WidgetAnalyticsTopStatsComponent extends WidgetComponentBaseComponent
    implements OnInit, OnDestroy {
    totalProfit: number;
    totalProfitCounter: number;
    newOrders: number;
    newOrdersCounter: number;
    segments: number;
    segmentsCounter: number;
    orderSegmentRatio: number;
    orderSegmentRatioCounter: number;
    selectedAgency: string;
    selectedDateRange: moment.Moment[] = [
        moment().add(-7, 'days').startOf('day'),
        moment().endOf('day'),
    ];

    constructor(
        injector: Injector,
        private dashboardService: AgencyMasterDashboardServiceProxy,
        private analyticsService: AnalyticsDashboardServiceProxy
    ) {
        super(injector);
    }

    ngOnInit() {
        this.subDateRangeFilter();
        this.subAgencyFilter();
        this.getData();
    }

    ngOnDestroy(): void {
        this.unSubDateRangeFilter();
        this.unSubAgencyFilter();
    }

    getData() {
        this.dashboardService
            .getTotalProfit(
                this.selectedDateRange[0],
                this.selectedDateRange[1]
            )
            .subscribe((res) => {
                this.totalProfit = res;
            });

        this.dashboardService
            .getReservationsSegmentsCountByDateRange(
                this.selectedDateRange[0],
                this.selectedDateRange[1]
            )
            .subscribe((res) => {
                this.segments = res;
            });

        this.dashboardService
            .getNewOrders(this.selectedDateRange[0], this.selectedDateRange[1])
            .subscribe((res) => {
                this.newOrders = res;
            });

        this.analyticsService
            .getOrderScheduleRatio(
                this.selectedDateRange[0],
                this.selectedDateRange[1],
                this.selectedAgency
            )
            .subscribe((res) => {
                this.orderSegmentRatio = res;
            });
    }

    subDateRangeFilter() {
        abp.event.on(
            'app.dashboardFilters.dateRangePicker.onDateChange',
            this.onDateRangeFilterChange
        );
    }

    unSubDateRangeFilter() {
        abp.event.off(
            'app.dashboardFilters.dateRangePicker.onDateChange',
            this.onDateRangeFilterChange
        );
    }

    onDateRangeFilterChange = (dateRange) => {
        if (
            !dateRange ||
            dateRange.length !== 2 ||
            (this.selectedDateRange[0] === dateRange[0] &&
                this.selectedDateRange[1] === dateRange[1])
        ) {
            return;
        }

        this.selectedDateRange[0] = dateRange[0];
        this.selectedDateRange[1] = dateRange[1];
        this.getData();
    }

    subAgencyFilter() {
        abp.event.on(
            'app.dashboardFilters.dateRangePicker.onAgencyChange',
            this.onAgencyChange
        );
    }

    unSubAgencyFilter() {
        abp.event.off(
            'app.dashboardFilters.dateRangePicker.onAgencyChange',
            this.onAgencyChange
        );
    }

    onAgencyChange = (agency) => {
        this.runDelayed(() => {
            this.selectedAgency = agency;
            this.getData();
        });
    }

}
