export class ThemeHelper {

    public static getTheme(): string {
        let theme = abp.setting.get('App.UiManagement.Theme');
        if(theme === 'default') theme = 'theme1';
        return theme;
    }

    public static getAsideSkin(): string {
        return abp.setting.get(ThemeHelper.getTheme() + '.App.UiManagement.Left.AsideSkin');
    }

    public static getHeaderSkin(): string {
        return abp.setting.get(ThemeHelper.getTheme() + '.App.UiManagement.Header.Skin');
    }
}
