import { Injectable } from '@angular/core';
import { TablePreferenceModel } from '@app/shared/models/table-preference.model';
import * as localForage from 'localforage';

@Injectable()
export class LocalStorageService {
    getItem(key: string, callback: any): void {
        if (!localForage) {
            return;
        }

        localForage.getItem(key, callback);
    }

    setItem(key, value, callback?: any): void {
        if (!localForage) {
            return;
        }

        if (value === null) {
            value = undefined;
        }

        localForage.setItem(key, value, callback);
    }

    removeItem(key, callback?: any): void {
        if (!localForage) {
            return;
        }

        localForage.removeItem(key, callback);
    }

    getTablePreferences(componentName: string): Promise<TablePreferenceModel> {
        if (!localForage) {
            return;
        }

        return localForage.getItem('table-preferences').then((x: TablePreferenceModel[]) => x ? x.find(y => y.component === componentName) : null);
    }

    updateTablePreferences(componentName: string, value: TablePreferenceModel, isInit: boolean): Promise<TablePreferenceModel> {
        if (!localForage) {
            return;
        }

        return localForage.getItem('table-preferences').then((current_value: TablePreferenceModel[]) => {
            if (current_value) {
                let old_value = current_value.find(x => x.component === componentName);
                let index = current_value.indexOf(old_value);
                if (index >= 0) {
                    current_value[index].filter = value.filter.length > 0 ? value.filter : current_value[index].filter;
                    current_value[index].page_size = value.page_size ? value.page_size : current_value[index].page_size;

                    current_value[index].sort_field = value.sort_field
                        ? current_value[index].sort_order == -1 && !isInit ? undefined : value.sort_field
                        : current_value[index].sort_field;
                    current_value[index].sort_order = value.sort_order
                        ? current_value[index].sort_order == -1 && !isInit ? undefined : value.sort_order
                        : current_value[index].sort_order;

                    localForage.setItem('table-preferences', current_value);
                    return current_value[index];
                }
                current_value.push(value);
                localForage.setItem('table-preferences', current_value);
                return current_value[current_value.length - 1];
            } else {
                localForage.setItem('table-preferences', [value]);
                return value;
            }
        });
    }
}
