import { Component, OnInit, Input } from '@angular/core';
import { FieldConfigDto } from '@shared/service-proxies/service-proxies';
import { UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'app-datetimepicker',
    templateUrl: './datetimepicker.component.html',
})
export class DatetimepickerComponent implements OnInit {
    @Input() field: FieldConfigDto;
    @Input() group: UntypedFormGroup;
    @Input() readonly: boolean;

    date: moment.Moment;

    constructor() {}

    ngOnInit() {
        if (this.group.get(this.field.name).value) {
            this.date = moment(this.group.get(this.field.name).value, 'YYYY-MM-DD LT');
        }
    }
    
    updateState() {
        if(this.readonly) {
            this.group.get(this.field.name).disable();
        }
    }

    onDateTimeChange(value: moment.Moment) {
        if (value) {
            this.group.get(this.field.name).setValue(value.format('YYYY-MM-DD LT'));
        } else {
            this.group.get(this.field.name).setValue(null);
        }
    }
}
