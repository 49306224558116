import { Component, Injector, OnInit, Input } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ThemeAssetContributorFactory } from '@shared/helpers/ThemeAssetContributorFactory';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { version } from 'environments/version';

@Component({
    templateUrl: './footer.component.html',
    selector: 'footer-bar'
})
export class FooterComponent extends AppComponentBase implements OnInit {
    releaseDate: string;
    tenantName;
    appName;
    @Input() useBottomDiv = true;
    webAppGuiVersion: string;

    footerStyle = 'footer bg-white py-4 d-flex flex-lg-column';

    constructor(
        injector: Injector,
        private appSessionService: AppSessionService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.releaseDate = this.appSession.application.releaseDate.format('YYYYMMDD');
        this.webAppGuiVersion = version.number;

        let themeAssetContributor = ThemeAssetContributorFactory.getCurrent();
        if (themeAssetContributor) {
            this.footerStyle = themeAssetContributor.getFooterStyle();
        }
        if (this.appSessionService.tenant && this.appSessionService.tenant.name) {
            this.tenantName = this.appSessionService.tenant.name;
        }
        // Set AppName
        this.appName = this.l('Application');
    }
}
