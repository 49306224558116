/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AirBookRequestBaseDto } from '../models/air-book-request-base-dto';
import { AirBookRequestDto } from '../models/air-book-request-dto';
import { AirBookResponseDto } from '../models/air-book-response-dto';
import { AirExtraRequestDto } from '../models/air-extra-request-dto';
import { AirportDto } from '../models/airport-dto';
import { AjaxResponseOfFareRuleResponseDto } from '../models/ajax-response-of-fare-rule-response-dto';
import { ApiResponseOfBoolean } from '../models/api-response-of-boolean';
import { ApiResponseOfReservationDto } from '../models/api-response-of-reservation-dto';
import { AvailabilityRequestDto } from '../models/availability-request-dto';
import { AvailabilityRequestMulticityDto } from '../models/availability-request-multicity-dto';
import { AvailabilityResponseMulticityDto } from '../models/availability-response-multicity-dto';
import { CalendarShoppingDto } from '../models/calendar-shopping-dto';
import { FlightAncillaryDto } from '../models/flight-ancillary-dto';
import { LeadPriceCalendarDto } from '../models/lead-price-calendar-dto';
import { ListResultDtoOfNameValueDto } from '../models/list-result-dto-of-name-value-dto';
import { PersonNameDataDto } from '../models/person-name-data-dto';
import { ReservationDto } from '../models/reservation-dto';

@Injectable({
  providedIn: 'root',
})
export class FlightService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getDestinationAutocomplete
   */
  static readonly GetDestinationAutocompletePath = '/api/services/app/Flight/GetDestinationAutocomplete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDestinationAutocomplete()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDestinationAutocomplete$Response(params?: {
    query?: string;
    isPackageRateDestination?: boolean;
  }): Observable<StrictHttpResponse<Array<AirportDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.GetDestinationAutocompletePath, 'get');
    if (params) {
      rb.query('query', params.query, {});
      rb.query('isPackageRateDestination', params.isPackageRateDestination, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AirportDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDestinationAutocomplete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDestinationAutocomplete(params?: {
    query?: string;
    isPackageRateDestination?: boolean;
  }): Observable<Array<AirportDto>> {

    return this.getDestinationAutocomplete$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AirportDto>>) => r.body as Array<AirportDto>)
    );
  }

  /**
   * Path part for operation getAirport
   */
  static readonly GetAirportPath = '/api/services/app/Flight/GetAirport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAirport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAirport$Response(params?: {
    code?: string;
  }): Observable<StrictHttpResponse<AirportDto>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.GetAirportPath, 'get');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AirportDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAirport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAirport(params?: {
    code?: string;
  }): Observable<AirportDto> {

    return this.getAirport$Response(params).pipe(
      map((r: StrictHttpResponse<AirportDto>) => r.body as AirportDto)
    );
  }

  /**
   * Path part for operation getAirportNearTo
   */
  static readonly GetAirportNearToPath = '/api/services/app/Flight/GetAirportNearTo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAirportNearTo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAirportNearTo$Response(params?: {
    latitude?: number;
    longitude?: number;
    ray?: number;
  }): Observable<StrictHttpResponse<Array<AirportDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.GetAirportNearToPath, 'get');
    if (params) {
      rb.query('latitude', params.latitude, {});
      rb.query('longitude', params.longitude, {});
      rb.query('ray', params.ray, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AirportDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAirportNearTo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAirportNearTo(params?: {
    latitude?: number;
    longitude?: number;
    ray?: number;
  }): Observable<Array<AirportDto>> {

    return this.getAirportNearTo$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AirportDto>>) => r.body as Array<AirportDto>)
    );
  }

  /**
   * Path part for operation search
   */
  static readonly SearchPath = '/api/services/app/Flight/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Response(params?: {
    body?: AvailabilityRequestDto
  }): Observable<StrictHttpResponse<AvailabilityResponseMulticityDto>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.SearchPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailabilityResponseMulticityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search(params?: {
    body?: AvailabilityRequestDto
  }): Observable<AvailabilityResponseMulticityDto> {

    return this.search$Response(params).pipe(
      map((r: StrictHttpResponse<AvailabilityResponseMulticityDto>) => r.body as AvailabilityResponseMulticityDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  search$Xml$Response(params?: {
    body?: AvailabilityRequestDto
  }): Observable<StrictHttpResponse<AvailabilityResponseMulticityDto>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.SearchPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailabilityResponseMulticityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `search$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  search$Xml(params?: {
    body?: AvailabilityRequestDto
  }): Observable<AvailabilityResponseMulticityDto> {

    return this.search$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AvailabilityResponseMulticityDto>) => r.body as AvailabilityResponseMulticityDto)
    );
  }

  /**
   * Path part for operation searchMulticity
   */
  static readonly SearchMulticityPath = '/api/services/app/Flight/SearchMulticity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchMulticity()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchMulticity$Response(params?: {
    body?: AvailabilityRequestMulticityDto
  }): Observable<StrictHttpResponse<AvailabilityResponseMulticityDto>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.SearchMulticityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailabilityResponseMulticityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchMulticity$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchMulticity(params?: {
    body?: AvailabilityRequestMulticityDto
  }): Observable<AvailabilityResponseMulticityDto> {

    return this.searchMulticity$Response(params).pipe(
      map((r: StrictHttpResponse<AvailabilityResponseMulticityDto>) => r.body as AvailabilityResponseMulticityDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchMulticity$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  searchMulticity$Xml$Response(params?: {
    body?: AvailabilityRequestMulticityDto
  }): Observable<StrictHttpResponse<AvailabilityResponseMulticityDto>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.SearchMulticityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailabilityResponseMulticityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchMulticity$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  searchMulticity$Xml(params?: {
    body?: AvailabilityRequestMulticityDto
  }): Observable<AvailabilityResponseMulticityDto> {

    return this.searchMulticity$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AvailabilityResponseMulticityDto>) => r.body as AvailabilityResponseMulticityDto)
    );
  }

  /**
   * Path part for operation calendarShopping
   */
  static readonly CalendarShoppingPath = '/api/services/app/Flight/CalendarShopping';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarShopping()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  calendarShopping$Response(params?: {
    body?: AvailabilityRequestMulticityDto
  }): Observable<StrictHttpResponse<CalendarShoppingDto>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.CalendarShoppingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalendarShoppingDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarShopping$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  calendarShopping(params?: {
    body?: AvailabilityRequestMulticityDto
  }): Observable<CalendarShoppingDto> {

    return this.calendarShopping$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarShoppingDto>) => r.body as CalendarShoppingDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarShopping$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  calendarShopping$Xml$Response(params?: {
    body?: AvailabilityRequestMulticityDto
  }): Observable<StrictHttpResponse<CalendarShoppingDto>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.CalendarShoppingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalendarShoppingDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarShopping$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  calendarShopping$Xml(params?: {
    body?: AvailabilityRequestMulticityDto
  }): Observable<CalendarShoppingDto> {

    return this.calendarShopping$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarShoppingDto>) => r.body as CalendarShoppingDto)
    );
  }

  /**
   * Path part for operation startSearch_1
   */
  static readonly StartSearch_1Path = '/api/services/app/Flight/StartSearch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startSearch_1()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startSearch_1$Response(params?: {
    body?: AvailabilityRequestMulticityDto
  }): Observable<StrictHttpResponse<AvailabilityResponseMulticityDto>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.StartSearch_1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailabilityResponseMulticityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `startSearch_1$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startSearch_1(params?: {
    body?: AvailabilityRequestMulticityDto
  }): Observable<AvailabilityResponseMulticityDto> {

    return this.startSearch_1$Response(params).pipe(
      map((r: StrictHttpResponse<AvailabilityResponseMulticityDto>) => r.body as AvailabilityResponseMulticityDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startSearch_1$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  startSearch_1$Xml$Response(params?: {
    body?: AvailabilityRequestMulticityDto
  }): Observable<StrictHttpResponse<AvailabilityResponseMulticityDto>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.StartSearch_1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailabilityResponseMulticityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `startSearch_1$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  startSearch_1$Xml(params?: {
    body?: AvailabilityRequestMulticityDto
  }): Observable<AvailabilityResponseMulticityDto> {

    return this.startSearch_1$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AvailabilityResponseMulticityDto>) => r.body as AvailabilityResponseMulticityDto)
    );
  }

  /**
   * Path part for operation checkSearch
   */
  static readonly CheckSearchPath = '/api/services/app/Flight/CheckSearch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkSearch$Response(params?: {
    availabilityId?: string;
  }): Observable<StrictHttpResponse<AvailabilityResponseMulticityDto>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.CheckSearchPath, 'post');
    if (params) {
      rb.query('availabilityId', params.availabilityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailabilityResponseMulticityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkSearch(params?: {
    availabilityId?: string;
  }): Observable<AvailabilityResponseMulticityDto> {

    return this.checkSearch$Response(params).pipe(
      map((r: StrictHttpResponse<AvailabilityResponseMulticityDto>) => r.body as AvailabilityResponseMulticityDto)
    );
  }

  /**
   * Path part for operation getCheckoutData
   */
  static readonly GetCheckoutDataPath = '/api/services/app/Flight/GetCheckoutData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCheckoutData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCheckoutData$Response(params?: {
    AvailabilityId?: string;
    GroupItineraryId?: string;
    ItineraryId?: string;
    PricingInformationId?: string;
    OutwardId?: string;
    ReturnId?: string;
  }): Observable<StrictHttpResponse<AirBookResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.GetCheckoutDataPath, 'get');
    if (params) {
      rb.query('AvailabilityId', params.AvailabilityId, {});
      rb.query('GroupItineraryId', params.GroupItineraryId, {});
      rb.query('ItineraryId', params.ItineraryId, {});
      rb.query('PricingInformationId', params.PricingInformationId, {});
      rb.query('OutwardId', params.OutwardId, {});
      rb.query('ReturnId', params.ReturnId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AirBookResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCheckoutData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCheckoutData(params?: {
    AvailabilityId?: string;
    GroupItineraryId?: string;
    ItineraryId?: string;
    PricingInformationId?: string;
    OutwardId?: string;
    ReturnId?: string;
  }): Observable<AirBookResponseDto> {

    return this.getCheckoutData$Response(params).pipe(
      map((r: StrictHttpResponse<AirBookResponseDto>) => r.body as AirBookResponseDto)
    );
  }

  /**
   * Path part for operation seatMapData
   */
  static readonly SeatMapDataPath = '/api/services/app/Flight/SeatMapData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `seatMapData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  seatMapData$Response(params?: {
    body?: AirExtraRequestDto
  }): Observable<StrictHttpResponse<FlightAncillaryDto>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.SeatMapDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FlightAncillaryDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `seatMapData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  seatMapData(params?: {
    body?: AirExtraRequestDto
  }): Observable<FlightAncillaryDto> {

    return this.seatMapData$Response(params).pipe(
      map((r: StrictHttpResponse<FlightAncillaryDto>) => r.body as FlightAncillaryDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `seatMapData$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  seatMapData$Xml$Response(params?: {
    body?: AirExtraRequestDto
  }): Observable<StrictHttpResponse<FlightAncillaryDto>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.SeatMapDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FlightAncillaryDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `seatMapData$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  seatMapData$Xml(params?: {
    body?: AirExtraRequestDto
  }): Observable<FlightAncillaryDto> {

    return this.seatMapData$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<FlightAncillaryDto>) => r.body as FlightAncillaryDto)
    );
  }

  /**
   * Path part for operation getCheckoutDataByReservationId_1
   */
  static readonly GetCheckoutDataByReservationId_1Path = '/api/services/app/Flight/GetCheckoutDataByReservationId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCheckoutDataByReservationId_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCheckoutDataByReservationId_1$Response(params?: {
    reservationId?: string;
  }): Observable<StrictHttpResponse<AirBookResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.GetCheckoutDataByReservationId_1Path, 'get');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AirBookResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCheckoutDataByReservationId_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCheckoutDataByReservationId_1(params?: {
    reservationId?: string;
  }): Observable<AirBookResponseDto> {

    return this.getCheckoutDataByReservationId_1$Response(params).pipe(
      map((r: StrictHttpResponse<AirBookResponseDto>) => r.body as AirBookResponseDto)
    );
  }

  /**
   * Path part for operation validate
   */
  static readonly ValidatePath = '/api/services/app/Flight/Validate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  validate$Response(params?: {
    availabilityId?: string;
    groupItineraryId?: string;
    itineraryId?: string;
    pricingInformationId?: string;
    body?: Array<PersonNameDataDto>
  }): Observable<StrictHttpResponse<ApiResponseOfBoolean>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.ValidatePath, 'post');
    if (params) {
      rb.query('availabilityId', params.availabilityId, {});
      rb.query('groupItineraryId', params.groupItineraryId, {});
      rb.query('itineraryId', params.itineraryId, {});
      rb.query('pricingInformationId', params.pricingInformationId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponseOfBoolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `validate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  validate(params?: {
    availabilityId?: string;
    groupItineraryId?: string;
    itineraryId?: string;
    pricingInformationId?: string;
    body?: Array<PersonNameDataDto>
  }): Observable<ApiResponseOfBoolean> {

    return this.validate$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponseOfBoolean>) => r.body as ApiResponseOfBoolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validate$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  validate$Xml$Response(params?: {
    availabilityId?: string;
    groupItineraryId?: string;
    itineraryId?: string;
    pricingInformationId?: string;
    body?: Array<PersonNameDataDto>
  }): Observable<StrictHttpResponse<ApiResponseOfBoolean>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.ValidatePath, 'post');
    if (params) {
      rb.query('availabilityId', params.availabilityId, {});
      rb.query('groupItineraryId', params.groupItineraryId, {});
      rb.query('itineraryId', params.itineraryId, {});
      rb.query('pricingInformationId', params.pricingInformationId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponseOfBoolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `validate$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  validate$Xml(params?: {
    availabilityId?: string;
    groupItineraryId?: string;
    itineraryId?: string;
    pricingInformationId?: string;
    body?: Array<PersonNameDataDto>
  }): Observable<ApiResponseOfBoolean> {

    return this.validate$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponseOfBoolean>) => r.body as ApiResponseOfBoolean)
    );
  }

  /**
   * Path part for operation book_1
   */
  static readonly Book_1Path = '/api/services/app/Flight/Book';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `book_1()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  book_1$Response(params?: {
    body?: AirBookRequestDto
  }): Observable<StrictHttpResponse<ApiResponseOfReservationDto>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.Book_1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponseOfReservationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `book_1$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  book_1(params?: {
    body?: AirBookRequestDto
  }): Observable<ApiResponseOfReservationDto> {

    return this.book_1$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponseOfReservationDto>) => r.body as ApiResponseOfReservationDto)
    );
  }

  /**
   * Path part for operation issue_1
   */
  static readonly Issue_1Path = '/api/services/app/Flight/Issue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issue_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  issue_1$Response(params?: {
    orderId?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.Issue_1Path, 'post');
    if (params) {
      rb.query('orderId', params.orderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `issue_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issue_1(params?: {
    orderId?: string;
  }): Observable<void> {

    return this.issue_1$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation confirm
   */
  static readonly ConfirmPath = '/api/services/app/Flight/Confirm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirm()` instead.
   *
   * This method doesn't expect any request body.
   */
  confirm$Response(params?: {
    orderId?: string;
  }): Observable<StrictHttpResponse<ReservationDto>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.ConfirmPath, 'post');
    if (params) {
      rb.query('orderId', params.orderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReservationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `confirm$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  confirm(params?: {
    orderId?: string;
  }): Observable<ReservationDto> {

    return this.confirm$Response(params).pipe(
      map((r: StrictHttpResponse<ReservationDto>) => r.body as ReservationDto)
    );
  }

  /**
   * Path part for operation getLeadPriceCalendar
   */
  static readonly GetLeadPriceCalendarPath = '/api/services/app/Flight/GetLeadPriceCalendar';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLeadPriceCalendar()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeadPriceCalendar$Response(params?: {
    Origin?: string;
    Destination?: string;
    PointOfSaleCountry?: string;
    LengthOfStay?: string;
    MinFare?: number;
    MaxFare?: number;
    DepartureDate?: string;
    NumOfdays?: number;
  }): Observable<StrictHttpResponse<Array<LeadPriceCalendarDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.GetLeadPriceCalendarPath, 'get');
    if (params) {
      rb.query('Origin', params.Origin, {});
      rb.query('Destination', params.Destination, {});
      rb.query('PointOfSaleCountry', params.PointOfSaleCountry, {});
      rb.query('LengthOfStay', params.LengthOfStay, {});
      rb.query('MinFare', params.MinFare, {});
      rb.query('MaxFare', params.MaxFare, {});
      rb.query('DepartureDate', params.DepartureDate, {});
      rb.query('NumOfdays', params.NumOfdays, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LeadPriceCalendarDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLeadPriceCalendar$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeadPriceCalendar(params?: {
    Origin?: string;
    Destination?: string;
    PointOfSaleCountry?: string;
    LengthOfStay?: string;
    MinFare?: number;
    MaxFare?: number;
    DepartureDate?: string;
    NumOfdays?: number;
  }): Observable<Array<LeadPriceCalendarDto>> {

    return this.getLeadPriceCalendar$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LeadPriceCalendarDto>>) => r.body as Array<LeadPriceCalendarDto>)
    );
  }

  /**
   * Path part for operation fareRules
   */
  static readonly FareRulesPath = '/api/services/app/Flight/FareRules';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fareRules()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  fareRules$Response(params?: {
    body?: AirBookRequestBaseDto
  }): Observable<StrictHttpResponse<AjaxResponseOfFareRuleResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.FareRulesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfFareRuleResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fareRules$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  fareRules(params?: {
    body?: AirBookRequestBaseDto
  }): Observable<AjaxResponseOfFareRuleResponseDto> {

    return this.fareRules$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfFareRuleResponseDto>) => r.body as AjaxResponseOfFareRuleResponseDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fareRules$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  fareRules$Xml$Response(params?: {
    body?: AirBookRequestBaseDto
  }): Observable<StrictHttpResponse<AjaxResponseOfFareRuleResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.FareRulesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfFareRuleResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fareRules$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  fareRules$Xml(params?: {
    body?: AirBookRequestBaseDto
  }): Observable<AjaxResponseOfFareRuleResponseDto> {

    return this.fareRules$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfFareRuleResponseDto>) => r.body as AjaxResponseOfFareRuleResponseDto)
    );
  }

  /**
   * Path part for operation fareRulesReservation
   */
  static readonly FareRulesReservationPath = '/api/services/app/Flight/FareRulesReservation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fareRulesReservation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  fareRulesReservation$Response(params?: {
    reservationId?: string;
    body?: AirBookRequestBaseDto
  }): Observable<StrictHttpResponse<AjaxResponseOfFareRuleResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.FareRulesReservationPath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfFareRuleResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fareRulesReservation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  fareRulesReservation(params?: {
    reservationId?: string;
    body?: AirBookRequestBaseDto
  }): Observable<AjaxResponseOfFareRuleResponseDto> {

    return this.fareRulesReservation$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfFareRuleResponseDto>) => r.body as AjaxResponseOfFareRuleResponseDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fareRulesReservation$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  fareRulesReservation$Xml$Response(params?: {
    reservationId?: string;
    body?: AirBookRequestBaseDto
  }): Observable<StrictHttpResponse<AjaxResponseOfFareRuleResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.FareRulesReservationPath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfFareRuleResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fareRulesReservation$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  fareRulesReservation$Xml(params?: {
    reservationId?: string;
    body?: AirBookRequestBaseDto
  }): Observable<AjaxResponseOfFareRuleResponseDto> {

    return this.fareRulesReservation$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfFareRuleResponseDto>) => r.body as AjaxResponseOfFareRuleResponseDto)
    );
  }

  /**
   * Path part for operation fareDescription
   */
  static readonly FareDescriptionPath = '/api/services/app/Flight/FareDescription';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fareDescription()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  fareDescription$Response(params?: {
    body?: AirBookRequestBaseDto
  }): Observable<StrictHttpResponse<ListResultDtoOfNameValueDto>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.FareDescriptionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListResultDtoOfNameValueDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fareDescription$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  fareDescription(params?: {
    body?: AirBookRequestBaseDto
  }): Observable<ListResultDtoOfNameValueDto> {

    return this.fareDescription$Response(params).pipe(
      map((r: StrictHttpResponse<ListResultDtoOfNameValueDto>) => r.body as ListResultDtoOfNameValueDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fareDescription$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  fareDescription$Xml$Response(params?: {
    body?: AirBookRequestBaseDto
  }): Observable<StrictHttpResponse<ListResultDtoOfNameValueDto>> {

    const rb = new RequestBuilder(this.rootUrl, FlightService.FareDescriptionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListResultDtoOfNameValueDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fareDescription$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  fareDescription$Xml(params?: {
    body?: AirBookRequestBaseDto
  }): Observable<ListResultDtoOfNameValueDto> {

    return this.fareDescription$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ListResultDtoOfNameValueDto>) => r.body as ListResultDtoOfNameValueDto)
    );
  }

}
