import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TravelItemComponent } from './travel-item.component';
import { PricingInformationTablesComponent } from '../pricing-information-tables/pricing-information-tables.component';
import { ItineraryDetailComponent } from './itinerary-detail/itinerary-detail.component';
import { RouterModule } from '@angular/router';
import { UtilsModule } from '@shared/utils/utils.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { LegDetailsModule } from './leg-details/leg-details.module';
import { ShareSerpService } from '@app/shared/services/flight/share-serp.service';
import { SearchResultUtilitiesModule } from '@app/shared/directives/search-result-utilities/search-result-utilities.module';
import { PopoverOutViewportModule } from '@app/shared/directives/popover-out-viewport/popover-out-viewport.module';
import { PricingInformationModule } from './pricing-information/pricing-information.module';
import { UtilitiesModule } from '@app/shared/pipes/utilities.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AlertModule } from 'ngx-bootstrap/alert';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UtilsModule,
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        ModalModule.forRoot(),
        CollapseModule.forRoot(),
        AlertModule.forRoot(),
        MatButtonModule,
        LegDetailsModule,
        PopoverOutViewportModule,
        SearchResultUtilitiesModule,
        PricingInformationModule,
        UtilitiesModule,
        MatIconModule
    ],
    declarations: [
        TravelItemComponent,
        PricingInformationTablesComponent,
        ItineraryDetailComponent,
    ],
    exports: [
        TravelItemComponent,
        ItineraryDetailComponent
    ],
    providers: [ShareSerpService]
})
export class TravelItemModule { }
