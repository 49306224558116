import { Component, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';

@Component({
  selector: 'app-filter-date-range-picker',
  templateUrl: './filter-date-range-picker.component.html',
})
export class FilterDateRangePickerComponent extends AppComponentBase {

  date: Date;
  //modificata la data di default per dashboard impostando un range arbitrario degli ultimi 30 giorni
  selectedDateRange: moment.Moment[] = [moment().add(-30, 'day').startOf('day'), moment().endOf('day')];
  constructor(injector: Injector) {
    super(injector);
  }

  onChange() {
    //il filtro per data viene reso inclusivo
    this.selectedDateRange[0] = this.selectedDateRange[0].startOf('day');
    this.selectedDateRange[1] = this.selectedDateRange[1].endOf('day');

    abp.event.trigger('app.dashboardFilters.dateRangePicker.onDateChange', this.selectedDateRange);
  }
}
