import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PhoneInputDirective } from './phone-input.directive';

@NgModule({
    imports: [CommonModule],
    exports: [PhoneInputDirective],
    declarations: [PhoneInputDirective],
})
export class PhoneInputModule { }
