/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AjaxResponseOfApiResponseOfReservationDto } from '../models/ajax-response-of-api-response-of-reservation-dto';
import { AjaxResponseOfAvailabilityResponseMulticityDto } from '../models/ajax-response-of-availability-response-multicity-dto';
import { AjaxResponseOfBoolean } from '../models/ajax-response-of-boolean';
import { AjaxResponseOfIEnumerableOfLocationDto } from '../models/ajax-response-of-i-enumerable-of-location-dto';
import { AjaxResponseOfTrainBookResponseDto } from '../models/ajax-response-of-train-book-response-dto';
import { AjaxResponseOfTrainExtraOffersResponseDto } from '../models/ajax-response-of-train-extra-offers-response-dto';
import { AjaxResponseOfTrainSeatsResponse } from '../models/ajax-response-of-train-seats-response';
import { ApiResponseOfReservationDto } from '../models/api-response-of-reservation-dto';
import { ApiResponseOfTrainLayoutDto } from '../models/api-response-of-train-layout-dto';
import { AvailabilityResponseMulticityDto } from '../models/availability-response-multicity-dto';
import { ExtraOfferDto } from '../models/extra-offer-dto';
import { FareOfferDto } from '../models/fare-offer-dto';
import { HiddenStopDto } from '../models/hidden-stop-dto';
import { LocationDto } from '../models/location-dto';
import { ModifyResult } from '../models/modify-result';
import { ReservationDto } from '../models/reservation-dto';
import { SeatsDto } from '../models/seats-dto';
import { TrainAvailabilityRequestDto } from '../models/train-availability-request-dto';
import { TrainBookRequestBaseDto } from '../models/train-book-request-base-dto';
import { TrainBookRequestDto } from '../models/train-book-request-dto';
import { TrainPaxClientDto } from '../models/train-pax-client-dto';
import { UpdatePassengersDto } from '../models/update-passengers-dto';

@Injectable({
  providedIn: 'root',
})
export class TrainService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getTrainPassengerAge
   */
  static readonly GetTrainPassengerAgePath = '/api/services/app/Train/GetTrainPassengerAge';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTrainPassengerAge()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrainPassengerAge$Response(params?: {
  }): Observable<StrictHttpResponse<Array<TrainPaxClientDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.GetTrainPassengerAgePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TrainPaxClientDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTrainPassengerAge$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrainPassengerAge(params?: {
  }): Observable<Array<TrainPaxClientDto>> {

    return this.getTrainPassengerAge$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TrainPaxClientDto>>) => r.body as Array<TrainPaxClientDto>)
    );
  }

  /**
   * Path part for operation getDestinationAutocomplete_2
   */
  static readonly GetDestinationAutocomplete_2Path = '/api/services/app/Train/GetDestinationAutocomplete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDestinationAutocomplete_2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDestinationAutocomplete_2$Response(params?: {
    query?: string;
    isCarnet?: boolean;
  }): Observable<StrictHttpResponse<AjaxResponseOfIEnumerableOfLocationDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.GetDestinationAutocomplete_2Path, 'get');
    if (params) {
      rb.query('query', params.query, {});
      rb.query('isCarnet', params.isCarnet, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfIEnumerableOfLocationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDestinationAutocomplete_2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDestinationAutocomplete_2(params?: {
    query?: string;
    isCarnet?: boolean;
  }): Observable<AjaxResponseOfIEnumerableOfLocationDto> {

    return this.getDestinationAutocomplete_2$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfIEnumerableOfLocationDto>) => r.body as AjaxResponseOfIEnumerableOfLocationDto)
    );
  }

  /**
   * Path part for operation getStationByAirportCode
   */
  static readonly GetStationByAirportCodePath = '/api/services/app/Train/GetStationByAirportCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStationByAirportCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStationByAirportCode$Response(params?: {
    airportCode?: string;
  }): Observable<StrictHttpResponse<Array<LocationDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.GetStationByAirportCodePath, 'get');
    if (params) {
      rb.query('airportCode', params.airportCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LocationDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStationByAirportCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStationByAirportCode(params?: {
    airportCode?: string;
  }): Observable<Array<LocationDto>> {

    return this.getStationByAirportCode$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LocationDto>>) => r.body as Array<LocationDto>)
    );
  }

  /**
   * Path part for operation getStationNearTo
   */
  static readonly GetStationNearToPath = '/api/services/app/Train/GetStationNearTo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStationNearTo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStationNearTo$Response(params?: {
    latitude?: number;
    longitude?: number;
    ray?: number;
  }): Observable<StrictHttpResponse<Array<LocationDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.GetStationNearToPath, 'get');
    if (params) {
      rb.query('latitude', params.latitude, {});
      rb.query('longitude', params.longitude, {});
      rb.query('ray', params.ray, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LocationDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStationNearTo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStationNearTo(params?: {
    latitude?: number;
    longitude?: number;
    ray?: number;
  }): Observable<Array<LocationDto>> {

    return this.getStationNearTo$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LocationDto>>) => r.body as Array<LocationDto>)
    );
  }

  /**
   * Path part for operation search_1
   */
  static readonly Search_1Path = '/api/services/app/Train/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search_1()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search_1$Response(params?: {
    body?: TrainAvailabilityRequestDto
  }): Observable<StrictHttpResponse<AjaxResponseOfAvailabilityResponseMulticityDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.Search_1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfAvailabilityResponseMulticityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `search_1$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search_1(params?: {
    body?: TrainAvailabilityRequestDto
  }): Observable<AjaxResponseOfAvailabilityResponseMulticityDto> {

    return this.search_1$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfAvailabilityResponseMulticityDto>) => r.body as AjaxResponseOfAvailabilityResponseMulticityDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search_1$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  search_1$Xml$Response(params?: {
    body?: TrainAvailabilityRequestDto
  }): Observable<StrictHttpResponse<AjaxResponseOfAvailabilityResponseMulticityDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.Search_1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfAvailabilityResponseMulticityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `search_1$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  search_1$Xml(params?: {
    body?: TrainAvailabilityRequestDto
  }): Observable<AjaxResponseOfAvailabilityResponseMulticityDto> {

    return this.search_1$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfAvailabilityResponseMulticityDto>) => r.body as AjaxResponseOfAvailabilityResponseMulticityDto)
    );
  }

  /**
   * Path part for operation startSearch_3
   */
  static readonly StartSearch_3Path = '/api/services/app/Train/StartSearch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startSearch_3()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startSearch_3$Response(params?: {
    body?: TrainAvailabilityRequestDto
  }): Observable<StrictHttpResponse<AvailabilityResponseMulticityDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.StartSearch_3Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailabilityResponseMulticityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `startSearch_3$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startSearch_3(params?: {
    body?: TrainAvailabilityRequestDto
  }): Observable<AvailabilityResponseMulticityDto> {

    return this.startSearch_3$Response(params).pipe(
      map((r: StrictHttpResponse<AvailabilityResponseMulticityDto>) => r.body as AvailabilityResponseMulticityDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startSearch_3$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  startSearch_3$Xml$Response(params?: {
    body?: TrainAvailabilityRequestDto
  }): Observable<StrictHttpResponse<AvailabilityResponseMulticityDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.StartSearch_3Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailabilityResponseMulticityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `startSearch_3$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  startSearch_3$Xml(params?: {
    body?: TrainAvailabilityRequestDto
  }): Observable<AvailabilityResponseMulticityDto> {

    return this.startSearch_3$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AvailabilityResponseMulticityDto>) => r.body as AvailabilityResponseMulticityDto)
    );
  }

  /**
   * Path part for operation checkSearch_1
   */
  static readonly CheckSearch_1Path = '/api/services/app/Train/CheckSearch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkSearch_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkSearch_1$Response(params?: {
    availabilityId?: string;
  }): Observable<StrictHttpResponse<AvailabilityResponseMulticityDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.CheckSearch_1Path, 'post');
    if (params) {
      rb.query('availabilityId', params.availabilityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailabilityResponseMulticityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkSearch_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkSearch_1(params?: {
    availabilityId?: string;
  }): Observable<AvailabilityResponseMulticityDto> {

    return this.checkSearch_1$Response(params).pipe(
      map((r: StrictHttpResponse<AvailabilityResponseMulticityDto>) => r.body as AvailabilityResponseMulticityDto)
    );
  }

  /**
   * Path part for operation fareOffers
   */
  static readonly FareOffersPath = '/api/services/app/Train/FareOffers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fareOffers()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  fareOffers$Response(params?: {
    postSale?: boolean;
    body?: TrainBookRequestBaseDto
  }): Observable<StrictHttpResponse<Array<FareOfferDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.FareOffersPath, 'post');
    if (params) {
      rb.query('postSale', params.postSale, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FareOfferDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fareOffers$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  fareOffers(params?: {
    postSale?: boolean;
    body?: TrainBookRequestBaseDto
  }): Observable<Array<FareOfferDto>> {

    return this.fareOffers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FareOfferDto>>) => r.body as Array<FareOfferDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fareOffers$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  fareOffers$Xml$Response(params?: {
    postSale?: boolean;
    body?: TrainBookRequestBaseDto
  }): Observable<StrictHttpResponse<Array<FareOfferDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.FareOffersPath, 'post');
    if (params) {
      rb.query('postSale', params.postSale, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FareOfferDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fareOffers$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  fareOffers$Xml(params?: {
    postSale?: boolean;
    body?: TrainBookRequestBaseDto
  }): Observable<Array<FareOfferDto>> {

    return this.fareOffers$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FareOfferDto>>) => r.body as Array<FareOfferDto>)
    );
  }

  /**
   * Path part for operation trainStops
   */
  static readonly TrainStopsPath = '/api/services/app/Train/TrainStops';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainStops()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  trainStops$Response(params?: {
    segmentId?: string;
    body?: TrainBookRequestBaseDto
  }): Observable<StrictHttpResponse<Array<HiddenStopDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.TrainStopsPath, 'post');
    if (params) {
      rb.query('segmentId', params.segmentId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HiddenStopDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainStops$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  trainStops(params?: {
    segmentId?: string;
    body?: TrainBookRequestBaseDto
  }): Observable<Array<HiddenStopDto>> {

    return this.trainStops$Response(params).pipe(
      map((r: StrictHttpResponse<Array<HiddenStopDto>>) => r.body as Array<HiddenStopDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainStops$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  trainStops$Xml$Response(params?: {
    segmentId?: string;
    body?: TrainBookRequestBaseDto
  }): Observable<StrictHttpResponse<Array<HiddenStopDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.TrainStopsPath, 'post');
    if (params) {
      rb.query('segmentId', params.segmentId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HiddenStopDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainStops$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  trainStops$Xml(params?: {
    segmentId?: string;
    body?: TrainBookRequestBaseDto
  }): Observable<Array<HiddenStopDto>> {

    return this.trainStops$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<Array<HiddenStopDto>>) => r.body as Array<HiddenStopDto>)
    );
  }

  /**
   * Path part for operation checkoutData_1
   */
  static readonly CheckoutData_1Path = '/api/services/app/Train/CheckoutData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkoutData_1()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  checkoutData_1$Response(params?: {
    postSale?: boolean;
    body?: TrainBookRequestBaseDto
  }): Observable<StrictHttpResponse<AjaxResponseOfTrainBookResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.CheckoutData_1Path, 'post');
    if (params) {
      rb.query('postSale', params.postSale, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTrainBookResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkoutData_1$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  checkoutData_1(params?: {
    postSale?: boolean;
    body?: TrainBookRequestBaseDto
  }): Observable<AjaxResponseOfTrainBookResponseDto> {

    return this.checkoutData_1$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTrainBookResponseDto>) => r.body as AjaxResponseOfTrainBookResponseDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkoutData_1$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  checkoutData_1$Xml$Response(params?: {
    postSale?: boolean;
    body?: TrainBookRequestBaseDto
  }): Observable<StrictHttpResponse<AjaxResponseOfTrainBookResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.CheckoutData_1Path, 'post');
    if (params) {
      rb.query('postSale', params.postSale, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTrainBookResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkoutData_1$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  checkoutData_1$Xml(params?: {
    postSale?: boolean;
    body?: TrainBookRequestBaseDto
  }): Observable<AjaxResponseOfTrainBookResponseDto> {

    return this.checkoutData_1$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTrainBookResponseDto>) => r.body as AjaxResponseOfTrainBookResponseDto)
    );
  }

  /**
   * Path part for operation getCheckoutDataByReservationId_3
   */
  static readonly GetCheckoutDataByReservationId_3Path = '/api/services/app/Train/GetCheckoutDataByReservationId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCheckoutDataByReservationId_3()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCheckoutDataByReservationId_3$Response(params?: {
    reservationId?: string;
    postSale?: boolean;
  }): Observable<StrictHttpResponse<AjaxResponseOfTrainBookResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.GetCheckoutDataByReservationId_3Path, 'get');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.query('postSale', params.postSale, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTrainBookResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCheckoutDataByReservationId_3$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCheckoutDataByReservationId_3(params?: {
    reservationId?: string;
    postSale?: boolean;
  }): Observable<AjaxResponseOfTrainBookResponseDto> {

    return this.getCheckoutDataByReservationId_3$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTrainBookResponseDto>) => r.body as AjaxResponseOfTrainBookResponseDto)
    );
  }

  /**
   * Path part for operation seatMapData_2
   */
  static readonly SeatMapData_2Path = '/api/services/app/Train/SeatMapData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `seatMapData_2()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  seatMapData_2$Response(params?: {
    orderId?: string;
    postSale?: boolean;
    body?: TrainBookRequestBaseDto
  }): Observable<StrictHttpResponse<ApiResponseOfTrainLayoutDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.SeatMapData_2Path, 'post');
    if (params) {
      rb.query('orderId', params.orderId, {});
      rb.query('postSale', params.postSale, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponseOfTrainLayoutDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `seatMapData_2$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  seatMapData_2(params?: {
    orderId?: string;
    postSale?: boolean;
    body?: TrainBookRequestBaseDto
  }): Observable<ApiResponseOfTrainLayoutDto> {

    return this.seatMapData_2$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponseOfTrainLayoutDto>) => r.body as ApiResponseOfTrainLayoutDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `seatMapData_2$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  seatMapData_2$Xml$Response(params?: {
    orderId?: string;
    postSale?: boolean;
    body?: TrainBookRequestBaseDto
  }): Observable<StrictHttpResponse<ApiResponseOfTrainLayoutDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.SeatMapData_2Path, 'post');
    if (params) {
      rb.query('orderId', params.orderId, {});
      rb.query('postSale', params.postSale, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponseOfTrainLayoutDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `seatMapData_2$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  seatMapData_2$Xml(params?: {
    orderId?: string;
    postSale?: boolean;
    body?: TrainBookRequestBaseDto
  }): Observable<ApiResponseOfTrainLayoutDto> {

    return this.seatMapData_2$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponseOfTrainLayoutDto>) => r.body as ApiResponseOfTrainLayoutDto)
    );
  }

  /**
   * Path part for operation seatMapDataItalo
   */
  static readonly SeatMapDataItaloPath = '/api/services/app/Train/SeatMapDataItalo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `seatMapDataItalo()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  seatMapDataItalo$Response(params?: {
    orderId?: string;
    tripId?: string;
    body?: TrainBookRequestBaseDto
  }): Observable<StrictHttpResponse<AjaxResponseOfTrainSeatsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.SeatMapDataItaloPath, 'post');
    if (params) {
      rb.query('orderId', params.orderId, {});
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTrainSeatsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `seatMapDataItalo$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  seatMapDataItalo(params?: {
    orderId?: string;
    tripId?: string;
    body?: TrainBookRequestBaseDto
  }): Observable<AjaxResponseOfTrainSeatsResponse> {

    return this.seatMapDataItalo$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTrainSeatsResponse>) => r.body as AjaxResponseOfTrainSeatsResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `seatMapDataItalo$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  seatMapDataItalo$Xml$Response(params?: {
    orderId?: string;
    tripId?: string;
    body?: TrainBookRequestBaseDto
  }): Observable<StrictHttpResponse<AjaxResponseOfTrainSeatsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.SeatMapDataItaloPath, 'post');
    if (params) {
      rb.query('orderId', params.orderId, {});
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTrainSeatsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `seatMapDataItalo$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  seatMapDataItalo$Xml(params?: {
    orderId?: string;
    tripId?: string;
    body?: TrainBookRequestBaseDto
  }): Observable<AjaxResponseOfTrainSeatsResponse> {

    return this.seatMapDataItalo$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTrainSeatsResponse>) => r.body as AjaxResponseOfTrainSeatsResponse)
    );
  }

  /**
   * Path part for operation updatePassengers
   */
  static readonly UpdatePassengersPath = '/api/services/app/Train/UpdatePassengers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePassengers()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePassengers$Response(params?: {
    orderId?: string;
    body?: UpdatePassengersDto
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.UpdatePassengersPath, 'put');
    if (params) {
      rb.query('orderId', params.orderId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePassengers$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePassengers(params?: {
    orderId?: string;
    body?: UpdatePassengersDto
  }): Observable<boolean> {

    return this.updatePassengers$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePassengers$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  updatePassengers$Xml$Response(params?: {
    orderId?: string;
    body?: UpdatePassengersDto
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.UpdatePassengersPath, 'put');
    if (params) {
      rb.query('orderId', params.orderId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePassengers$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  updatePassengers$Xml(params?: {
    orderId?: string;
    body?: UpdatePassengersDto
  }): Observable<boolean> {

    return this.updatePassengers$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation book_2
   */
  static readonly Book_2Path = '/api/services/app/Train/Book';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `book_2()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  book_2$Response(params?: {
    body?: TrainBookRequestDto
  }): Observable<StrictHttpResponse<ApiResponseOfReservationDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.Book_2Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponseOfReservationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `book_2$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  book_2(params?: {
    body?: TrainBookRequestDto
  }): Observable<ApiResponseOfReservationDto> {

    return this.book_2$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponseOfReservationDto>) => r.body as ApiResponseOfReservationDto)
    );
  }

  /**
   * Path part for operation getExtraOptions
   */
  static readonly GetExtraOptionsPath = '/api/services/app/Train/GetExtraOptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExtraOptions()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getExtraOptions$Response(params?: {
    orderId?: string;
    body?: TrainBookRequestDto
  }): Observable<StrictHttpResponse<AjaxResponseOfTrainExtraOffersResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.GetExtraOptionsPath, 'post');
    if (params) {
      rb.query('orderId', params.orderId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTrainExtraOffersResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getExtraOptions$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getExtraOptions(params?: {
    orderId?: string;
    body?: TrainBookRequestDto
  }): Observable<AjaxResponseOfTrainExtraOffersResponseDto> {

    return this.getExtraOptions$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTrainExtraOffersResponseDto>) => r.body as AjaxResponseOfTrainExtraOffersResponseDto)
    );
  }

  /**
   * Path part for operation issue_3
   */
  static readonly Issue_3Path = '/api/services/app/Train/Issue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issue_3()` instead.
   *
   * This method doesn't expect any request body.
   */
  issue_3$Response(params?: {
    orderId?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.Issue_3Path, 'post');
    if (params) {
      rb.query('orderId', params.orderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `issue_3$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issue_3(params?: {
    orderId?: string;
  }): Observable<void> {

    return this.issue_3$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation assignSeats
   */
  static readonly AssignSeatsPath = '/api/services/app/Train/AssignSeats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignSeats()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assignSeats$Response(params?: {
    orderId?: string;
    availabilityId?: string;
    isTrip?: boolean;
    body?: Array<SeatsDto>
  }): Observable<StrictHttpResponse<AjaxResponseOfApiResponseOfReservationDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.AssignSeatsPath, 'post');
    if (params) {
      rb.query('orderId', params.orderId, {});
      rb.query('availabilityId', params.availabilityId, {});
      rb.query('isTrip', params.isTrip, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfApiResponseOfReservationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `assignSeats$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assignSeats(params?: {
    orderId?: string;
    availabilityId?: string;
    isTrip?: boolean;
    body?: Array<SeatsDto>
  }): Observable<AjaxResponseOfApiResponseOfReservationDto> {

    return this.assignSeats$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfApiResponseOfReservationDto>) => r.body as AjaxResponseOfApiResponseOfReservationDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignSeats$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  assignSeats$Xml$Response(params?: {
    orderId?: string;
    availabilityId?: string;
    isTrip?: boolean;
    body?: Array<SeatsDto>
  }): Observable<StrictHttpResponse<AjaxResponseOfApiResponseOfReservationDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.AssignSeatsPath, 'post');
    if (params) {
      rb.query('orderId', params.orderId, {});
      rb.query('availabilityId', params.availabilityId, {});
      rb.query('isTrip', params.isTrip, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfApiResponseOfReservationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `assignSeats$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  assignSeats$Xml(params?: {
    orderId?: string;
    availabilityId?: string;
    isTrip?: boolean;
    body?: Array<SeatsDto>
  }): Observable<AjaxResponseOfApiResponseOfReservationDto> {

    return this.assignSeats$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfApiResponseOfReservationDto>) => r.body as AjaxResponseOfApiResponseOfReservationDto)
    );
  }

  /**
   * Path part for operation assignExtraOffers
   */
  static readonly AssignExtraOffersPath = '/api/services/app/Train/AssignExtraOffers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignExtraOffers()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assignExtraOffers$Response(params?: {
    tripId?: string;
    body?: Array<ExtraOfferDto>
  }): Observable<StrictHttpResponse<AjaxResponseOfApiResponseOfReservationDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.AssignExtraOffersPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfApiResponseOfReservationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `assignExtraOffers$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assignExtraOffers(params?: {
    tripId?: string;
    body?: Array<ExtraOfferDto>
  }): Observable<AjaxResponseOfApiResponseOfReservationDto> {

    return this.assignExtraOffers$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfApiResponseOfReservationDto>) => r.body as AjaxResponseOfApiResponseOfReservationDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignExtraOffers$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  assignExtraOffers$Xml$Response(params?: {
    tripId?: string;
    body?: Array<ExtraOfferDto>
  }): Observable<StrictHttpResponse<AjaxResponseOfApiResponseOfReservationDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.AssignExtraOffersPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfApiResponseOfReservationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `assignExtraOffers$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  assignExtraOffers$Xml(params?: {
    tripId?: string;
    body?: Array<ExtraOfferDto>
  }): Observable<AjaxResponseOfApiResponseOfReservationDto> {

    return this.assignExtraOffers$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfApiResponseOfReservationDto>) => r.body as AjaxResponseOfApiResponseOfReservationDto)
    );
  }

  /**
   * Path part for operation assignSeatsAndIssue
   */
  static readonly AssignSeatsAndIssuePath = '/api/services/app/Train/AssignSeatsAndIssue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignSeatsAndIssue()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assignSeatsAndIssue$Response(params?: {
    orderId?: string;
    availabilityId?: string;
    body?: Array<SeatsDto>
  }): Observable<StrictHttpResponse<AjaxResponseOfApiResponseOfReservationDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.AssignSeatsAndIssuePath, 'post');
    if (params) {
      rb.query('orderId', params.orderId, {});
      rb.query('availabilityId', params.availabilityId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfApiResponseOfReservationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `assignSeatsAndIssue$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assignSeatsAndIssue(params?: {
    orderId?: string;
    availabilityId?: string;
    body?: Array<SeatsDto>
  }): Observable<AjaxResponseOfApiResponseOfReservationDto> {

    return this.assignSeatsAndIssue$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfApiResponseOfReservationDto>) => r.body as AjaxResponseOfApiResponseOfReservationDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignSeatsAndIssue$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  assignSeatsAndIssue$Xml$Response(params?: {
    orderId?: string;
    availabilityId?: string;
    body?: Array<SeatsDto>
  }): Observable<StrictHttpResponse<AjaxResponseOfApiResponseOfReservationDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.AssignSeatsAndIssuePath, 'post');
    if (params) {
      rb.query('orderId', params.orderId, {});
      rb.query('availabilityId', params.availabilityId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfApiResponseOfReservationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `assignSeatsAndIssue$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  assignSeatsAndIssue$Xml(params?: {
    orderId?: string;
    availabilityId?: string;
    body?: Array<SeatsDto>
  }): Observable<AjaxResponseOfApiResponseOfReservationDto> {

    return this.assignSeatsAndIssue$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfApiResponseOfReservationDto>) => r.body as AjaxResponseOfApiResponseOfReservationDto)
    );
  }

  /**
   * Path part for operation assignSeatsAndMove
   */
  static readonly AssignSeatsAndMovePath = '/api/services/app/Train/AssignSeatsAndMove';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignSeatsAndMove()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assignSeatsAndMove$Response(params?: {
    orderId?: string;
    availabilityId?: string;
    travelSolutionId?: string;
    ticketIds?: string;
    confirmPayment?: boolean;
    body?: Array<SeatsDto>
  }): Observable<StrictHttpResponse<ModifyResult>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.AssignSeatsAndMovePath, 'post');
    if (params) {
      rb.query('orderId', params.orderId, {});
      rb.query('availabilityId', params.availabilityId, {});
      rb.query('travelSolutionId', params.travelSolutionId, {});
      rb.query('ticketIds', params.ticketIds, {});
      rb.query('confirmPayment', params.confirmPayment, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ModifyResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `assignSeatsAndMove$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assignSeatsAndMove(params?: {
    orderId?: string;
    availabilityId?: string;
    travelSolutionId?: string;
    ticketIds?: string;
    confirmPayment?: boolean;
    body?: Array<SeatsDto>
  }): Observable<ModifyResult> {

    return this.assignSeatsAndMove$Response(params).pipe(
      map((r: StrictHttpResponse<ModifyResult>) => r.body as ModifyResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignSeatsAndMove$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  assignSeatsAndMove$Xml$Response(params?: {
    orderId?: string;
    availabilityId?: string;
    travelSolutionId?: string;
    ticketIds?: string;
    confirmPayment?: boolean;
    body?: Array<SeatsDto>
  }): Observable<StrictHttpResponse<ModifyResult>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.AssignSeatsAndMovePath, 'post');
    if (params) {
      rb.query('orderId', params.orderId, {});
      rb.query('availabilityId', params.availabilityId, {});
      rb.query('travelSolutionId', params.travelSolutionId, {});
      rb.query('ticketIds', params.ticketIds, {});
      rb.query('confirmPayment', params.confirmPayment, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ModifyResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `assignSeatsAndMove$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  assignSeatsAndMove$Xml(params?: {
    orderId?: string;
    availabilityId?: string;
    travelSolutionId?: string;
    ticketIds?: string;
    confirmPayment?: boolean;
    body?: Array<SeatsDto>
  }): Observable<ModifyResult> {

    return this.assignSeatsAndMove$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ModifyResult>) => r.body as ModifyResult)
    );
  }

  /**
   * Path part for operation confirm_1
   */
  static readonly Confirm_1Path = '/api/services/app/Train/Confirm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirm_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  confirm_1$Response(params?: {
    orderId?: string;
  }): Observable<StrictHttpResponse<ReservationDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.Confirm_1Path, 'post');
    if (params) {
      rb.query('orderId', params.orderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReservationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `confirm_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  confirm_1(params?: {
    orderId?: string;
  }): Observable<ReservationDto> {

    return this.confirm_1$Response(params).pipe(
      map((r: StrictHttpResponse<ReservationDto>) => r.body as ReservationDto)
    );
  }

  /**
   * Path part for operation isCompanyCarnet
   */
  static readonly IsCompanyCarnetPath = '/api/services/app/Train/IsCompanyCarnet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isCompanyCarnet()` instead.
   *
   * This method doesn't expect any request body.
   */
  isCompanyCarnet$Response(params?: {
    provider?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfBoolean>> {

    const rb = new RequestBuilder(this.rootUrl, TrainService.IsCompanyCarnetPath, 'post');
    if (params) {
      rb.query('provider', params.provider, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfBoolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `isCompanyCarnet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isCompanyCarnet(params?: {
    provider?: string;
  }): Observable<AjaxResponseOfBoolean> {

    return this.isCompanyCarnet$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfBoolean>) => r.body as AjaxResponseOfBoolean)
    );
  }

}
