import { NgModule } from '@angular/core';
import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRouteGuard } from './shared/common/auth/auth-route-guard';
import { NotificationsComponent } from './shared/layout/notifications/notifications.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CanDeactivateGuard } from '@app/shared/can-deactivate-guard.service';
import { AppFeatureGuard } from './shared/guards/app-feature-guard/app-feature.guard';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserProfileCustomFieldsComponent } from './user-profile/user-profile-custom-fields/user-profile-custom-fields.component';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'app',
                component: AppComponent,
                canActivate: [AppRouteGuard],
                canActivateChild: [AppRouteGuard],
                children: [
                    {
                        path: '',
                        redirectTo: '/app/main/dashboardMaster',
                        pathMatch: 'full'
                    },
                    {
                        path: 'notifications',
                        component: NotificationsComponent
                    },
                    {
                        path: 'main',
                        loadChildren: () => import('app/main/main.module').then(m => m.MainModule), //Lazy load main module
                        data: { preload: true }
                    },
                    {
                        path: 'admin',
                        loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule), //Lazy load admin module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    //START corporate routes
                    {
                        path: 'user-profile',
                        children: [
                            {
                                path: '',
                                component: UserProfileComponent,
                                data: { preload: true, feature: 'App.Corporate', enabled: true },
                                canActivate: [AppFeatureGuard, AppRouteGuard],
                            },
                            {
                                path: 'custom-fields',
                                component: UserProfileCustomFieldsComponent,
                                data: {permission: 'Pages.UserProfiles.CustomFields'}
                            }
                        ]
                    },
                    {
                        path: 'trip',
                        loadChildren: () => import('app/corporate/trip/trip.module').then(m => m.TripModule),
                        data: { preload: true, feature: 'App.Corporate', enabled: true },
                        canLoad: [AppFeatureGuard, AppRouteGuard]
                    },
                    //END corporate routes
                    //START standard routes
                    {
                        path: 'flight',
                        loadChildren: () => import('app/flight/flight.module').then(m => m.FlightModule),
                        data: { preload: true, feature: 'App.Corporate', enabled: false },
                        canLoad: [AppFeatureGuard, AppRouteGuard]
                    },
                    {
                        path: 'hotel',
                        loadChildren: () => import('app/hotel/hotel.module').then(m => m.HotelModule),
                        data: { preload: true, feature: 'App.Corporate', enabled: false },
                        canLoad: [AppFeatureGuard, AppRouteGuard]
                    },
                    {
                        path: 'train',
                        loadChildren: () => import('app/train/train.module').then(m => m.TrainModule),
                        data: { preload: true, feature: 'App.Corporate', enabled: false },
                        canLoad: [AppFeatureGuard, AppRouteGuard]
                    },
                    {
                        path: 'car',
                        loadChildren: () => import('app/car/car.module').then(m => m.CarModule),
                        data: { preload: true, feature: 'App.Corporate', enabled: false },
                        canLoad: [AppFeatureGuard, AppRouteGuard]
                    },
                    {
                        path: 'carnet',
                        loadChildren: () => import('app/carnet/carnet.module').then(m => m.CarnetModule),
                        canLoad: [AppFeatureGuard, AppRouteGuard]
                    },
                    //END standard routes
                    {
                        path: '**', redirectTo: 'notifications'
                    }
                ]
            },
            {
                path: 'flight/voucher/:orderId',
                loadChildren: () => import('public/flight-voucher/flight-voucher.module').then(m => m.FlightVoucherModule),
                canActivate: [AppRouteGuard],
                data: { permission: 'Pages.Reservation.Detail' }
            },
            {
                path: 'hotel/voucher/:orderId',
                loadChildren: () => import('public/hotel-voucher/hotel-voucher.module').then(m => m.HotelVoucherModule),
                canActivate: [AppRouteGuard],
                data: { permission: 'Pages.Reservation.Detail' }
            },
            {
                path: 'train/voucher/:orderId',
                loadChildren: () => import('public/train-voucher/train-voucher.module').then(m => m.TrainVoucherModule),
                canActivate: [AppRouteGuard],
                data: { permission: 'Pages.Reservation.Detail' }
            },
            {
                path: 'car/voucher/:orderId',
                loadChildren: () => import('public/car-voucher/car-voucher.module').then(m => m.CarVoucherModule),
                canActivate: [AppRouteGuard],
                data: { permission: 'Pages.Reservation.Detail' }
            }
        ])
    ],
    exports: [RouterModule],
    providers: [CanDeactivateGuard]
})

export class AppRoutingModule {
    constructor(
        private router: Router,
        private spinnerService: NgxSpinnerService
    ) {
        router.events.subscribe((event) => {

            if (event instanceof RouteConfigLoadStart) {
                spinnerService.show();
            }

            if (event instanceof RouteConfigLoadEnd) {
                spinnerService.hide();
            }

            if (event instanceof NavigationEnd) {
                document.querySelector('meta[property=og\\:url').setAttribute('content', window.location.href);
            }
        });
    }
}
