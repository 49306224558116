import { EmailFormTemplateModule } from '@app/shared/components/email-form-template/email-form-template.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialShareComponent } from './social-share.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ShareResultsComponent } from './share-results/share-results.component';
import { UtilsModule } from '@shared/utils/utils.module';
import { TrainShareSerpService } from '@app/shared/services/train/train-share-serp.service';
import { SocialShareService } from '@app/shared/services/social-share/social-share.service';

@NgModule({
    imports: [CommonModule, TooltipModule, ModalModule.forRoot(), EmailFormTemplateModule, UtilsModule],
    declarations: [SocialShareComponent, ShareResultsComponent],
    exports: [SocialShareComponent],
    providers: [TrainShareSerpService, SocialShareService]
})
export class SocialShareModule { }
