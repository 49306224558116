<div #widgetParent [busyIf]="isLoading" class="card card-custom h-100">
	<h3 class="card-title align-items-start flex-column m-2">
		<span class="font-weight-bolder text-dark">
			<i class="flaticon2-open-text-book"></i>
			{{ 'News_Widget_Title' | localize }}
		</span>
	</h3>
	<!-- Blocco inserimento post -->
	<div class="row justify-content-center p-4">
		<div class="col-sm-12 mb-2 text-center">
			<img class="profileImage" [src]="profilePicture" />
			<span class="d-block">{{ userName }}</span>
			<button
				(click)="triggerPostAddMode()"
				*ngIf="isGranted('Pages.Tenant.Dashboard.Update.Widgets.Data') || isGranted('Pages.Host.Dashboard.Update.Widgets.Data')"
				class="btn btn-primary mb-4"
			>
				{{ 'News_Widget_Add_Post' | localize }}
				<i *ngIf="!showNewsAddForm" inline class="material-icons">add</i>
				<i *ngIf="showNewsAddForm" inline class="material-icons">close</i>
			</button>
		</div>
		<div class="col-sm-12 text-center">
			<div [@addPost] *ngIf="showNewsAddForm">
				<div class="input-group mb-2">
					<input
						[(ngModel)]="title"
						maxlength="20"
						type="text"
						class="form-control"
						placeholder="{{ 'News_Widget_Add_Title' | localize }}"
						aria-describedby="basic-addon2"
					/>
				</div>
				<div>
					<img @fadeTransition *ngIf="this.uploadedImage" [src]="this.uploadedImage" class="uploaded-image" />
					<i matSuffix *ngIf="this.uploadedImage" inline class="material-icons clickableIcon" (click)="this.uploadedImage = ''">close</i>
					<span @fadeTransition *ngIf="this.uploadedLink"
						>{{ getUploadedLink() }}
						<i class="material-icons clickableIcon" style="vertical-align: middle" (click)="this.uploadedLink = null">close</i>
					</span>
				</div>
				<form [formGroup]="form">
					<mat-form-field class="insert-post-box mt-2">
						<mat-label>{{ 'News_Widget_Write_Something' | localize }}</mat-label>
						<textarea rows="2" cols="8" style="resize: none" name="message" formControlName="message" matInput #message maxlength="280"></textarea>
						<i matSuffix *ngIf="message.value" inline class="material-icons clickableIcon" (click)="this.form.reset()">close</i>
						<mat-hint align="end">{{ message.value.length }} / 280</mat-hint>
					</mat-form-field>
				</form>
				<div class="row" #toolbar>
					<div class="flex-container toolbar-side">
						<div class="flex-child mx-2">
							<i class="material-icons clickableIcon" (click)="triggerAddMode()">add_link</i>
						</div>
						<div class="flex-child mx-2">
							<label for="loadImg">
								<i class="material-icons clickableIcon">image</i>
							</label>
							<input type="file" id="loadImg" accept="image/*" class="loadImgInput" (change)="getImageAsDataUrl($event)" (click)="$event.target.value = null" />
						</div>
					</div>
					<div class="toolbar-side text-right">
						<button (click)="this.addNews()" [disabled]="!form.valid" class="btn btn-success mx-2">
							{{ 'Widget_Add' | localize }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Blocco per aggiunta link -->
	<div [@addLinkInput] *ngIf="addLinkEnabled" class="mx-2 my-2">
		<form [formGroup]="addLinkFormGroup" (ngSubmit)="addLink(addLinkForm.url.value, addLinkForm.alias.value)">
			<div class="input-group mb-2">
				<input type="url" formControlName="url" class="form-control" placeholder="{{ 'Link_Widget_AddLinkUrl' | localize }}" aria-describedby="basic-addon2" />
				<div class="input-group-append">
					<button class="btn btn-outline-secondary" [disabled]="!addLinkFormGroup.valid" type="submit">
						{{ 'Widget_Add' | localize }}
					</button>
				</div>
			</div>
			<div [@addLinkInput]>
				<input type="text" formControlName="alias" class="form-control" placeholder="{{ 'Link_Widget_LinkAlias' | localize }}" *ngIf="addLinkFormGroup.valid" />
			</div>
			<div [@addLinkInput] *ngIf="addLinkForm.url.invalid && addLinkForm.url.errors.pattern" class="alert alert-danger">
				<div>{{ 'Link_Widget_Url_Error' | localize }}</div>
			</div>
		</form>
	</div>
	<!-- Blocco carousel notizie -->
	<div class="news-carousel" *ngIf="this.widgetNews && this.widgetNews.elements && this.widgetNews.elements.length > 0; else noNewsToShowTemplate">
		<p-carousel
			[value]="this.widgetNews.elements"
			[numVisible]="1"
			[numScroll]="1"
			[circular]="true"
			[autoplayInterval]="5000"
			[responsiveOptions]="carouselResponsiveOptions"
		>
			<ng-template let-news pTemplate="item">
				<div class="news-item">
					<i *ngIf="news.userId == getUserId()" matSuffix inline class="material-icons clickableIcon float-right" (click)="openNewsDeleteDialog(news)">close</i>
					<div @fadeTransition (click)="openNewsDialog(news)" class="news-item-content">
						<h6 class="author-span">{{ news.title }}</h6>
						<img *ngIf="news.frontImageSource" [src]="news.frontImageSource" class="news-image" />
						<p>
							{{ formatText(news.text) }}
						</p>
						<!--Link card-->
						<a (click)="$event.stopPropagation()" *ngIf="news.link" [href]="news.link.link" target="_blank">
							<button class="btn btn-light rounded-pill col-10">
								<span class="font-weight-bolder text-dark">
									{{ 'News_Widget_Click_Here' | localize }}
								</span>
							</button>
						</a>
					</div>
				</div>
			</ng-template>
		</p-carousel>
	</div>
	<ng-template #noNewsToShowTemplate>
		<div class="flex-grow-1 row justify-content-center align-items-center">
			<h6 class="card-title">
				{{ 'News_Widget_No_News_Uploaded_Yet' | localize }}
			</h6>
		</div>
	</ng-template>
</div>
<!-- Modale notizia -->
<ng-template #templateModal class="modal-dialog modal-lg">
	<div class="modal-content">
		<div *ngIf="selectedNews.title" class="modal-header">
			<h3 class="modal-title pull-left">{{ selectedNews.title }}</h3>
		</div>
		<div class="modal-body modal-container text-center">
			<div class="img-container">
				<img *ngIf="selectedNews.frontImageSource" [src]="selectedNews.frontImageSource" class="modal-img" />
			</div>
			<div class="modal-text">
				{{ formatText(selectedNews.text, false) }}
			</div>
			<a (click)="$event.stopPropagation()" *ngIf="selectedNews.link" [href]="selectedNews.link.link" target="_blank">
				<button class="btn btn-light rounded-pill col-8">
					<span class="font-weight-bolder text-dark">
						{{ 'News_Widget_Click_Here' | localize }}
					</span>
				</button>
			</a>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-light" (click)="closeNewsDialog()">
				{{ 'Common_Close' | localize }}
			</button>
		</div>
	</div>
</ng-template>
<!-- Modale cancellazione notizia -->
<ng-template #deleteTemplateModal class="modal-dialog modal-lg">
	<div class="modal-content modal-container">
		<div class="modal-header">
			<h3 class="modal-title pull-left">{{ 'News_Widget_Delete_News' | localize }}</h3>
			<i class="warningIcon material-icons">warning</i>
		</div>
		<div class="modal-body modal-text text-center">
			<div class="modal-text">
				{{ 'News_Widget_Delete_News_Confirmation' | localize }}<br />
				<span class="font-weight-bolder text-dark"> {{ newsToDelete.title ? newsToDelete.title : '' }}<br /> </span>
				{{ formatText(newsToDelete.text, true) }}
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-danger" data-dismiss="modal" (click)="removeNews(); closeNewsDeleteDialog()">
				{{ 'Common_Delete' | localize }}
			</button>
			<button type="button" class="btn btn-light" (click)="closeNewsDeleteDialog()">
				{{ 'Common_Close' | localize }}
			</button>
		</div>
	</div>
</ng-template>
