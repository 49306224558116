export class DashboardCustomizationConst {
    static widgets = {
        tenant: {
            profitShare: 'Widgets_Tenant_ProfitShare',
            memberActivity: 'Widgets_Tenant_MemberActivity',
            regionalStats: 'Widgets_Tenant_RegionalStats',
            salesSummary: 'Widgets_Tenant_SalesSummary',
            topStats: 'Widgets_Tenant_TopStats',
            generalStats: 'Widgets_Tenant_GeneralStats',
            dailySales: 'Widgets_Tenant_DailySales',
            fileShowDownload: 'Widgets_Tenant_FileShowDownload',
            news: 'Widgets_Tenant_News',
        },
        host: {
            topStats: 'Widgets_Host_TopStats',
            incomeStatistics: 'Widgets_Host_IncomeStatistics',
            editionStatistics: 'Widgets_Host_EditionStatistics',
            subscriptionExpiringTenants:
                'Widgets_Host_SubscriptionExpiringTenants',
            recentTenants: 'Widgets_Host_RecentTenants',
            fileShowDownload: 'Widgets_Host_FileShowDownload',
            news: 'Widgets_Host_News',
        },
        analytics: {
            orderTypeStats: 'Widgets_Analytics_OrderType',
            salesByAgency: 'Widgets_Analytics_SalesByAgency',
            salesByAirlineCompanies:
                'Widgets_Analytics_SalesByAirlineCompanies',
            salesBySchedulesAndCompanies:
                'Widgets_Analytics_SalesBySchedulesAndCompanies',
            salesFlightType: 'Widgets_Analytics_SalesFlightType',
            topStats: 'Widgets_Analytics_TopStats',
        },
    };
    static filters = {
        filterDateRangePicker: 'Filters_DateRangePicker',
        agencyFilter: 'Filters_Agency',
    };
    static dashboardNames = {
        defaultTenantDashboard: 'TenantDashboard',
        defaultHostDashboard: 'HostDashboard',

        //Reports
        defaultAnalyticsDashboard: 'AnalyticsDashboard',
    };
    static Applications = {
        Angular: 'Angular',
    };
}
