<div id="kt_quick_sidebar" class="offcanvas offcanvas-right pt-5 pb-10">
    <div class="quick-panel-actions pr-5">
        <a
            href="javascript:;"
            class="btn btn-xs btn-icon btn-light btn-hover-primary page-quick-sidebar-pinner"
            (click)="reversePinned()"
        >
            <i
                class="fa fa-map-pin"
                [ngClass]="{ 'fa-rotate-90': !pinned }"
                aria-label="l('Pin')"
            ></i>
        </a>
        <a
            href="javascript:;"
            class="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_sidebar_close"
        >
            <i class="flaticon2-delete"></i>
        </a>
    </div>
    <div class="flex-row-auto" style="height: 100%">
        <div class="card card-custom custom-widget-position" *ngIf="!isImpersonatedLogin; else isImpersonated"></div>
        <ng-template #isImpersonated>
            non è possibile utilizzare la chat in modalità impersonificata
        </ng-template>
    </div>
</div>
