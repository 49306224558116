import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AvailabilityRequestMulticityDTO, AvailabilityStructuresRequestDto } from '@shared/service-proxies/service-proxies';
import { MyTrainAvailabilityRequestDTO } from '@app/shared/models/client-train-models';
import { MyCarAvailabilityPagedRequestDto } from '@app/shared/models/car.models';
import { IProductsLoaderType, IFlightLoaderStatus } from './products-loader.models';
import { flightDescriptionFromRequest, hotelDescriptionFromRequest, trainDescriptionFromRequest, carDescriptionFromRequest } from '@shared/helpers/description-from-request.helper';
import { DatePipe } from '@angular/common';
import { AvailabilityPackageRateRequestDto } from '@app/api/models';

@Injectable({
    providedIn: 'root'
})
export class ProductsLoaderService {
    private loaderStatus: BehaviorSubject<IFlightLoaderStatus>;

    constructor(private datePipe: DatePipe) {
        this.loaderStatus = new BehaviorSubject({
            status: false
        });
    }

    get LoaderStatus(): BehaviorSubject<IFlightLoaderStatus> {
        return this.loaderStatus;
    }

    show(
        type: IProductsLoaderType,
        request?: AvailabilityRequestMulticityDTO
            | AvailabilityStructuresRequestDto
            | MyTrainAvailabilityRequestDTO
            | MyCarAvailabilityPagedRequestDto
            | Object
    ) {
        const loaderText = this.requestToLoaderText(type , request);
        this.showWithText(type, loaderText);
    }

    showWithText(type: IProductsLoaderType, loaderText: string) {
        this.loaderStatus.next({
            status: true,
            type,
            loaderText
        });
    }

    hide() {
        this.loaderStatus.next({
            status: false
        });
    }

    private requestToLoaderText(type: IProductsLoaderType,
        request?: AvailabilityRequestMulticityDTO
        | AvailabilityStructuresRequestDto
        | MyTrainAvailabilityRequestDTO
        | MyCarAvailabilityPagedRequestDto
        | Object): string {

            if (type === 'flight' && request instanceof AvailabilityRequestMulticityDTO) {
                return flightDescriptionFromRequest(request, this.datePipe);
            } else if (type === 'flight' && request instanceof Object) {
                return flightDescriptionFromRequest(request as AvailabilityRequestMulticityDTO, this.datePipe);
            } else if (type === 'hotel' && request instanceof AvailabilityStructuresRequestDto) {
                return hotelDescriptionFromRequest(request, this.datePipe);
            } else if (type === 'hotel' && request instanceof Object){
                return hotelDescriptionFromRequest(request  as AvailabilityStructuresRequestDto, this.datePipe, true) //logica da cambiare
            } else if (type === 'train' && request instanceof MyTrainAvailabilityRequestDTO) {
                return trainDescriptionFromRequest(request, this.datePipe);
            } else if (type === 'train' && request instanceof Object) {
                return trainDescriptionFromRequest(request as MyTrainAvailabilityRequestDTO, this.datePipe);
            } else if (type === 'car' && request instanceof MyCarAvailabilityPagedRequestDto) {
                return carDescriptionFromRequest(request, this.datePipe);
            }
    }

    isVisible() {
        return this.loaderStatus.getValue().status;
    }
}
