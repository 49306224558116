import { AbstractControl, ValidationErrors, UntypedFormControl, ValidatorFn, UntypedFormGroup } from '@angular/forms';
import { ProductType } from '@shared/AppBusiness';

export function NotEmptyFFFieldsValidator(otherFFFieldName: string, productType: ProductType = ProductType.flight): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
        if (form.parent && ((form.parent as UntypedFormGroup).controls[otherFFFieldName] as UntypedFormControl)) {
            let valueOther = ((form.parent as UntypedFormGroup).controls[otherFFFieldName] as UntypedFormControl).value as string;
            let value_ = form.value as string;
            if (productType === ProductType.train) {
                value_ = value_;
                valueOther = valueOther;
            }
            if ((valueOther && !value_) || (value_ && !valueOther)) {
                if ((valueOther === null || valueOther === '') && value_) {
                    ((form.parent as UntypedFormGroup).controls[otherFFFieldName] as UntypedFormControl).setErrors({ required: {} });
                    return null;
                } else if ((form.value === null || form.value === '') && valueOther) {
                    form.setErrors({ required: {} });
                    return { required: {} };
                } else {
                    ((form.parent as UntypedFormGroup).controls[otherFFFieldName] as UntypedFormControl).setErrors(null);
                    form.setErrors(null);
                    return null;
                }
            } else {
                ((form.parent as UntypedFormGroup).controls[otherFFFieldName] as UntypedFormControl).setErrors(null);
                form.setErrors(null);
                return null;
            }
        }
    };
}

