import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { LinearChartDto, AnalyticsDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { WidgetComponentBaseComponent } from '../widget-component-base';

@Component({
  selector: 'app-widget-sales-airline-companies',
  templateUrl: './widget-sales-airline-companies.component.html',
})
export class WidgetSalesAirlineCompaniesComponent extends WidgetComponentBaseComponent implements OnInit, OnDestroy {

    data: Array<LinearChartDto>;
    isLoading: boolean;
    selectedDateRange: moment.Moment[] = [
        moment().add(-1, 'month').startOf('month'),
        moment().endOf('day'),
    ];
    selectedAgency: string;

    constructor(injector: Injector, private analyticsService: AnalyticsDashboardServiceProxy) {
        super(injector);
    }

    ngOnInit() {
        this.subDateRangeFilter();
        this.subAgencyFilter();
        this.getData(this.selectedDateRange);
    }

    ngOnDestroy() {
        this.unSubDateRangeFilter();
        this.unSubAgencyFilter();
    }

    onDateRangeFilterChange = (dateRange) => {
        this.runDelayed(() => {
            if (
                !dateRange ||
                dateRange.length !== 2 ||
                (this.selectedDateRange[0] === dateRange[0] &&
                    this.selectedDateRange[1] === dateRange[1])
            ) {
                return;
            }

            this.selectedDateRange = dateRange;
            this.getData(this.selectedDateRange);
        });
    }

    onAgencyChange = (agency) => {
        this.runDelayed(() => {
            this.selectedAgency = agency;
            this.getData(this.selectedDateRange);
        });
    }

    getData(dateRange: Array<moment.Moment>) {
        this.isLoading = true;
        this.analyticsService
            .getSalesByAirlineCompanies(dateRange[0], dateRange[1], this.selectedAgency)
            .pipe(finalize(() => {
                this.isLoading = false;
            }))
            .subscribe((data) => {
                this.data = data;
                this.isLoading = false;
            });
    }

    subDateRangeFilter() {
        abp.event.on(
            'app.dashboardFilters.dateRangePicker.onDateChange',
            this.onDateRangeFilterChange
        );
    }

    unSubDateRangeFilter() {
        abp.event.off(
            'app.dashboardFilters.dateRangePicker.onDateChange',
            this.onDateRangeFilterChange
        );
    }

    subAgencyFilter() {
        abp.event.on(
            'app.dashboardFilters.dateRangePicker.onAgencyChange',
            this.onAgencyChange
        );
    }

    unSubAgencyFilter() {
        abp.event.off(
            'app.dashboardFilters.dateRangePicker.onAgencyChange',
            this.onAgencyChange
        );
    }

    formatXAxis(val) {
        return '€ ' + val;
    }

}
