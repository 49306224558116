import { Directive, ElementRef, Renderer2, DoCheck, RendererStyleFlags2 } from '@angular/core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

@Directive({ selector: '[defaultDropdownSize]' })
export class DefaultDropdownSizeDirective implements DoCheck {
    found = false;
    currentWidth: number;
    currentHeight: number;
    constructor(
        private element: BsDropdownDirective,
        private template: ElementRef<HTMLElement>,
        private renderer: Renderer2
    ) {
        this.currentHeight = 0;
        this.currentWidth = 0;
    }


    ngDoCheck() {
        if (this.element.isOpen) {
            const element = this.template.nativeElement.querySelector('.dropdown-menu');
            const container = element.querySelector('.date-time-picker-container');
            if (element && container) {
                const width = container.clientWidth;
                const height = container.clientHeight;

                this.renderer.setStyle(element, 'min-width', 'auto', RendererStyleFlags2.Important);
                this.renderer.setStyle(element, 'min-height', 'auto', RendererStyleFlags2.Important);

                if (!this.found) {
                    if (width) {
                        this.renderer.setStyle(container, 'min-width', `${width}px`);
                        this.currentWidth = width;
                        this.found = true;
                    }
                    if (height) {
                        this.renderer.setStyle(container, 'min-height', `${height}px`);
                        this.currentHeight = height;
                        this.found = true;
                    }
                }

                if ((this.element.isOpen && this.found) && (this.currentHeight && height > this.currentHeight ) || (this.currentWidth && width > this.currentWidth)) {
                    this.found = false;
                    this.currentHeight = height;
                    this.currentWidth = width;
                }
        }
    }
}
}
