import { Pipe, PipeTransform } from '@angular/core';
import { ValidatorTypeDto } from '@app/api/models';
import { validatorsWithValue, IValidatorInformation } from '../edit-custom-field/edit-custom-field.models';

@Pipe({
  name: 'validatorHasValue'
})
export class ValidatorHasValuePipe implements PipeTransform {

  transform(value: ValidatorTypeDto, args?: any): IValidatorInformation {
    const element = validatorsWithValue.find((validatorInfo) => validatorInfo.value === value);
    return element;
  }

}
