import { IAvailabilityItineraryMulticityDTOExtended } from '@shared/interfaces/availability-itinerary-muticity-extended.interface';
import * as moment from 'moment';
import { LocationDTO, PricingInformationDTO, TrainAvailabilityRequestDTO } from '@shared/service-proxies/service-proxies';
import { ITimeFilter } from '@app/shared/services/flight/models/filter.models';
import { LocationDto } from '@app/api/models';

export class MyTrainAvailabilityRequestDTO extends TrainAvailabilityRequestDTO {
    originDisplayName?: string;
    destinationDisplayName?: string;
    originCityName?: string;
    destinationCityName?: string;

    toJSON(data?: any) {
        const mydata = super.toJSON(data);
        mydata['departureDate'] = this.departureDate ? this.departureDate.format('YYYY-MM-DD HH:mm') : <any>undefined;
        mydata['returnDate'] = this.returnDate ? this.returnDate.format('YYYY-MM-DD HH:mm') : <any>undefined;
        mydata['originDisplayName'] = this.originDisplayName ? this.originDisplayName : undefined;
        mydata['destinationDisplayName'] = this.destinationDisplayName ? this.destinationDisplayName : undefined;
        mydata['originCityName'] = this.originCityName ? this.originCityName : undefined;
        mydata['destinationCityName'] = this.destinationCityName ? this.destinationCityName : undefined;
        return mydata;
    }
}

export class MyTrainAvailabilityRequestDTOExtended extends MyTrainAvailabilityRequestDTO {
    supplierName: string | undefined;
    addTraveller: boolean = false;
    isEconomyChangeDate: boolean = false;
}

export enum PersonNameTypeStrings {
    ADT = "ADT",
    YNG = "YNG",
    CHD = "CHD",
    INF = "INF",
    SEN = "SEN"
}

export class MyTrainAvailabilityRequestDTOEmitter {
    trainAvailability: MyTrainAvailabilityRequestDTO;
    fieldModified: string;
    indexFieldModified = -1;
    initEvent: boolean;

    constructor(trainAvailability: MyTrainAvailabilityRequestDTO, field: string, initEvent = false, indexFieldModified?: number) {
        this.trainAvailability = trainAvailability;
        this.fieldModified = field;
        this.initEvent = initEvent;
        if (indexFieldModified) {
            this.indexFieldModified = indexFieldModified;
        }
    }
}

export class LocationDTOEmitter {
    station: LocationDTO;
    type: string;
    constructor(type: string) {
        this.type = type;
    }
}

export class LocationDtoEmitter {
    station: LocationDto;
    type: string;
    constructor(type: string) {
        this.type = type;
    }
}

export class TrainDateEmitter {
    date: moment.Moment;
    type: string;
    constructor(type: string) {
        this.type = type;
    }
}

export class PricingInformationEmitter {
    pricingInfo: PricingInfoExtended;
    type: TrainAvailabilityType;
    outwardId: string;
    constructor(type: TrainAvailabilityType) {
        this.type = type;
    }
}

export class PricingInfoExtended extends PricingInformationDTO {
    totalPrice: number;
    // Contiene i valori delle fee per ogni ticket selezionato
    feeValuesArray: number[];
    // Valore totale delle fee
    feeTotalValue: number;
    // Contiene i valori delle agency fee per ogni ticket selezionato
    agencyFeeValuesArray: number[];
    // Valore totale delle agency fee
    agencyFeeTotalValue: number;
    constructor(totalPrice: number) {
        super();
        this.totalPrice = totalPrice;
    };
}

export class TrainAvailabilityType {
    static data = 'data';
    static outward = 'outward';
    static return = 'return';
}

export class PaginatedDataEmitter {
    type: TrainAvailabilityType;
    paginatedData: Array<IAvailabilityItineraryMulticityDTOExtended> = [];

    constructor(type: TrainAvailabilityType = TrainAvailabilityType.data) {
        this.type = type;
    }
}

export class PaginationBtnEmitter {
    arrayDataType: TrainAvailabilityType;
    value: boolean;
    type: 'prev' | 'next'
    constructor(type: TrainAvailabilityType = TrainAvailabilityType.data) {
        this.arrayDataType = type;
    }
}

export class TrainClientModel {
    providerId = '';
    originDisplayName = '';
    destinationDisplayName = '';
    departureDate = '';
    time = '';
    trainIdentifier = '';
    constructor(providerId: string, originDisplayName: string, destinationDisplayName: string, departureDate: string, time: string, trainIdentifier: string) {
        this.providerId = providerId;
        this.originDisplayName = originDisplayName;
        this.destinationDisplayName = destinationDisplayName;
        this.departureDate = departureDate;
        this.time = time;
        this.trainIdentifier = trainIdentifier;
    }
}

export class PaxRef {
    nameNumber = '';
    personName = '';
    constructor(nameNumber: string, personName: string) {
        this.nameNumber = nameNumber;
        this.personName = personName;
    }
}

// Filter
export class TrainFilter {
    id: number;
    name: string;
    active: boolean;
    companies: CompanyFilter[];
    originStations: StationFilter[];
    destinationStations: StationFilter[];
    departureTime: ITimeFilter;
    arrivalTime: ITimeFilter;
}

export class CompanyFilter {
    active: boolean;
    carrier: string;
    code: string;
    bestPrice: number;
}

export class StationFilter {
    active: boolean;
    airportCode: string;
    poiName: string;
    bestPrice: number;
}
