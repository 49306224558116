import { Component, OnInit, Input, Injector } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfigDto } from '@shared/service-proxies/service-proxies';
import { GetValidationNamePipe } from '../../manage-custom-fields/pipes/get-validation-name.pipe';

@Component({
    selector: 'app-error-validation-messages',
    templateUrl: './error-validation-messages.component.html',
})
export class ErrorValidationMessagesComponent implements OnInit {
    @Input() group: UntypedFormGroup;
    @Input() field: FieldConfigDto;
    validationNamePipe: GetValidationNamePipe;

    errorMessages: Map<string, string>;

    constructor(injector: Injector) {
        this.errorMessages = new Map<string, string>();
        this.validationNamePipe = new GetValidationNamePipe(injector);
    }

    ngOnInit() {
        this.getValidationMessages();
    }

    getValidationMessages() {
        this.field.validations.map((validation) => {
            if (validation.message) {
                this.errorMessages.set(this.validationNamePipe.transform(validation.type).toLowerCase(), validation.message);
            }
        });
    }
}
