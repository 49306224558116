import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class CompanySettingsService {
    approvalProcessDeselected: EventEmitter<any> = new EventEmitter();
    invoiceProfileDeselected: EventEmitter<any> = new EventEmitter();
    travelPolicyDeselected: EventEmitter<any> = new EventEmitter();
    reasonDeselected: EventEmitter<any> = new EventEmitter();
    labelDeselected: EventEmitter<any> = new EventEmitter();
    customFieldDeselected: EventEmitter<any> = new EventEmitter();
    constructor() {}
}
