<ng-container *ngIf="mode === 'list'">
    <button class="btn btn-primary" type="button" (click)="createField()">{{'Common_Add' | localize}}</button>
    <mat-card class="mt-4" *ngIf="fieldConfig && fieldConfig.length > 0">
        <mat-card-content id="customField">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">{{'CustomField_Name' | localize}}</th>
                        <th scope="col">{{'CustomField_Label' | localize}}</th>
                        <th scope="col">{{'CustomField_Type' | localize}}</th>
                        <th scope="col">{{'CustomField_Validations' | localize}}</th>
                        <th scope="col">{{'Common_edit' | localize}}/{{'Common_delete' | localize}}</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let field of fieldConfig; let i = index">
                        <tr>
                            <td attr.data-label="{{'CustomField_Name' | localize}}">{{field.name}}</td>
                            <td attr.data-label="{{'CustomField_Label' | localize}}">{{field.label}}</td>
                            <td attr.data-label="{{'CustomField_Type' | localize}}">{{(field.type | tagTypeName)+ (field.type === tagType.Input ? '/' + (field.inputType | inputTypeName): '')}}</td>
                            <td attr.data-label="{{'CustomField_Validations' | localize}}">
                                <div class="mt-2">
                                    <span class="badge badge-pill badge-success m-1" *ngFor="let validation of field.validations">{{validation.type | validationName}}</span>
                                </div>
                            </td>
                            <td attr.data-label="{{'Common_edit' | localize}}/{{'Common_delete' | localize}}">
                                <div>
                                    <button mat-icon-button tooltip="{{'Edit' | localize}}" (click)="editField(field.name)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button mat-icon-button tooltip="{{'Delete' | localize}}" (click)="removeField(field.name)" class="text-danger">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <div class="d-flex">
                <button *ngIf="isSavable" class="ml-auto btn btn-primary" (click)="saveCustomFields()">{{'Common_Save' | localize}}</button>
            </div>
        </mat-card-content>
    </mat-card>
</ng-container>

<ng-container *ngIf="mode === 'create' || mode === 'edit'">
    <app-edit-custom-field [customFieldData]="currentFieldConfig" (onCustomFieldSubmit)="onCustomFieldSubmit($event)" (onCustomFieldCancel)="onCustomFieldCancel()"></app-edit-custom-field>
</ng-container>
