import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ProductsLoaderComponent } from './products-loader.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FlightSpinnerComponent } from './flight-spinner/flight-spinner.component';
import { HotelSpinnerComponent } from './hotel-spinner/hotel-spinner.component';
import { TrainSpinnerComponent } from './train-spinner/train-spinner.component';
import { AnimationControllerDirective } from './hotel-spinner/directives/animation-controller.directive';
import { CarSpinnerComponent } from './car-spinner/car-spinner.component';
import { RemoveNgxSpinnerModule } from '@app/shared/directives/remove-ngx-spinner/remove-ngx-spinner.module';



@NgModule({
  declarations: [ProductsLoaderComponent, FlightSpinnerComponent, HotelSpinnerComponent, TrainSpinnerComponent, CarSpinnerComponent, AnimationControllerDirective],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    RemoveNgxSpinnerModule
  ],
  exports: [ProductsLoaderComponent],
  providers: [DatePipe]
})
export class ProductsLoaderModule { }
