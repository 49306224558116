import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { IProductsLoaderType } from './products-loader.models';
import { ProductsLoaderService } from './products-loader.service';
import { Subscription } from 'rxjs';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConsts } from '@shared/AppConsts';

@Component({
    selector: 'app-products-loader',
    templateUrl: './products-loader.component.html',
    styleUrls: ['./products-loader.component.scss']
})
export class ProductsLoaderComponent extends AppComponentBase
    implements OnInit, OnDestroy {
    loaderType: IProductsLoaderType;
    loaderText: string;
    loaderServiceSubscription: Subscription;
    logoUrl: string;
    isVisible: boolean;

    constructor(
        injector: Injector,
        private loaderService: ProductsLoaderService
    ) {
        super(injector);
        this.logoUrl = this.getLogoUrl();
    }

    ngOnInit() {
        this.loaderServiceSubscription = this.loaderService.LoaderStatus.subscribe(
            res => {
                if ((this.isVisible !== res.status)) {
                    this.loaderText = '';
                    if (res.status === true) {
                        this.loaderType = res.type;
                        this.spinnerService.show('productsLoader');
                        this.isVisible = true;
                    } else {
                        this.loaderType = undefined;
                        this.spinnerService.hide('productsLoader');
                        this.isVisible = false;
                    }

                    if (res.loaderText) {
                        this.loaderText = res.loaderText;
                    }
                }
            }
        );
    }

    ngOnDestroy() {
        this.loaderServiceSubscription.unsubscribe();
    }

    private getLogoUrl() {
        if (this.appSession.tenant && this.appSession.tenant.id) {
            return (
                AppConsts.remoteServiceBaseUrl +
                '/TenantCustomization/GetTenantLogo?skin=light&tenantId=' +
                this.appSession.tenant.id +
                '&id=' +
                this.appSession.tenant.logoId
            );
        } else {
            return (
                this.appRootUrl() +
                '/assets/common/images/app-logo-on-' +
                this.currentTheme.baseSettings.menu.asideSkin +
                '.svg'
            );
        }
    }
}
