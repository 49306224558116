import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, from as _observableFrom, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import {
    ReservationDto,
    BookStructureRequestDto,
    HotelServiceProxy,
    API_BASE_URL,
    PayReservationInput,
    ReservationServiceProxy,
    PayReservationOutput,
    IssueReservationOutput,
    IssueReservationInput,
    CarServiceProxy,
    CarBookRequestDto,
    ApiException,
    TripServiceProxy,
    BookTripRequestDto,
    TripDto
} from './service-proxies';

@Injectable()
export class HotelServiceProxyExtended extends HotelServiceProxy {
    private _http: HttpClient;
    private _baseUrl: string;


    constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        super(http, baseUrl);
        this._http = http;
        this._baseUrl = baseUrl ? baseUrl : "";
    }

    /**
     * @model (optional)
     * @return Success
     */
    bookAndPayExtended(model: BookStructureRequestDto | null | undefined): Observable<ReservationDto> {
        let url_ = this._baseUrl + "/api/services/app/Hotel/BookAndPay";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(model);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this._http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processBookAndPayExtended(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processBookAndPayExtended(<any>response_);
                } catch (e) {
                    return <Observable<ReservationDto>><any>_observableThrow(e);
                }
            } else
                return <Observable<ReservationDto>><any>_observableThrow(response_);
        }));
    }

    protected processBookAndPayExtended(response: HttpResponseBase): Observable<ReservationDto> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
        if (status === 200) {
            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = resultData200 ? ReservationDto.fromJS(resultData200) : new ReservationDto();
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<ReservationDto>(<any>null);
    }

}

@Injectable()
export class ReservationServiceProxyExtended extends ReservationServiceProxy {
    private _http: HttpClient;
    private _baseUrl: string;


    constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        super(http, baseUrl);
        this._http = http;
        this._baseUrl = baseUrl ? baseUrl : "";
    }
    /**
     * @model (optional)
     * @return Success
     */
    payReservationExtended(model: PayReservationInput | null | undefined): Observable<PayReservationOutput> {
        let url_ = this._baseUrl + "/api/services/app/Reservation/PayReservation";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(model);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this._http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processPayReservationExtended(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processPayReservationExtended(<any>response_);
                } catch (e) {
                    return <Observable<PayReservationOutput>><any>_observableThrow(e);
                }
            } else {
                return <Observable<PayReservationOutput>><any>_observableThrow(response_);
            }
        }));
    }

    protected processPayReservationExtended(response: HttpResponseBase): Observable<PayReservationOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
        if (status === 200) {
            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = resultData200 ? PayReservationOutput.fromJS(resultData200) : new PayReservationOutput();
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<PayReservationOutput>(<any>null);
    }

    /**
     * @reservationId (optional) 
     * @return Success
    */
    issueExtended(model: IssueReservationInput | null | undefined): Observable<IssueReservationOutput> {
        let url_ = this._baseUrl + "/api/services/app/Reservation/Issue";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(model);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this._http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processIssueExtended(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processIssueExtended(<any>response_);
                } catch (e) {
                    return <Observable<IssueReservationOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<IssueReservationOutput>><any>_observableThrow(response_);
        }));
    }

    protected processIssueExtended(response: HttpResponseBase): Observable<IssueReservationOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
        if (status === 200) {
            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = resultData200 ? IssueReservationOutput.fromJS(resultData200) : new IssueReservationOutput();
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<IssueReservationOutput>(<any>null);
    }
}

@Injectable()
export class CarServiceProxyExtended extends CarServiceProxy {
    private _http: HttpClient;
    private _baseUrl: string;


    constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        super(http, baseUrl);
        this._http = http;
        this._baseUrl = baseUrl ? baseUrl : "";
    }
    /**
         * @model (optional)
         * @return Success
         */
    bookExtended(model: CarBookRequestDto | null | undefined): Observable<ReservationDto> {
        let url_ = this._baseUrl + "/api/services/app/Car/book";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(model);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this._http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processBookExtended(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processBookExtended(<any>response_);
                } catch (e) {
                    return <Observable<ReservationDto>><any>_observableThrow(e);
                }
            } else {
                return <Observable<ReservationDto>><any>_observableThrow(response_);
            }
        }));
    }

    protected processBookExtended(response: HttpResponseBase): Observable<ReservationDto> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
        if (status === 200) {
            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = resultData200 ? ReservationDto.fromJS(resultData200) : new ReservationDto();
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<ReservationDto>(<any>null);
    }
}

@Injectable()
export class TripServiceProxyExtended extends TripServiceProxy {
    private _http: HttpClient;
    private _baseUrl: string;


    constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        super(http, baseUrl);
        this._http = http;
        this._baseUrl = baseUrl ? baseUrl : "";
    }
    /**
         * @model (optional)
         * @return Success
         */
    bookExtended(model: BookTripRequestDto | null | undefined): Observable<TripDto> {
        let url_ = this._baseUrl + "/api/services/app/Trip/book";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(model);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this._http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processBookExtended(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processBookExtended(<any>response_);
                } catch (e) {
                    return <Observable<TripDto>><any>_observableThrow(e);
                }
            } else {
                return <Observable<TripDto>><any>_observableThrow(response_);
            }
        }));
    }

    protected processBookExtended(response: HttpResponseBase): Observable<TripDto> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
        if (status === 200) {
            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = resultData200 ? TripDto.fromJS(resultData200) : new TripDto();
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<TripDto>(<any>null);
    }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): Observable<any> {
    if (result !== null && result !== undefined)
        return _observableThrow(result);
    else
        return _observableThrow(new ApiException(message, status, response, headers, null));
}

function blobToText(blob: any): Observable<string> {
    return new Observable<string>((observer: any) => {
        if (!blob) {
            observer.next("");
            observer.complete();
        } else {
            let reader = new FileReader();
            reader.onload = function () {
                observer.next(this.result);
                observer.complete();
            }
            reader.readAsText(blob);
        }
    });
}
