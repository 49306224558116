
import { AppConsts } from '@shared/AppConsts';

export function getAirlinesLogoUrl(code, dimension: 'SM' | 'MD' | 'XL') {
    try {
        return AppConsts.cdnBaseUrl + `/images/airlines/${code}_${dimension}.png`;
    } catch (e) {
        return '';
    }
}
