import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailFormTemplateComponent } from './email-form-template.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReactiveValidationMessageModule } from '@app/shared/components/reactive-validation-message/reactive-validation-message.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UserProfileAutocompleteModule } from '@app/shared/components/user-profile-autocomplete/user-profile-autocomplete.module';
import { UtilsModule } from '@shared/utils/utils.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ReactiveValidationMessageModule,
    MatCheckboxModule,
    TooltipModule.forRoot(),
    UserProfileAutocompleteModule,
    UtilsModule
  ],
  declarations: [EmailFormTemplateComponent],
  exports: [EmailFormTemplateComponent]
})
export class EmailFormTemplateModule { }
