import { Component, OnInit, OnDestroy, Injector, ViewChild, ElementRef } from '@angular/core';
import { WidgetComponentBaseComponent } from '../widget-component-base';
import { LinearChartDto, AnalyticsDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-widget-sales-schedule-companies',
    templateUrl: './widget-sales-schedule-companies.component.html',
})
export class WidgetSalesScheduleCompaniesComponent extends WidgetComponentBaseComponent
    implements OnInit, OnDestroy {
    data: Array<LinearChartDto>;
    isLoading: boolean;
    selectedAgency: string;
    selectedDateRange: moment.Moment[] = [
        moment().add(-3, 'months').startOf('day'),
        moment().endOf('day'),
    ];

    constructor(
        injector: Injector,
        private analyticsService: AnalyticsDashboardServiceProxy
    ) {
        super(injector);
    }

    ngOnInit() {
        this.subDateRangeFilter();
        this.subAgencyFilter();
        this.getData();
    }

    ngOnDestroy() {
        this.unSubDateRangeFilter();
        this.unSubAgencyFilter();
    }

    onDateRangeFilterChange = (dateRange) => {
        this.runDelayed(() => {
            if (
                !dateRange ||
                dateRange.length !== 2 ||
                (this.selectedDateRange[0] === dateRange[0] &&
                    this.selectedDateRange[1] === dateRange[1])
            ) {
                return;
            }

            this.selectedDateRange = dateRange;
            this.getData();
        });
    }

    getData() {
        this.isLoading = true;
        this.analyticsService
            .getSalesBySchedulesAndCompanies(this.selectedDateRange[0], this.selectedDateRange[1], this.selectedAgency)
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe((data) => {
                this.data = data;
                this.isLoading = false;
            });
    }

    subDateRangeFilter() {
        abp.event.on(
            'app.dashboardFilters.dateRangePicker.onDateChange',
            this.onDateRangeFilterChange
        );
    }

    unSubDateRangeFilter() {
        abp.event.off(
            'app.dashboardFilters.dateRangePicker.onDateChange',
            this.onDateRangeFilterChange
        );
    }

    subAgencyFilter() {
        abp.event.on(
            'app.dashboardFilters.dateRangePicker.onAgencyChange',
            this.onAgencyChange
        );
    }

    unSubAgencyFilter() {
        abp.event.off(
            'app.dashboardFilters.dateRangePicker.onAgencyChange',
            this.onAgencyChange
        );
    }

    onAgencyChange = (agency) => {
        this.runDelayed(() => {
            this.selectedAgency = agency;
            this.getData();
        });
    }
}
