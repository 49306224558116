import { animate, animateChild, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { Injector, TemplateRef, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarRef as MatSnackBarRef, LegacyTextOnlySnackBar as TextOnlySnackBar } from '@angular/material/legacy-snack-bar';
import { FileDownloadLinkDTO, FileDownloadLinksOutput, HostDashboardServiceProxy, TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { AbpSessionService } from 'abp-ng2-module';
import { isEmpty } from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { filter, finalize } from 'rxjs/operators';
import { WidgetComponentBaseComponent } from '../widget-component-base';

@Component({
	selector: 'app-widget-download-show-file',
	templateUrl: './widget-download-show-file.component.html',
	styleUrls: ['./widget-download-show-file.component.scss'],
	animations: [
		trigger('linksContainer', [
			transition(':enter', [
				query('@links', stagger(300, animateChild()), {
					optional: true,
				}),
			]),
			transition(':leave', [
				query('@links', stagger(300, animateChild()), {
					optional: true,
				}),
			]),
		]),
		trigger('links', [
			transition(':enter', [
				style({ transform: 'scale(0.5)', opacity: 0 }), // initial
				animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)', style({ transform: 'scale(1)', opacity: 1 })), // final
			]),
			transition(':leave', [
				style({ transform: 'scale(1)', opacity: 1, height: '*' }),
				animate(
					'1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
					style({
						transform: 'scale(0.5)',
						opacity: 0,
						height: '0px',
						margin: '0px',
					})
				),
			]),
		]),
		trigger('addLinkInput', [
			state('in', style({ opacity: 1, transform: 'translateY(0)' })),
			transition('void => *', [style({ opacity: 0, transform: 'translateY(100%)' }), animate(200)]),
			transition('* => void', [animate(200, style({ opacity: 0, transform: 'translateY(100%)' }))]),
		]),
	],
})
export class WidgetDownloadShowFileComponent extends WidgetComponentBaseComponent implements OnInit {
	form: UntypedFormGroup = new UntypedFormGroup({});
	@ViewChild('templateModal') templateModal: TemplateRef<any>;

	constructor(
		injector: Injector,
		private tenantDashboardService: TenantDashboardServiceProxy,
		private hostDashboardService: HostDashboardServiceProxy,
		private fb: UntypedFormBuilder,
		private _sessionService: AbpSessionService,
		private modalService: BsModalService,
		private matSnackBar: MatSnackBar
	) {
		super(injector);
		const reg = '^(http|https):\/\/[^ "]+$';
		this.form = fb.group({
			url: ['', [Validators.required, Validators.pattern(reg)]],
			alias: [''],
		});
	}

	//Elementi link e booleani di controllo
	widgetLinks: FileDownloadLinksOutput = new FileDownloadLinksOutput();
	isOnAddMode: boolean = false;
	isLoading: boolean = false;

	//Link in rimozione e rimossi (per eventuale ripristino)
	lastRemovedLink: FileDownloadLinkDTO = null;
	lastRemovedLinkIndex: number = -1;
	linkIndexToRemove: number = null;
	removedLinks: FileDownloadLinkDTO[] = [];
	removingLinksString: string = '';
	//Modali e snackbar
	openedSnackBar: MatSnackBarRef<TextOnlySnackBar> = null;
	modalRef: BsModalRef;

	ngOnInit(): void {
		this.isLoading = true;
		this.refreshLinks(false);
	}

	addLink(link: string, alias: string) {
		if (this.openedSnackBar) {
			this.openedSnackBar.dismiss();
		}
		if (this.widgetLinks == null) {
			this.widgetLinks = new FileDownloadLinksOutput();
			this.widgetLinks.elements = [];
		}
		this.widgetLinks.elements.push(
			new FileDownloadLinkDTO({
				link: link,
				alias: alias,
				addedByHost: this.getTenantId() == null ? true : false,
			})
		);

		this.refreshLinks(true);
	}

	refreshLinks(pushNewLinks: Boolean = false) {
		if (pushNewLinks == true) {
			switch (this.getTenantId()) {
				case null:
					this.hostDashboardService
						.setFilesDownload(this.widgetLinks.elements)
						.pipe(finalize(() => {}))
						.subscribe((_) => {});
					break;
				default:
					this.tenantDashboardService
						.setFilesDownload(this.widgetLinks.elements)
						.pipe(finalize(() => {}))
						.subscribe((_) => {});
					break;
			}
		} else {
			this.getLinks();
		}
	}

	removeLink(index: number) {
		this.lastRemovedLink = this.widgetLinks.elements.splice(index, 1)[0];
		this.lastRemovedLinkIndex = index;
		this.openSnackBarForUndoDeleting(false);
		this.refreshLinks(true);
	}

	removeAllLinks() {
		this.linkIndexToRemove = null;
		this.removedLinks = this.widgetLinks.elements;
		let hostLinks = this.widgetLinks.elements.filter((v) => {
			return v.addedByHost == true;
		});
		if (this.getTenantId() != null) {
			this.widgetLinks.elements = hostLinks;
		} else {
			this.widgetLinks.elements = [];
		}

		this.refreshLinks(true);
		this.openSnackBarForUndoDeleting(true);
	}

	getLinks() {
		switch (this.getTenantId()) {
			case null:
				this.hostDashboardService
					.getFilesDownload()
					.pipe(
						filter((value) => !isEmpty(value)),
						finalize(() => {
							this.isLoading = false;
						})
					)
					.subscribe((ret) => {
						this.widgetLinks.elements = ret.elements;
					});
				break;
			default:
				this.tenantDashboardService
					.getFilesDownload()
					.pipe(
						filter((value) => !isEmpty(value)),
						finalize(() => {
							this.isLoading = false;
						})
					)
					.subscribe((ret) => {
						this.widgetLinks.elements = ret.elements;
					});
				break;
		}
	}

	formatLink(link: FileDownloadLinkDTO) {
		if (link.alias) {
			return link.alias;
		}
		let s = link.link.split('?')[0].split('/').pop();
		if (s != '') {
			return s;
		} else {
			return link.link;
		}
	}

	get addLinkForm() {
		return this.form.controls;
	}

	triggerAddMode() {
		this.form.reset();
		this.isOnAddMode = !this.isOnAddMode;
	}

	/**
	 * Apertura snackbar per avviso cancellazione link ed eventuale annullamento.
	 * @param allRemoved
	 */
	openSnackBarForUndoDeleting(allRemoved: boolean) {
		if (!allRemoved) {
			//#region se viene rimosso solo un link
			this.openedSnackBar = this.matSnackBar.open(this.l('Link_Widget_Link_Deleted'), this.l('Link_Widget_Undo'), {
				panelClass: 'success-snackbar',
				duration: 5000,
			});
			this.openedSnackBar.onAction().subscribe(() => {
				this.widgetLinks.elements.splice(this.lastRemovedLinkIndex, 0, this.lastRemovedLink); // Reinserimento del link precedentemente cancellato, nel suo indice.
				this.openedSnackBar.dismiss();
				this.refreshLinks(true);
			});
			//#endregion
		} else {
			//#region se tutti i link vengono rimossi
			this.openedSnackBar = this.matSnackBar.open(this.l('Link_Widget_AllLinks_Deleted'), this.l('Link_Widget_Undo'), {
				panelClass: 'success-snackbar',
				duration: 5000,
			});

			this.openedSnackBar.onAction().subscribe(() => {
				this.widgetLinks.elements = this.removedLinks;
				this.openedSnackBar.dismiss();
				this.refreshLinks(true);
			});
			//#endregion
		}
	}

	/**
	 * Funzione che controlla se l'utente corrente ha la possibilità di rimuovere il link.
	 * @param link Link da controllare.
	 * @returns
	 */
	checkIfUserCanRemoveLink(link: FileDownloadLinkDTO): boolean {
		let check = false;
		if (link.addedByHost) {
			//Se il link è stato aggiunto da host, e sono host (il TenantId è null)
			if (this.getTenantId() == null) {
				check = true;
			} else {
				check = false;
			}
		} else {
			check = true;
		}

		return check;
	}

	getTenantId() {
		return this._sessionService.tenantId;
	}

	/**
	 * Apertura modale di cancellazione link.
	 * @param linkIndex Link da eliminare.
	 */
	openConfirmDeleteDialog(linkIndex: number) {
		this.linkIndexToRemove = linkIndex;
		if (this.linkIndexToRemove != null) {
			this.removingLinksString = `${this.l('Link_Widget_Confirm_Delete_Link')}\n${this.widgetLinks.elements[this.linkIndexToRemove].link}`;
		} else {
			this.removingLinksString = `${this.l('Link_Widget_Confirm_Delete_All_Links')}`;
		}
		this.modalRef = this.modalService.show(this.templateModal, Object.assign({}, { class: 'gray modal-dialog-centered' }));
	}

	/**
	 * Chiusura modale di cancellazione.
	 */
	closeConfirmDeleteDialog() {
		this.modalRef.hide();
	}

	/**
	 * Funzione che semplicemente apre in una scheda blank, il link passato tramite parametro.
	 * @param link Link da aprire.
	 */
	openLink(link: FileDownloadLinkDTO) {
		window.open(link.link, '_blank');
	}
}
