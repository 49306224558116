import { Component, OnInit, Input } from '@angular/core';
import { FieldConfigDto } from '@shared/service-proxies/service-proxies';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
})
export class ButtonComponent implements OnInit {
    @Input() field: FieldConfigDto;
    @Input() group: UntypedFormGroup;
    @Input() readonly: boolean;

    constructor() {}

    ngOnInit() {
    }

    updateState() {
        if(this.readonly) {
            this.group.get(this.field.name).disable();
        }
    }
}
