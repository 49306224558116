import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import * as moment from 'moment';
import { getNumberOfPassenger } from '@app/shared/helpers/getNumberOfPassenger';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { FlightHistoryItem } from '@shared/interfaces/flight-history-item';
import { AvailabilityRequestMulticityDTO } from '@shared/service-proxies/service-proxies';
import { typeAvailabilityRequestMulticityDTO, checkHistoryItemsVersion } from '@app/main/reservations/utility-functions';
import { ISearchHistory } from '@app/shared/models/search-history.model';

@Injectable()
export class FlightSearchHistoryService {
    max = 10;

    constructor(private appSessionService: AppSessionService) {
        const historyItems = this.getAllFlightHistoryItem() as Array<ISearchHistory>;
        this.setFlightHistoryItem(checkHistoryItemsVersion(historyItems) as Array<FlightHistoryItem>);
    }

    /**
     * Add item to the history of Flight
     * @param model The model of the search request
     */
    saveFlightSearchHistory(model: AvailabilityRequestMulticityDTO) {
        let flightHistory: FlightHistoryItem[] = this.getAllFlightHistoryItem();
        const flightHistoryItem = this.buildFlightHistoryItem(model);
        flightHistory.map((el, index) => {
            if ((el.tenantId === flightHistoryItem.tenantId && el.userId === flightHistoryItem.userId) && this.checkIfFlightElementAlredyExist(el.model, flightHistoryItem.model)) {
                (flightHistory.length === 1) ? flightHistory = [] : flightHistory.splice(index, 1);
            }
        });
        flightHistoryItem.tenantId = this.appSessionService.tenantId;
        flightHistory = [flightHistoryItem, ...flightHistory];
        flightHistory = this.checkMaxNumberReached(flightHistory);
        localStorage.setItem('flightHistory', JSON.stringify(flightHistory));
    }

    /**
     * set history items in local storage
     * @param historyItems set of history items
     */
    setFlightHistoryItem(historyItems: Array<FlightHistoryItem>) {
        localStorage.setItem('flightHistory', JSON.stringify(historyItems));
    }

    /**
     * Build the flight history item
     * @param model The model of the search request
     * @returns return the built history flight item
     */
    buildFlightHistoryItem(model: AvailabilityRequestMulticityDTO): FlightHistoryItem {
        let flightHistoryItem: FlightHistoryItem = {
            tenantId: this.appSessionService.tenantId,
            userId: this.appSessionService.userId,
            model: model,
            version: this.appSessionService.application.releaseDate.format('YYYYMMDD')
        };

        flightHistoryItem = this.typeHistoryItem(flightHistoryItem);
        return flightHistoryItem;
    }

    /**
     * Get all the element from the history of flight
     *
     */
    private getAllFlightHistoryItem(): FlightHistoryItem[] {
        let flightHistory: FlightHistoryItem[] = JSON.parse(localStorage.getItem('flightHistory')) || [];

        flightHistory.map((element, index) => {
            flightHistory[index] = this.typeHistoryItem(element);
        });
        return flightHistory;
    }

    /**
     * Convert the property of the flight model to the correct type
     * @param flightHistoryItem The item of the flight history
     */
    private typeHistoryItem(element: FlightHistoryItem) {
        element.model = typeAvailabilityRequestMulticityDTO(element.model);
        return element;
    }

    /**
     * Get all the element from the history of flight by tenant and user
     */
    private getFlightHistoryItemByUser(): FlightHistoryItem[] {
        let flightHistory: FlightHistoryItem[] = this.getAllFlightHistoryItem();
        flightHistory = flightHistory.filter((el) => (el.tenantId === this.appSessionService.tenantId && el.userId === this.appSessionService.userId));
        return flightHistory;
    }

    /**
     * Return an observable of all the element from the history of flight
     */
    getFlightHistoryListByUser(): Observable<FlightHistoryItem[]> {
        return of(this.getFlightHistoryItemByUser());
    }

    /**
     * Check if an item already exist into the history of flight
     * @param model The model of the search request
     */
    checkIfFlightElementAlredyExist(model: AvailabilityRequestMulticityDTO, newModel: AvailabilityRequestMulticityDTO): boolean {
        if (this.getFlightHistoryItemByUser().length > 0) {
            let alreadyExist = true;
                if (model.tripType === newModel.tripType &&
                    getNumberOfPassenger(model.passengers) === getNumberOfPassenger(newModel.passengers)) {
                    for (let i = 0; i < model.legs.length; i++) {
                        if (alreadyExist) {
                            if (newModel.legs[i]) {
                            alreadyExist = moment(newModel.legs[i].departureDate).format('YYYY-MM-DD') === moment(model.legs[i].departureDate).format('YYYY-MM-DD')
                                            && newModel.legs[i].originAirportCode === model.legs[i].originAirportCode
                                            && newModel.legs[i].destinationAirportCode === model.legs[i].destinationAirportCode;
                            } else {
                                alreadyExist = false;
                            }
                        }
                    }
                } else {
                    return false;
                }
            return alreadyExist;
        } else {
            return false;
        }
    }

    /**
     * check if the max number of items has been reached
     * @param flightHistoryItem The item of the flight history
     */
    checkMaxNumberReached(flightHistoryItem: FlightHistoryItem[]): FlightHistoryItem[] {
        let getUserElement = flightHistoryItem.filter(el => ((this.appSessionService.userId === el.userId) && (this.appSessionService.tenantId === el.tenantId)));
        getUserElement = getUserElement.slice(0, this.max);
        let getOtherElement =  flightHistoryItem.filter(el => !((this.appSessionService.userId === el.userId) && (this.appSessionService.tenantId === el.tenantId)));
        return [...getUserElement, ...getOtherElement];
    }

    /**
     * Remove a specific element from the history of flight
     * @param index index of element
     */
    removeFlightHistoryElement(index: number) {
        const flightHistory: FlightHistoryItem[] = this.getFlightHistoryItemByUser();
        flightHistory.splice(index, 1);
        let getOtherElement =  this.getAllFlightHistoryItem().filter(el => !((this.appSessionService.userId === el.userId) && (this.appSessionService.tenantId === el.tenantId)));
        localStorage.setItem('flightHistory', JSON.stringify([...flightHistory, ...getOtherElement]));
    }

     /**
     * Remove all the element from the history of flight by user
     */
    removeAllHistoryElementByUser() {
        let getOtherElement =  this.getAllFlightHistoryItem().filter(el => !((this.appSessionService.userId === el.userId) && (this.appSessionService.tenantId === el.tenantId)));
        localStorage.setItem('flightHistory', JSON.stringify(getOtherElement));
    }

    /**
     * remove if the dates is out to date
     */
    removeIfDateIsPast() {
        const getUserElement = this.getFlightHistoryItemByUser();
        getUserElement.map(el => {
            let removed = false;
            el.model.legs.map((leg, index) => {
                if (moment(leg.departureDate).startOf('day').isBefore(moment().startOf('day')) && !removed) {
                    removed = true;
                    this.removeFlightHistoryElement(index);
                }
            });
        });
    }
}
