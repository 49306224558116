<div class="col-12 p-0">
    <div dropdown #dateTimepickerDropdown="bs-dropdown" defaultDropdownSize [insideClick]="true"
        [autoClose]="autoClose" (onHidden)="onDropdownHidden()" (onShown)="checkIfIsDisabled($event)" [isDisabled]="disabled">

        <div class="input-group" dropdownToggle>
            <div [ngClass]="{'col-12 p-0': isTrainPostSaleAction}" class="input-group-prepend">
                <span class="input-group-text">
                    <i class="material-icons">today</i>
                </span>
                <input class="form-control" type="text" [value]="datepicker?.value" [disabled]="disabled"
                    [ngClass]="{disabled : disabled}" (change)="onEmptyValue($event)">
            </div>
        </div>
        <div class="dropdown-menu" *dropdownMenu>
            <div class="date-time-picker-container">
                <bs-datepicker-inline *ngIf="!selectTime" [(bsValue)]="date" [minDate]="minDate" [bsConfig]="datePickerConfig">
                </bs-datepicker-inline>

                <div class="d-flex justify-content-center mt-auto time-container" *ngIf="selectTime">
                    <timepicker class="custom-timepicker" [(ngModel)]="date" [disabled]="disabled" [mousewheel]="mousewheel"
                    [showMeridian]="isMeridian" [hourStep]="hoursStep"
                        [minuteStep]="minutesStep">
                    </timepicker>
                    <button class="btn btn-sm btn-primary mt-1 mr-2 time-container-btn" (click)="onDropdownHidden()">
                        <i class="fas fa-check"></i>
                        {{'confirm' | localize}}
                    </button>
                </div>


                <button type="button" class="btn btn-sm btn-light btn-block text-primary mt-auto"
                    (click)="selectTime = !selectTime">
                    <i *ngIf="!selectTime" class="far fa-clock" (click)="preventClose($event);selectTime = !selectTime"></i>
                    <i *ngIf="selectTime" class="far fa-calendar-alt" (click)="preventClose($event);selectTime = !selectTime"></i>
                </button>
            </div>
        </div>
    </div>

    <input type="datetime" #datepicker bsDatepicker datePickerMomentModifier [bsConfig]="datePickerConfig"
        [minDate]="minDate" [date]="date" (dateChange)="dateChange($event)" hidden />
</div>
