import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileAutocompleteComponent } from './user-profile-autocomplete.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@shared/utils/utils.module';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    UtilsModule
  ],
  declarations: [UserProfileAutocompleteComponent],
  exports: [UserProfileAutocompleteComponent]
})
export class UserProfileAutocompleteModule { }
