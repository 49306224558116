import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { ParamMap, Params } from '@angular/router';
import { DetailHotelParams } from '@app/shared/models/client-hotel-models';

@Injectable()
export class WizardService {
//service condiviso tra la parte hotel e la parte voli;
  currentStep: number;
  @Output() changeStepEmitter = new EventEmitter();
  searchResultParamsQueryString: Params;
  checkOutParams: ParamMap;
  confirmParams: ParamMap;
  detailParams: DetailHotelParams = new DetailHotelParams();
  constructor() {
    this.currentStep = 1;
    }
    //metodi condivisi
    setStep(step) {
        this.currentStep = step;
    }

    getCurrentStep(): Observable<number> {
        return of(this.currentStep);
    }
    setSearchResultParamsQueryString(paramsQueryString) {
        this.searchResultParamsQueryString = paramsQueryString;
    }
    setCheckoutParamMap(params) {
        this.checkOutParams = params;
    }

    setConfirmParamMap(params) {
        this.confirmParams = params;
    }
    getSearchResultParamsQueryString(): Observable<any> {
        return of(this.searchResultParamsQueryString);
    }
    getCheckoutParamMap(): Observable<any> {
        return of(this.checkOutParams);
    }
    getConfirmParamMap(): Observable<any> {
        return of(this.confirmParams);
    }
    //solo per la parte hotel
    setDetailHotelParamMap(params, queryString) {
        this.detailParams.paramMap = params;
        this.detailParams.queryString = queryString;
    }
    getDetailHotelParamMap(): Observable<any> {
        return of(this.detailParams);
    }

}
