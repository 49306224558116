import { TravelDocumentDto, UserProfileDto, FidelityCardDto } from '@shared/service-proxies/service-proxies';
import { SelectItem } from 'primeng';

export class TravelDocumentClient {
    index: number;
    travelDocument: TravelDocumentDto;
}

/**classe che estende UserProfileDto per risolvere il problema del formato delle date serializzato dal proxy sempre come ZULU.**/
export class MyUserProfileDto extends UserProfileDto {
    toJSON(data?: any) {
        let mydata = super.toJSON(data);
        mydata['birthDate'] = this.birthDate ? this.birthDate.format('YYYY-MM-DD') : <any>undefined;
        return mydata;
    }
}

/**classe che estende TravelDocumentDto per risolvere il problema del formato delle date serializzato dal proxy sempre come ZULU.**/
export class MyTravelDocumentDto extends TravelDocumentDto {
    toJSON(data?: any) {
        let mydata = super.toJSON(data);
        mydata['expirationDate'] = this.expirationDate ? this.expirationDate.format('YYYY-MM-DD') : <any>undefined;
        mydata['issuingDate'] = this.issuingDate ? this.issuingDate.format('YYYY-MM-DD') : <any>undefined;
        return mydata;
    }
}

export class UserProfileAutocompleteEmitter {
    userProfile: MyUserProfileDto;
    index: number;

    constructor(userProfile: MyUserProfileDto, index: number = -1) {
        this.userProfile = userProfile;
        this.index = index;
    }
}

export class FrequentFlyerEmitter {
    frequentFlyer: FidelityCardDto;
    index: number;

    constructor(frequentFlyer: FidelityCardDto, index: number = -1) {
        this.frequentFlyer = frequentFlyer;
        this.index = index;
    }
}

export class userProfileToSaveEmitter {
    saveProfile: boolean;
    index: number;

    constructor(saveProfile: boolean, index: number = -1) {
        this.saveProfile = saveProfile;
        this.index = index;
    }
}

export class MySelectItem implements SelectItem {
    label: string;
    value: any;
    styleClass?: string;

    constructor(Label?: string, Value?: any) {
        this.label = Label;
        this.value = Value;
    }
}
