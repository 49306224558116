import { Component, OnInit, Input, EventEmitter, Output, Injector, OnDestroy } from '@angular/core';
import { FieldConfigDto, TagTypeDto } from '@shared/service-proxies/service-proxies';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { cloneDeep, head } from 'lodash';
import { Subscription, Observable } from 'rxjs';
import { ValidatorDto, ValidatorTypeDto } from '@app/api/models';

@Component({
	selector: 'app-custom-field-form',
	templateUrl: './custom-field-form.component.html',
})
export class CustomFieldFormComponent extends AppComponentBase implements OnInit, OnDestroy {
	@Input() fields: Array<FieldConfigDto>;
	@Input() mainForm: UntypedFormGroup;
	/**
	 * You can pass an observable (ex. A Subject as observable) to send submit signal to the form
	 */
	@Input() submitEvent?: Observable<void>;
	@Input() readonly: boolean = false;

	@Output() submit: EventEmitter<any>;

	form: UntypedFormGroup;
	submitEventSubscription: Subscription;

	constructor(injector: Injector, private fb: UntypedFormBuilder) {
		super(injector);
		this.submit = new EventEmitter<any>();
	}

	ngOnInit() {
		if (this.mainForm) {
			this.form = this.mainForm;
			this.addControl();
		} else {
			this.form = this.createControl();
		}

		if (this.submitEvent) {
			this.submitEventSubscription = this.submitEvent.subscribe(() => {
				this.onSubmit();
			});
		}
	}

	ngOnDestroy(): void {
		if (this.submitEventSubscription) {
			this.submitEventSubscription.unsubscribe();
		}
	}

	get Value() {
		return this.form.value;
	}

	createControl(): UntypedFormGroup {
		const group = this.fb.group({});

		this.fields.forEach((field) => {
			let control;
			if (field.type === TagTypeDto.MultiSelect) {
				control = this.fb.control(field.values, this.bindValidations(field.validations || []));
			} else {
				control = this.fb.control(head(field.values), this.bindValidations(field.validations || []));
			}
			group.addControl(field.name, control);
		});
		return group;
	}

	addControl(): void {
		this.fields.forEach((field) => {
			let control;
			if (field.type === TagTypeDto.MultiSelect) {
				control = this.fb.control(field.values, this.bindValidations(field.validations || []));
			} else {
				control = this.fb.control(head(field.values), this.bindValidations(field.validations || []));
			}
			this.form.addControl(field.name, control);
		});
	}

	bindValidations(validations: Array<ValidatorDto>) {
		if (validations.length > 0) {
			const validationList = [];
			validations.forEach((validation) => {
				validationList.push(this.getValidator(validation));
			});

			return Validators.compose(validationList);
		}

		return null;
	}

	getValidator(validator: ValidatorDto): ValidationErrors | ValidatorFn {
		switch (validator.type) {
			case ValidatorTypeDto.Required:
				return Validators.required;
			case ValidatorTypeDto.Email:
				return Validators.email;
			case ValidatorTypeDto.Min:
				return Validators.min(Number(validator.value));
			case ValidatorTypeDto.Max:
				return Validators.max(Number(validator.value));
			case ValidatorTypeDto.MinLength:
				return Validators.minLength(Number(validator.value));
			case ValidatorTypeDto.MaxLength:
				return Validators.maxLength(Number(validator.value));
			case ValidatorTypeDto.Pattern:
				return Validators.pattern(validator.value);
		}
	}

	onSubmit() {
		if (this.form.valid) {
			const values = this.form.getRawValue();
			const fields = cloneDeep(this.fields);
			Object.keys(values).map((value) => {
				const fieldIndex = fields.findIndex((field) => field.name === value);
				if (fieldIndex >= 0) {
					if (Array.isArray(values[value])) {
						fields[fieldIndex].values = values[value];
					} else if (typeof values[value] !== 'undefined' && values[value] !== null) {
						fields[fieldIndex].values = [values[value]];
					} else {
						fields[fieldIndex].values = values[value];
					}
				}
			});
			this.submit.emit(fields);
		} else {
			this.submit.emit(null);
			this.validateAllFormFields(this.form);
		}
	}

	validateAllFormFields(formGroup: UntypedFormGroup) {
		Object.keys(formGroup.controls).forEach((field) => {
			const control = formGroup.get(field);
			control.markAsTouched({ onlySelf: true });
		});
	}
}
