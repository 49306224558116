import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaxNumberPickerComponent } from '@app/flight/form-search-flight/passengers-selection/number-picker/number-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [PaxNumberPickerComponent],
  exports: [PaxNumberPickerComponent]
})
export class NumberPickerModule { }
