import { Pipe, PipeTransform, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { TagTypeDto } from '@shared/service-proxies/service-proxies';

@Pipe({
    name: 'tagTypeName',
})
export class TagTypeNamePipe extends AppComponentBase implements PipeTransform {

    constructor(injector: Injector) {
        super(injector);
    }

    transform(value: any): any {
        let tagTypeDto: Array<any> = Object.entries(TagTypeDto);
        tagTypeDto.splice(0, tagTypeDto.length / 2);
        const tagValue = tagTypeDto.find((tag) =>
            tag[1] === value ? tag[0] : undefined
        );
        return tagValue[0];
    }
}
