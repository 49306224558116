import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSourceAutocompleteComponent } from './multi-source-autocomplete.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@shared/utils/utils.module';

@NgModule({
    declarations: [MultiSourceAutocompleteComponent],
    imports: [CommonModule, MatAutocompleteModule, FormsModule, ReactiveFormsModule, MatButtonModule, UtilsModule],
    exports: [MultiSourceAutocompleteComponent],
    providers: []
})
export class MultiSourceAutocompleteModule {}
