import { Component, OnInit, ViewChild, EventEmitter, Injector } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/common/app-component-base';
import { SwUpdate } from '@angular/service-worker';

@Component({
	selector: 'app-pwa-update-modal',
	templateUrl: './pwa-update-modal.component.html',
})
export class PwaUpdateModalComponent extends AppComponentBase implements OnInit {
	updateEmitter: EventEmitter<boolean>;
	@ViewChild('updateModal') updateModal: BsModalRef;
	currentModal: BsModalRef;

	constructor(injector: Injector, private swUpdate: SwUpdate, private modalService: BsModalService) {
		super(injector);
		this.updateEmitter = new EventEmitter();
	}

	ngOnInit() {
		this.swUpdate.available.subscribe((el) => {
			this.openModal(this.updateModal);
			this.updateEmitter.subscribe((update) => {
				if (update) {
					this.closeModal();
					window.location.reload();
				}
			});
		});
	}

	openModal(template) {
		this.currentModal = this.modalService.show(template, { class: 'modal-lg', backdrop: 'static' });
	}

	closeModal() {
		this.currentModal.hide();
	}
}
