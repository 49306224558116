import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperStateStyleDirective } from '../stepper-state-style.directive';
import { PopoverOutViewportModule } from '../popover-out-viewport/popover-out-viewport.module';

@NgModule({
    imports: [
        CommonModule
  ],
  declarations: [StepperStateStyleDirective],
  exports: [StepperStateStyleDirective]
})
export class SearchResultUtilitiesModule { }
