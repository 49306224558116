import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contextControlName'
})
export class ContextControlNamePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    return {
        controlName: value
    };
  }

}
