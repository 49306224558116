import { Injectable } from '@angular/core';
import { UserSettings, IUserSetting } from './user-settings.models';

@Injectable()
export class UserSettingsService {
   private setting_key: string;

   constructor() {
       this.setting_key = `bookingtool_user_settings_${abp.session.tenantId}_${abp.session.userId}`;
   }

    private getUserSettings(): IUserSetting{
        let userSettingStorage = localStorage.getItem(this.setting_key);
        const userSettings: IUserSetting = JSON.parse(userSettingStorage);
        return userSettings || {};
    }

    getUserSetting(settingName: UserSettings) {
        const userSettings = this.getUserSettings();
        return userSettings[settingName];
    }

    setUserSetting(settingName: UserSettings, value: any) {
        const userSettings = this.getUserSettings();
        userSettings[settingName] = value;
        localStorage.setItem(this.setting_key, JSON.stringify(userSettings));
    }
}
