import { AvailabilityStructuresRequestDto, HotelBookResponseDto, QuotationDto, DocumentDto, AvailabilityStructuresPagedRequestDto, RefGuestDto, GuestDto, BookStructureRequestDto, DestinationDto } from '@shared/service-proxies/service-proxies';
import { AvailabilityStructureDetailRequestDto } from '../../../shared/service-proxies/service-proxies';
import { Params, ParamMap } from '@angular/router';

/**classi che estende i modelli server per risolvere il problema del formato delle date serializzato dal proxy sempre come ZULU.**/
export class MyAvailabilityStructuresRequestDto extends AvailabilityStructuresRequestDto {
    toJSON(data?: any) {
        let mydata = super.toJSON(data);
        mydata['checkinDate'] = this.checkinDate ? this.checkinDate.format('YYYY-MM-DD') : <any>undefined;
        mydata['checkoutDate'] = this.checkoutDate ? this.checkoutDate.format('YYYY-MM-DD') : <any>undefined;
        return mydata;
    }
}

export class MyQuotationDto extends QuotationDto {
    toJSON(data?: any) {
        let mydata = super.toJSON(data);
        mydata['quotationExpires'] = this.quotationExpires ? this.quotationExpires.format('YYYY-MM-DD') : <any>undefined;
        return mydata;
    }
}
export class MyDocumentDto extends DocumentDto {
    toJSON(data?: any) {
        let mydata = super.toJSON(data);
        mydata['expirationDate'] = this.expirationDate ? this.expirationDate.format('YYYY-MM-DD') : <any>undefined;
        return mydata;
    }
}

export class MyAvailabilityStructureDetailRequestDto extends AvailabilityStructureDetailRequestDto {
    toJSON(data?: any) {
        let mydata = super.toJSON(data);
        mydata['checkinDate'] = this.checkinDate ? this.checkinDate.format('YYYY-MM-DD') : <any>undefined;
        mydata['checkoutDate'] = this.checkoutDate ? this.checkoutDate.format('YYYY-MM-DD') : <any>undefined;
        return mydata;
    }
}

export class MyAvailabilityStructuresPagedRequestDto extends AvailabilityStructuresPagedRequestDto {
    toJSON(data?: any) {
        let mydata = super.toJSON(data);
        mydata['checkinDate'] = this.checkinDate ? this.checkinDate.format('YYYY-MM-DD') : <any>undefined;
        mydata['checkoutDate'] = this.checkoutDate ? this.checkoutDate.format('YYYY-MM-DD') : <any>undefined;
        return mydata;
    }
}
export class MyHotelBookResponseDto extends HotelBookResponseDto {
    toJSON(data?: any) {
        let mydata = super.toJSON(data);
        mydata['checkIn'] = this.checkIn ? this.checkIn.format('YYYY-MM-DD') : <any>undefined;
        mydata['checkOut'] = this.checkOut ? this.checkOut.format('YYYY-MM-DD') : <any>undefined;
        return mydata;
    }
}
export class MyHotelBookRequestDto extends BookStructureRequestDto {
    toJSON(data?: any) {
        let mydata = super.toJSON(data);
        mydata['checkIn'] = this.checkIn ? this.checkIn.format('YYYY-MM-DD') : <any>undefined;
        mydata['checkOut'] = this.checkOut ? this.checkOut.format('YYYY-MM-DD') : <any>undefined;
        return mydata;
    }
}
export class MyRefGuestDto extends RefGuestDto {
    toJSON(data?: any) {
        let mydata = super.toJSON(data);
        mydata['birthDate'] = this.birthDate ? this.birthDate.format('YYYY-MM-DD') : <any>undefined;
        return mydata;
    }
}
export class MyGuestDto extends GuestDto {
    toJSON(data?: any) {
        let mydata = super.toJSON(data);
        mydata['birthDate'] = this.birthDate ? this.birthDate.format('YYYY-MM-DD') : <any>undefined;
        return mydata;
    }
}

export class DetailHotelParams {
    queryString: Params;
    paramMap: ParamMap;
}

export class HotelEmitter {
    index: number;
    destination: DestinationDto;
}
