import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LegDetailsComponent } from './leg-details.component';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UtilsModule } from '@shared/utils/utils.module';
import { UtilitiesModule } from '@app/shared/pipes/utilities.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UtilsModule,
    UtilitiesModule,
    TooltipModule.forRoot()
  ],
  declarations: [LegDetailsComponent],
  exports: [LegDetailsComponent]
})
export class LegDetailsModule { }
