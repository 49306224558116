import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VatNumberReactiveInputComponent } from './vat-number-reactive-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@shared/utils/utils.module';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, UtilsModule],
    declarations: [VatNumberReactiveInputComponent],
    exports: [VatNumberReactiveInputComponent]
})
export class VatNumberInputModule {}
