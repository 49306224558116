import { AppConsts } from '@shared/AppConsts';
import { FormattedStringValueExtracter } from '@shared/helpers/FormattedStringValueExtracter';

export class SubdomainTenancyNameFinder {
	getCurrentTenancyNameOrNull(rootAddress: string): string {
		if (rootAddress.indexOf(AppConsts.tenancyNamePlaceHolderInUrl) < 0) {
			// Web site does not support subdomain tenant name
			return null;
		}

		let currentRootAddress = document.location.href;

        const baseCattolicaUrl = 'trasferte.unicatt.it';
		if (currentRootAddress.indexOf(baseCattolicaUrl) !== -1) {
			if (currentRootAddress.indexOf('test-') !== -1) {
                currentRootAddress = currentRootAddress.replace(`test-${baseCattolicaUrl}`, 'cattolica.app.test.fritravelapp.com');
			} else {
                currentRootAddress = currentRootAddress.replace(baseCattolicaUrl, 'cattolica.app.prod.fritravelapp.com');
            }
		}

		const formattedStringValueExtracter = new FormattedStringValueExtracter();
		const values: any[] = formattedStringValueExtracter.IsMatch(currentRootAddress, rootAddress);
		if (!values.length) {
			return null;
		}

		return values[0];
	}
}
