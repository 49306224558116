import { Pipe, PipeTransform, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Pipe({
    name: 'documentType'
})
export class DocumentTypePipe extends AppComponentBase implements PipeTransform {
    constructor(private injector: Injector) {
        super(injector);
    }
    transform(value: any, args?: any): any {
        if (value) {
            return this.l('PersonNameData_DocumentType_' + value);
        }
        return '';
    }
}
