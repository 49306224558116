/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { ApiConfiguration, ApiConfigurationParams } from '@app/api/api-configuration';
import { OrganizationUnitService, OrganizationUnitTypesService, UserService } from '@app/api/services';
import { CompanyService } from '@app/api/services/company.service';
import { AppConsts } from '@shared/AppConsts';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    OrganizationUnitService,
    OrganizationUnitTypesService,
    UserService,
    CompanyService,
    ApiConfiguration
  ],
})
export class ApiCommonModule {
  static forRoot(): ModuleWithProviders<ApiCommonModule> {
    return {
      ngModule: ApiCommonModule,
      providers: [
        {
          provide: ApiConfiguration, 
          useFactory: getRemoteServiceBaseUrl
        }
      ]
    }
  }
}

export function getRemoteServiceBaseUrl(): ApiConfigurationParams {
  return {
    rootUrl: AppConsts.remoteServiceBaseUrl
  };
}