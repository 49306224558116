import { Component, OnInit, Input, Output, EventEmitter, Injector, OnDestroy, ApplicationRef } from '@angular/core';
import { FieldConfigDto, TagTypeDto, InputTypeDto, ValidatorDto, FieldValueDto, MetaTypeDto } from '@shared/service-proxies/service-proxies';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Subscription } from 'rxjs';
import { validatorsWithValue } from './edit-custom-field.models';
import { ValidatorTypeDto } from '@app/api/models';

@Component({
    selector: 'app-edit-custom-field',
    templateUrl: './edit-custom-field.component.html',
    styleUrls: ['./edit-custom-field.component.scss']
})
export class EditCustomFieldComponent extends AppComponentBase
    implements OnInit, OnDestroy {
    @Input() customFieldData: FieldConfigDto;

    customField: UntypedFormGroup;
    tagTypes: Map<string, TagTypeDto>;
    inputTypes: Map<string, InputTypeDto>;
    validatorTypes: Map<string, ValidatorTypeDto>;
    metaTypeValues: Array<{
        text: string,
        value: string
    }>;

    @Output() onCustomFieldSubmit: EventEmitter<FieldConfigDto>;
    @Output()onCustomFieldCancel: EventEmitter<FieldConfigDto>;

    typeChangesSubcription: Subscription;

    constructor(injector: Injector, private fb: UntypedFormBuilder, private appRef: ApplicationRef) {
        super(injector);
        this.onCustomFieldSubmit = new EventEmitter();
        this.onCustomFieldCancel = new EventEmitter();
        this.customFieldData = new FieldConfigDto();
        this.tagTypes = this.getTagTypes();
        this.inputTypes = this.getInputTypes();
        this.metaTypeValues = this.getMetaTypeValues();
    }

    ngOnInit() {
        this.customField = this.initCustomFieldForm();
        this.validatorTypes = this.getValidatorsOptions();
        this.checkForm(this.customField.get('type').value);
        this.typeChangesSubcription = this.customField
            .get('type')
            .valueChanges.subscribe((value) => {
                this.checkForm(value);
            });
    }

    ngOnDestroy() {
        this.typeChangesSubcription.unsubscribe();
    }

    private initCustomFieldForm(): UntypedFormGroup {
        const form: UntypedFormGroup = this.fb.group({
            name: [this.customFieldData.name, [Validators.required, Validators.pattern('^[a-zA-Z_$][a-zA-Z_$0-9]*$')]],
            label: [this.customFieldData.label, [Validators.required]],
            type: [this.customFieldData.type, [Validators.required]],
            inputType: [this.customFieldData.inputType],
            options: this.getOptionsArray()/* [[], [ValidateOptions]] */,
            validations: this.getValidationsArray(),
        });

        return form;
    }

    get OptionsFormArray(): UntypedFormArray {
        return <UntypedFormArray>this.customField.get('options');
    }

    getValidationsArray(): UntypedFormArray {
        const validations = this.fb.array([]);
        if (this.customFieldData.id) {
            this.customFieldData.validations.map((validation) => {
                validations.push(this.addValidationGroup(validation));
            });
        }
        return validations;
    }

    addValidationGroup(validation: ValidatorDto): UntypedFormGroup {
        const group = this.fb.group({
            type: [validation.type, [Validators.required]],
            message: [validation.message]
        });

        const validatorIndex = validatorsWithValue.findIndex((validator) =>  validator.value === validation.type);

        if (validatorIndex >= 0) {
            const control: UntypedFormControl = this.fb.control(validation.value, [Validators.required]);
            control.enable();
            group.addControl('value', control);
        }
        return group;
    }

    getOptionsArray(): UntypedFormArray {
        const options = this.fb.array([]);
        if (this.customFieldData.id && this.customFieldData.options) {
            this.customFieldData.options.map((option) => {
                options.push(this.addOptionsGroup(option));
            });
        } else {
            options.push(this.addOptionsGroup());
        }


        return options;
    }

    addOptionsGroup(field?: FieldValueDto) {
        if (!field) {
            field = new FieldValueDto();
        }

        const group = this.fb.group({
            text: [field.text || '', Validators.required],
            value: [field.value || '', Validators.required],
            metaValue: [field.metaValue || undefined],
            metaType: [field.metaType || undefined]
        }, {
            validators: this.metaValidation()
        });
        return group;
    }

    metaValidation() {
        return (formGroup: UntypedFormGroup) => {
            const metaType = formGroup.get('metaType');
            const metaValue = formGroup.get('metaValue');

            if (!!metaValue.value || (typeof metaType.value !== 'undefined' && metaType.value !== null)) {
                if (!metaValue.value) {
                    metaValue.setErrors({required: true});
                } else {
                    metaValue.setErrors(null);

                }

                if ((typeof metaType.value === 'undefined' || metaType.value === null)) {
                    metaType.setErrors({required: true});
                } else {
                    metaType.setErrors(null);
                }
            } else {
                metaValue.setErrors(null);

                metaType.setErrors(null);

            }

            return;
        };
    }

    private checkForm(type) {
        const inputType = this.customField.get('inputType');
        const options = this.customField.get('options');
        if (type === TagTypeDto.Input) {
            inputType.enable();
            inputType.setValidators([Validators.required]);
        } else {
            inputType.disable();
            inputType.clearValidators();
        }

        if (type === TagTypeDto.Select || type === TagTypeDto.RadioButton || type === TagTypeDto.MultiSelect) {
            options.enable();
            options.setValidators([Validators.required]);
        } else {
            options.disable();
            options.clearValidators();
        }
    }

    addOption() {
        (<UntypedFormArray>this.customField.get('options')).push(this.addOptionsGroup());
    }

    removeOption(index: number) {
        (<UntypedFormArray>this.customField.get('options')).removeAt(index);
    }

    addValidation(validationSelect: HTMLSelectElement) {
        if (validationSelect.value) {
            const validation: ValidatorDto = new ValidatorDto({
                type: (Number(validationSelect.value)),
                value: undefined,
                message: undefined
            });

            const validationsArray: UntypedFormArray = this.customField.get('validations') as UntypedFormArray;
            validationsArray.push(this.addValidationGroup(validation));
            this.validatorTypes = this.getValidatorsOptions();
        }
    }

    removeValidation(index: number) {
        const validations: UntypedFormArray = this.customField.get('validations') as UntypedFormArray;
        validations.removeAt(index);
        this.validatorTypes = this.getValidatorsOptions();
    }

    getTagTypes(): Map<string, TagTypeDto> {
        const tagTypes = new Map<string, TagTypeDto>();
        let tagTypeKeys = Object.keys(TagTypeDto);
        tagTypeKeys = tagTypeKeys.slice(tagTypeKeys.length / 2);
        tagTypeKeys.map((value) => {
            tagTypes.set(value, TagTypeDto[value]);
        });
        return tagTypes;
    }

    getInputTypes(): Map<string, InputTypeDto> {
        const inputTypes = new Map<string, InputTypeDto>();
        let inputTypeKeys = Object.keys(InputTypeDto);
        inputTypeKeys = inputTypeKeys.slice(inputTypeKeys.length / 2);
        inputTypeKeys.map((value) => {
            inputTypes.set(value, InputTypeDto[value]);
        });
        return inputTypes;
    }

    getValidatorsOptions(): Map<string, ValidatorTypeDto> {
        const validatorType: Map<string, ValidatorTypeDto> = new Map<string, ValidatorTypeDto>();
        let validatorTypeKey = Object.keys(ValidatorTypeDto);
        validatorTypeKey = validatorTypeKey.slice(validatorTypeKey.length / 2);
        validatorTypeKey.map((value) => {
            if (
                (this.customField.get('validations').value as Array<
                    ValidatorDto
                >).findIndex((validation) => validation.type === ValidatorTypeDto[value]) < 0
            ) {
                validatorType.set(value, ValidatorTypeDto[value]);
            }
        });
        return validatorType;
    }

    onSubmit() {
        if (this.customField.valid) {

            const validations: Array<ValidatorDto> = [];
            const validationsValues = this.customField.get('validations').value as Array<any>;
            validationsValues.map((validation) => {
                validations.push(new ValidatorDto({
                    type: validation.type,
                    value: (validation && (validation.value !== null || typeof validation.value !== 'undefined') ? validation.value : null),
                    message: validation.message
                }));
            });
            const customFieldData = new FieldConfigDto({
                id: this.customFieldData.id,
                name: this.customField.get('name').value,
                label: this.customField.get('label').value,
                inputType: this.customField.get('inputType').value,
                options: this.getOptionFieldValues(),
                type: this.customField.get('type').value,
                validations,
                values: this.customFieldData.values
            });

            this.onCustomFieldSubmit.emit(customFieldData);
        }
    }

    getOptionFieldValues(): Array<FieldValueDto> {
        const values: Array<FieldValueDto> = [];
        if (this.customField.get('options').enabled && (<UntypedFormArray>this.customField.get('options')).length > 0) {
            (this.customField.get('options').value as Array<FieldValueDto>).map(option => {
                values.push(new FieldValueDto({
                    text: option.text,
                    value: option.value,
                    metaType: option.metaType,
                    metaValue: option.metaValue
                }));
            });
        }

        return values;
    }

    getMetaTypeValues() {
        const metaType = MetaTypeDto;
        const values = [];
        let metaValues = Object.keys(metaType);
        metaValues = metaValues.slice(metaValues.length / 2);
        metaValues.map((metaTypeName) => {
            values.push({
                text: metaTypeName,
                value: metaType[metaTypeName]
            });
        });

        return values;
    }

    onValidationTouched() {
        const validations = this.customField.get('validations');
        if (!validations.touched) {
            validations.markAsTouched();
        }
    }

    trackByFn(index) {
        return index;
    }
}
