<div [busyIf]="isLoading" class="card card-custom h-100">
	<h3 class="card-title align-items-start flex-column m-2">
		<span class="font-weight-bolder text-dark">
			<i class="flaticon-tool-1"></i>
			{{ 'Link_Widget_Title' | localize }}
		</span>
	</h3>
	<div *ngIf="isGranted('Pages.Tenant.Dashboard.Update.Widgets.Data') || isGranted('Pages.Host.Dashboard.Update.Widgets.Data')">
		<div class="buttons-container m-2">
			<button class="btn btn-danger col-4" (click)="openConfirmDeleteDialog(null); $event.stopPropagation()">
				{{ 'Link_Widget_Remove_All_Links' | localize }}
				<i inline class="material-icons">remove</i>
			</button>
			<button class="btn btn-success col-4" (click)="triggerAddMode()">
				{{ 'Widget_Add' | localize }}
				<i *ngIf="!isOnAddMode" inline class="material-icons">add_link</i>
				<i *ngIf="isOnAddMode" inline class="material-icons">close</i>
			</button>
		</div>
		<div [@addLinkInput] *ngIf="isOnAddMode" class="mr-3 ml-3">
			<form [formGroup]="form" (ngSubmit)="addLink(addLinkForm.url.value, addLinkForm.alias.value)">
				<div class="input-group mb-2">
					<input type="url" formControlName="url" class="form-control" placeholder="{{ 'Link_Widget_AddLinkUrl' | localize }}" aria-describedby="basic-addon2" />
					<div class="input-group-append">
						<button class="btn btn-outline-secondary" [disabled]="!form.valid" type="submit">
							{{ 'Link_Widget_Add_Link' | localize }}
						</button>
					</div>
				</div>
				<div [@addLinkInput]>
					<input type="text" formControlName="alias" class="form-control" placeholder="{{ 'Link_Widget_LinkAlias' | localize }}" *ngIf="form.valid" />
				</div>
				<div [@addLinkInput] *ngIf="addLinkForm.url.invalid && addLinkForm.url.errors.pattern" class="alert alert-danger">
					<div>{{ 'Link_Widget_Url_Error' | localize }}</div>
				</div>
			</form>
		</div>
	</div>
	<div class="mt-4" @linksContainer *ngIf="this.widgetLinks && this.widgetLinks.elements && this.widgetLinks.elements.length > 0; else noLinksTemplate">
		<div @links class="text-center my-2 align-items-center" *ngFor="let link of this.widgetLinks.elements; let i = index">
			<!--Link card-->
			<button (click)="openLink(link)" type="button" class="btn btn-primary rounded-pill col-10">
				{{ formatLink(link) }}
				<i
					*ngIf="(isGranted('Pages.Tenant.Dashboard.Update.Widgets.Data') || isGranted('Pages.Host.Dashboard.Update.Widgets.Data')) && checkIfUserCanRemoveLink(link)"
					(click)="openConfirmDeleteDialog(i); $event.stopPropagation()"
					class="deleteIcon material-icons"
					>close</i
				>
			</button>
		</div>
	</div>
	<ng-template #noLinksTemplate>
		<div class="flex-grow-1 row justify-content-center align-items-center">
			<h6 class="card-title">
				{{ 'Link_Widget_No_Links_Uploaded_Yet' | localize }}
			</h6>
		</div>
	</ng-template>
</div>
<!-- Modale eliminazione singolo link -->
<ng-template #templateModal class="modal-dialog modal-lg">
	<div class="modal-content">
		<div class="modal-header">
			<h3 class="modal-title pull-left">{{ 'Link_Widget_Delete_Link' | localize }}</h3>
			<i class="warningIcon material-icons">warning</i>
		</div>
		<div class="modal-body text-center">
			<div style="white-space: pre" class="modal-text">
				{{ removingLinksString }}
			</div>
		</div>
		<div class="modal-footer">
			<button
				*ngIf="linkIndexToRemove !== null"
				type="button"
				class="btn btn-danger"
				data-dismiss="modal"
				(click)="removeLink(linkIndexToRemove); closeConfirmDeleteDialog()"
			>
				{{ 'Common_Delete' | localize }}
			</button>
			<button *ngIf="linkIndexToRemove === null" type="button" class="btn btn-danger" data-dismiss="modal" (click)="removeAllLinks(); closeConfirmDeleteDialog()">
				{{ 'Common_Delete' | localize }}
			</button>
			<button type="button" class="btn btn-light" (click)="closeConfirmDeleteDialog()">
				{{ 'Common_Close' | localize }}
			</button>
		</div>
	</div>
</ng-template>
