import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { formatMask } from '@app/shared/helpers/formatMask';
import { hasErrorReactive } from '@app/shared/helpers/telInputHasError';
import { MyTravelDocumentDto, MyUserProfileDto } from '@app/shared/models/client-user-profile-models';
import { KeyValuePair } from '@app/shared/models/ClientFlightModels';
import { CheckoutService } from '@app/shared/services/flight/checkout.service';
import { LocalMaskGreaterThanTodayDateValidator } from '@app/shared/validators/greater-than-today-date-validator';
import { NotEmptyFFFieldsValidator } from '@app/shared/validators/not-empty-ff-fields-validator';
import { ValidDateValidator } from '@app/shared/validators/valid-date-validator';
import { accountModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CountryListDto, CountryServiceProxy, CreateOrEditUserProfileInputDto, EmergencyContactDto, 
    FidelityCardDto, TravelDocumentDto, UserProfileDto, UserProfilesServiceProxy, FieldConfigDto, 
    CustomFieldDto, TagOfStringString, OrganizationUnitDto, CenterOfCostsServiceProxy, CompanyHQServiceProxy, AddressDto } from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';
import * as moment from 'moment';
import { SelectItem } from 'primeng';
import { debounceTime, finalize, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { groupBy, map } from 'lodash';
import { AppConsts } from '@shared/AppConsts';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
    animations: [accountModuleAnimation()]
})

export class UserProfileComponent extends AppComponentBase implements OnInit, OnChanges {
    @ViewChild('formUserProfileTemplate') formUserProfileTemplate: NgForm;

    /* Per l'init del componente ci sono 3 modalità con la priorità che segue se i flag corrispondenti sono specificati:
     * 1. createMode = true : il componente viene inizializzato vuoto
     * 2. userProfileInput != null : il componente viene inizializzato con i dati presenti nell'oggetto
     * 3. initByLoginUserId = true : il componente viene inizializzato con i dati recuperati dal profilo di viaggio, se esiste, dell'utente loggato
     * 4. se tutti i precedenti flag sono false o null, il componente viene inizializzato con i dati recuperati dal profilo di viaggio, se esiste, dell'utente loggato
     */

    @Input() createMode: boolean = false;
    @Input() userProfileInput: UserProfileDto;
    @Input() initByLoginUserId: boolean = false; // Se true indica che il componente può essere inizializzato tramite lo UserId dell'utente loggato se ha un profilo di viaggio associato
    @Input() showMinimalRegisterForm: boolean = false;
    @Input() showCancelButton: boolean = false;
    @Input() showHeader: boolean = true;
    @Input() saveUserProfile: boolean = true; //Se true interagisce con il repository degli userProfiles salvando i dati sul DB
    @Input() showSaveUserProfileInput: boolean = false;
    @Input() tripMode: boolean = false;

    @Output() closeModal = new EventEmitter();
    @Output() userProfileEmitter = new EventEmitter<UserProfileDto>();

    userProfile: UserProfileDto = new UserProfileDto();
    formUserProfile: UntypedFormGroup;
    isFidelityCardOpened = new Array<boolean>();
    isShowMoreInformationClosed: boolean = true;
    fidelityCodeList: KeyValuePair<string, KeyValuePair<string, string>>[];
    fidelityItemList: SelectItem[];
    countryList: CountryListDto[];
    modifyFidelityCardPrograms = new Array<boolean>();
    modifyTravelDocuments = new Array<boolean>();
    saving: boolean = false;
    vatNumberFormatConfirmed: boolean = true;
    telInput: any; // tipo non assegnabile. Il componente telInput è basato su javascript
    emergencyTelInput: any; // tipo non assegnabile. Il componente telInput è basato su javascript
    fieldConfig: FieldConfigDto[];
    customFieldSubmit: Subject<void>;
	centersOfCosts: { [id: number] : OrganizationUnitDto; };
	companyHQs: { [id: number] : OrganizationUnitDto; };
    selectedHQ: OrganizationUnitDto;
    constructor(
        injector: Injector,
        private formBuilder: UntypedFormBuilder,
        private countryServ: CountryServiceProxy,
        private userProfileProxy: UserProfilesServiceProxy,
        private cocService: CenterOfCostsServiceProxy,
        private hqService: CompanyHQServiceProxy,
        private checkoutService: CheckoutService) {
        super(injector);
        this.customFieldSubmit = new Subject();

        this.spinnerService.show();
        this.userProfileProxy.getFieldsConfig()
        .pipe(finalize(() => this.spinnerService.hide()))
        .subscribe((fieldConfig) => {
            this.fieldConfig = fieldConfig;
            this.setCustomFieldValue();
        });
    }

    displaySelectedCocText(centersOfCosts: { [id: number] : OrganizationUnitDto; }) {
        return (id: number) => {
            if(centersOfCosts && centersOfCosts[id]) {
                let coc = centersOfCosts[id];
                return `${coc.displayName} (${coc.codeUnit})`;
            }
            return undefined;
        };
    }

    displaySelectedCompanyHQText(companyHQs: { [id: number] : OrganizationUnitDto; }) {
        return (id: number) => {
            if(companyHQs && companyHQs[id]) {
                let hq = companyHQs[id];
                return `${hq.displayName} (${hq.codeUnit})`;
            }
            return undefined;
        };
    }

    uncheckSameValue(event, button)
    {
        let currentValue = this.formUserProfile.controls["gender"].value;
        if(currentValue === button.value)
        {
            this.formUserProfile.controls["gender"].setValue(null);
            event.preventDefault();
        }
    }

    ngOnInit() {
        // Init countryList
        this.spinnerService.show();
        this.countryServ.getAllCountries()
            .subscribe(res => {
                this.countryList = res;
                this.spinnerService.hide();
            }, () => {
                this.spinnerService.hide();
            });
        let userId = !this.userProfile?.userId ? null : parseInt(this.userProfile?.userId);
        // Init form
        if (this.createMode) {
            this.initUserProfileModel();
            this.initUserProfileForm(this.userProfile);
        }
        else if (this.userProfileInput) {
            this.userProfile = new MyUserProfileDto(this.userProfileInput);
            this.setCustomFieldValue();
            this.initUserProfileForm(this.userProfile);
        }
        else if (this.initByLoginUserId || (!this.createMode && this.userProfileInput === undefined)) {
            this.spinnerService.show();
            this.userProfileProxy
                .getUserProfileByUserId(abp.session.userId.toString())
                .pipe(finalize(() => {
                    this.spinnerService.hide();
                }))
                .subscribe(result => {
                    if (result && Object.keys(result).length > 0) {
                        this.userProfile = new MyUserProfileDto(result);
                        this.setCustomFieldValue();
                        // Inizializzo il form appena il risultato è disponibile
                        this.initUserProfileForm(this.userProfile);
                    }
                    this.spinnerService.hide();
                });
        }
        // Init fidelityCardList
        var airlines = this.checkoutService.companyCodeList.map(x => new KeyValuePair<string, KeyValuePair<string, string>>("airlines", x));
        var trains = this.checkoutService.trainCompanyCodeList.map(x => new KeyValuePair<string, KeyValuePair<string, string>>("train", x));
        var hotels = this.checkoutService.hotelCompanyCodeList.map(x => new KeyValuePair<string, KeyValuePair<string, string>>("hotel", x));
        this.fidelityItemList = new Array<SelectItem>();
        this.fidelityCodeList = airlines.concat(trains).concat(hotels);
        this.fidelityCodeList.forEach(element => {
            this.fidelityItemList.push({ value: element.value.key, label: element.value.value, styleClass: element.key });
        });

        if(this.tripMode) {
            this.formUserProfile.controls['centerOfCostsId'].disable();
            this.formUserProfile.controls['companyHeadquartersId'].disable();
        }
    }

    setCustomFieldValue() {
        if (this.fieldConfig && this.userProfile.selectedCustomFields && this.userProfile.selectedCustomFields.length > 0) {
            this.groupCustomFields().map((customField) => {
                const index = this.fieldConfig.findIndex(field => field.id === customField.id);
                if (index >= 0) {
                    this.fieldConfig[index].values = customField.values;
                }
            });
        }
    }

    private groupCustomFields() {
        const groupedCustomField = groupBy(this.userProfile.selectedCustomFields, (field) => field.id);
        const customFields = map(groupedCustomField, (el, index) => ({
            id: index,
            values: el.every((el) => el.field.value === null)
                ? null
                : map(el, (custom) => custom.field.value),
        }));

        return customFields;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.userProfileInput && changes.userProfileInput.currentValue && !changes.userProfileInput.isFirstChange) {
            if (!this.createMode) {
                this.userProfile = changes.userProfileInput.currentValue;
                this.setCustomFieldValue();
            } else {
                this.initUserProfileModel();
            }
            this.initUserProfileForm(this.userProfile);
        }
    }

    initUserProfileModel() {
        this.userProfile = _.cloneDeep(new MyUserProfileDto());
        this.setCustomFieldValue();
        this.userProfile.travelDocuments = new Array<TravelDocumentDto>();
        this.userProfile.fidelityCardPrograms = new Array<FidelityCardDto>();
        this.userProfile.emergencyContact = new EmergencyContactDto();
        this.userProfile.address = new AddressDto();
        this.userProfile.isPrivate = false;
    }

    onCompanyHqSelected(e: any) {
        this.selectedHQ = this.companyHQs[e.option.value];
        if(!this.tripMode) {
            this.formUserProfile.controls['centerOfCostsId'].enable();
        }
    }

    initUserProfileForm(userProfile: UserProfileDto) {
        if (userProfile) {
            // TravelDocuments
            const travelDocuments = this.formBuilder.array([]);
            if (userProfile.travelDocuments && userProfile.travelDocuments.length > 0) {
                this.userProfile.travelDocuments.forEach(travelDocument => {
                    const travelDocumentGroup = this.formBuilder.group(this.getTravelDocumentGroup(travelDocument));
                    travelDocuments.push(travelDocumentGroup);
                    this.modifyTravelDocuments.push(false);
                });
            }

            // FidelityCardPrograms
            const fidelityCardPrograms = this.formBuilder.array([]);
            if (userProfile.fidelityCardPrograms && userProfile.fidelityCardPrograms.length > 0) {
                this.userProfile.fidelityCardPrograms.forEach(fidelityCardProgramObj => {
                    const fidelityCardProgram = this.formBuilder.group({
                        membershipId: [fidelityCardProgramObj.membershipId, [NotEmptyFFFieldsValidator('programId'), Validators.required]],
                        programId: [fidelityCardProgramObj.programId, [NotEmptyFFFieldsValidator('membershipId'), Validators.required]]
                    });
                    fidelityCardPrograms.push(fidelityCardProgram);
                    this.isFidelityCardOpened.push(true);
                    this.modifyFidelityCardPrograms.push(false);
                });
            }

            if (!this.userProfile.address) {
                this.userProfile.address = new AddressDto();
            }
            // EmergencyContact
            if (!this.userProfile.emergencyContact) {
                this.userProfile.emergencyContact = new EmergencyContactDto();
            }
            //Nationality
            if (!this.userProfile.nationality) {
                this.userProfile.nationality = this.initUserNation();
            }
            
            // Init form
            this.formUserProfile = this.formBuilder.group({
                isPrivate: [userProfile.isPrivate],
                username: [userProfile.username],
                givenName: [userProfile.givenName, Validators.required],
                surname: [userProfile.surname, Validators.required],               
                userEmail: [userProfile.userEmail],
                documentMail: [userProfile.documentMail],
                birthDate: [userProfile.birthDate ? moment(userProfile.birthDate, 'YYYY-MM-DD').format(formatMask()) : null, (this.showMinimalRegisterForm ? [] : [ValidDateValidator(true)])],
                gender: [userProfile.gender],
                phoneNumber: [userProfile.phoneNumber, Validators.required],
                fiscalCode: [userProfile.fiscalCode],
                specialNeeds: [userProfile.specialNeeds],
                nationality: [userProfile.nationality, Validators.required],
                businessName: [userProfile.businessName],
                vatNumber: [userProfile.vatNumber],
                emergencyContact: this.formBuilder.group({
                    name: [this.userProfile.emergencyContact.name],
                    surname: [this.userProfile.emergencyContact.surname],
                    phoneNumber: [this.userProfile.emergencyContact.phoneNumber]
                }),
                travelDocuments: travelDocuments,
                fidelityCardPrograms: fidelityCardPrograms,
                workId: [userProfile.workId],
                centerOfCostsId: [userProfile.centerOfCostsId],
                companyHeadquartersId: [userProfile.companyHeadquartersId],
                address: this.formBuilder.group({
                    address1: [this.userProfile.address.address1],
                    city: [this.userProfile.address.city],
                    province: [this.userProfile.address.province],
                    zip: [this.userProfile.address.zip],
                    country: [this.userProfile.address.country],
                }),                
            });
            // Init email validation
            this.setFormEmailField();
            
            this.centersOfCosts = {};
            this.companyHQs = {};

            if(this.isGranted('Pages.Trips.CenterOfCosts')) {
                if(this.userProfile.companyHeadquartersId) {
                    this.hqService.getCompanyHQ(this.userProfile.companyHeadquartersId)
                        .subscribe(x => {
                            this.companyHQs[x.id] = this.selectedHQ = x;
                            this.formUserProfile.controls['companyHeadquartersId'].setValue(x.id);
                        });
                }
                this.formUserProfile.controls['centerOfCostsId'].disable();
                if(this.userProfile.centerOfCostsId) {
                    this.cocService.getCenterOfCosts(this.userProfile.centerOfCostsId)
                        .subscribe(x => {
                            this.centersOfCosts[x.id] = x;
                            if(!this.tripMode) {
                                this.formUserProfile.controls['centerOfCostsId'].enable();
                            }
                            this.formUserProfile.controls['centerOfCostsId'].setValue(x.id);
                        });
                }
                if(!this.tripMode) {
                    this.formUserProfile.controls['companyHeadquartersId'].valueChanges
                        .pipe(tap(x => {
                            if(x && this.selectedHQ) {
                                this.formUserProfile.controls['centerOfCostsId'].enable();
                            }
                            else {
                                this.selectedHQ = null;
                                this.centersOfCosts = {};
                                this.formUserProfile.controls['centerOfCostsId'].setValue(undefined);
                                this.formUserProfile.controls['centerOfCostsId'].disable();
                            }
                        }))
                        .pipe(debounceTime(500))
                        .subscribe(x => {
                            if (!!x && x.length > 2) {
                                this.hqService.getCompanyHQsAutocomplete(x, undefined)
                                    .subscribe(data => this.companyHQs = data.items.reduce((d, x) => ({...d, [x.id]: x}), {}));
                            }
                        });
                    this.formUserProfile.controls['centerOfCostsId'].valueChanges
                        .pipe(debounceTime(500))
                        .subscribe(x => {
                            if (!!x && x.length > 2) {
                                let hqId = this.formUserProfile.controls['companyHeadquartersId'].value;
                                this.cocService.getCentersOfCostsAutocomplete(x, hqId, undefined)
                                    .subscribe(data => this.centersOfCosts = data.items.reduce((d, x) => ({...d, [x.id]: x}), {}));
                            }
                        });
                }
            }
        }
    }

    initUserNation() {
        return abp.localization.currentLanguage.name.toUpperCase() === 'EN' ? 'GB' : abp.localization.currentLanguage.name.toUpperCase();
    }

    setFormEmailField() {
        if (this.saveUserProfile) {
            this.formUserProfile.controls['userEmail'].setValidators([Validators.required, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{1,})+$')]);
            this.formUserProfile.controls['documentMail'].setValidators([Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{1,})+$')]);
        } else {
            this.formUserProfile.controls['userEmail'].clearValidators();
            this.formUserProfile.controls['documentMail'].clearValidators();
        }
        this.formUserProfile.controls['userEmail'].updateValueAndValidity();
        this.formUserProfile.controls['documentMail'].updateValueAndValidity();
    }

    getTravelDocumentGroup(travelDocument : TravelDocumentDto) : object {
        return {
            isDefault: [this.userProfile.travelDocuments.length === 0 ? true : false],
            documentType: [travelDocument.documentType, Validators.required],
            documentNumber: [travelDocument.documentNumber, Validators.required],
            expirationDate: [moment(travelDocument.expirationDate, 'YYYY-MM-DD').format(formatMask()), [Validators.required, LocalMaskGreaterThanTodayDateValidator()]],
            nationalityCountry: [travelDocument.nationalityCountry, Validators.required],
            issueCountry: [travelDocument.issueCountry, Validators.required],
            issuingDate: [moment(travelDocument.issuingDate, 'YYYY-MM-DD').format(formatMask()), [Validators.required, ValidDateValidator()]]
        };
    }

    addTravelDocument() {
        this.modifyTravelDocuments.push(true);
        const travelDocument = new TravelDocumentDto();
        const travelDocumentGroup = this.formBuilder.group(this.getTravelDocumentGroup(travelDocument));
        (this.formUserProfile.controls['travelDocuments'] as UntypedFormArray).push(travelDocumentGroup);
    }

    removeTravelDocument(index: number) {
        this.modifyTravelDocuments.splice(index, 1);
        (this.formUserProfile.controls['travelDocuments'] as UntypedFormArray).removeAt(index);
    }

    modifyTravelDocument(index: number) {
        this.modifyTravelDocuments[index] = true;
    }

    undoPendingChangesTravelDocument(index: number) {
        if (this.userProfile.travelDocuments[index]) {
            const travelDocumentGroup = {
                isDefault: this.userProfile.travelDocuments[index].isDefault ? true : false,               
                documentType: this.userProfile.travelDocuments[index].documentType,
                documentNumber: this.userProfile.travelDocuments[index].documentNumber,
                expirationDate: moment(this.userProfile.travelDocuments[index].expirationDate, 'YYYY-MM-DD').format(formatMask()),
                nationalityCountry: this.userProfile.travelDocuments[index].nationalityCountry,
                issueCountry: this.userProfile.travelDocuments[index].issueCountry,
                issuingDate: moment(this.userProfile.travelDocuments[index].issuingDate, 'YYYY-MM-DD').format(formatMask())                
            };
            (this.formUserProfile.controls['travelDocuments'] as UntypedFormArray).at(index).patchValue(travelDocumentGroup);
            this.modifyTravelDocuments[index] = false;
        } else {
            this.removeTravelDocument(index);
        }
    }

    completeAddTravelDocument(index: number) {
        if ((this.formUserProfile.controls['travelDocuments'] as UntypedFormArray).controls[index].valid) {
            if ((((this.formUserProfile.controls['travelDocuments'] as UntypedFormArray).controls[index] as UntypedFormGroup).controls['isDefault'].value === true
                && this.userProfile.travelDocuments[index]
                && ((this.formUserProfile.controls['travelDocuments'] as UntypedFormArray).controls[index] as UntypedFormGroup).controls['isDefault'].value !== this.userProfile.travelDocuments[index].isDefault)
                ||
                (((this.formUserProfile.controls['travelDocuments'] as UntypedFormArray).controls[index] as UntypedFormGroup).controls['isDefault'].value === true && this.userProfile.travelDocuments[index] === undefined)
            ) {
                for (let i = 0; i < (this.formUserProfile.controls['travelDocuments'] as UntypedFormArray).length; i++) {
                    if (i !== index) {
                        (this.formUserProfile.controls['travelDocuments'] as UntypedFormArray).at(i).patchValue({ isDefault: false });
                    }
                }
            }
            this.modifyTravelDocuments[index] = false;
        }
    }

    addFidelityCardProgram() {
        this.isFidelityCardOpened.push(true);
        this.modifyFidelityCardPrograms.push(true);
        const fidelityCardObj = new FidelityCardDto();
        const fidelityCardProgram = this.formBuilder.group({
            membershipId: [fidelityCardObj.membershipId, [NotEmptyFFFieldsValidator('programId'), Validators.required]],
            programId: [fidelityCardObj.programId, [NotEmptyFFFieldsValidator('membershipId'), Validators.required]]
        });
        (this.formUserProfile.controls['fidelityCardPrograms'] as UntypedFormArray).push(fidelityCardProgram);
    }

    removeFidelityCardProgram(index: number) {
        this.isFidelityCardOpened.splice(index, 1);
        this.modifyFidelityCardPrograms.splice(index, 1);
        (this.formUserProfile.controls['fidelityCardPrograms'] as UntypedFormArray).removeAt(index);
    }

    modifyFidelityCardProgram(index: number) {
        this.isFidelityCardOpened[index] = true;
        this.modifyFidelityCardPrograms[index] = true;
    }

    undoPendingChangesFidelityCardProgram(index: number) {
        if (this.userProfile.fidelityCardPrograms[index]) {
            const fidelityCardObj = this.userProfile.fidelityCardPrograms[index];
            (this.formUserProfile.controls['fidelityCardPrograms'] as UntypedFormArray).at(index).patchValue(fidelityCardObj);
            this.modifyFidelityCardPrograms[index] = false;
        } else {
            this.removeFidelityCardProgram(index);
        }
    }

    completeAddFidelityCardProgram(index: number) {
        if ((this.formUserProfile.controls['fidelityCardPrograms'] as UntypedFormArray).controls[index].valid) {
            this.modifyFidelityCardPrograms[index] = false;
        }
    }

    save() {
        if (this.fieldConfig && this.fieldConfig.length > 0 && !this.showMinimalRegisterForm) {
            //send submit event to custom field form
            this.customFieldSubmit.next();
        }

        if (this.formUserProfile.valid && ((this.fieldConfig && this.fieldConfig.length > 0 && !this.showMinimalRegisterForm) ? (this.userProfile.selectedCustomFields && this.userProfile.selectedCustomFields.length > 0) : true)) {
            const modelForm = _.cloneDeep(this.formUserProfile.value);
            const modelServer = new MyUserProfileDto(modelForm);
            modelServer.birthDate = this.showMinimalRegisterForm ? null : (modelForm.birthDate ? moment(modelForm.birthDate, 'L') : null);
            modelServer.emergencyContact = new EmergencyContactDto(modelForm.emergencyContact);
            
            modelServer.address = new AddressDto(modelForm.address);
            this.modifyTravelDocuments.forEach((boolModify, index) => this.modifyTravelDocuments[index] = false);
            this.modifyFidelityCardPrograms.forEach((boolModify, index) => this.modifyFidelityCardPrograms[index] = false);
            modelServer.travelDocuments = new Array<MyTravelDocumentDto>();
            modelServer.fidelityCardPrograms = new Array<FidelityCardDto>();
            modelForm.travelDocuments = modelForm.travelDocuments as Array<MyTravelDocumentDto>;
            modelForm.travelDocuments.forEach(x => {
                x.issuingDate = moment(x.issuingDate, formatMask());
                x.birthDate = moment(x.birthDate, 'L');
                x.expirationDate = moment(x.expirationDate, 'L');
                modelServer.travelDocuments.push(new MyTravelDocumentDto(x));
            });
            modelForm.fidelityCardPrograms.forEach(ff => {
                ff = ff as FidelityCardDto;
                modelServer.fidelityCardPrograms.push(new FidelityCardDto(ff));
            });
            if (this.saveUserProfile) {
                this.saving = true;
                const userProfileCopy = _.cloneDeep(this.userProfile);
                for (const prop in modelForm) {
                    if (prop) {
                        userProfileCopy[prop] = modelServer[prop];
                    }
                }
                userProfileCopy.creatorId = abp.session.userId + '';
                userProfileCopy.isActive = true;
                const model = new CreateOrEditUserProfileInputDto();
                model.userProfile = userProfileCopy;
                this.spinnerService.show();
                this.userProfileProxy
                    .createOrUpdateUserProfile(false, model)
                    .subscribe(res => {
                        this.userProfile = _.cloneDeep(res);
                        this.setCustomFieldValue();
                        this.initUserProfileForm(this.userProfile);
                        this.spinnerService.hide();
                        this.notify.success(this.l('OperationSuccessNotification'));
                        this.closeModal.emit();
                        this.userProfileEmitter.emit(this.userProfile);
                        this.saving = false;
                    }, () => {
                        this.spinnerService.hide();
                        this.saving = false;
                    });
            } else {
                this.saving = true;
                this.userProfileEmitter.emit(modelServer);
                this.closeModal.emit();
                this.saving = false;
            }
        } else {
            if (this.customFieldSubmit.error.length > 0) {                
                this.message.error(this.l('UserProfile_Fill_CustomFields_Error_Message'));
            }
        }
    }

    formatMask(): string {
        return formatMask();
    }

    validateDate(event, formName: string) {
        if (!moment(this.formUserProfile.controls[formName].value, 'L').isValid()) {
            (this.formUserProfile.controls[formName] as UntypedFormControl).setValue(null);
            event.target.value = '';
        }
    }

    hasPhoneError(event, formControl: AbstractControl) {
        hasErrorReactive(event, formControl);
    }

    getLogoFidelityCard(type: string, filename: string) {
        if (filename && type) {
            const filenameLower = filename ? filename.toUpperCase() : filename;
            try {
                if(type === 'airlines'){
                    return AppConsts.cdnBaseUrl + `/images/${type}/` + filenameLower + '_SM.png';
                }
                else if (type === 'train'){
                    return AppConsts.cdnBaseUrl + `/svg/${type}/trenitalia.svg`;
                }
                else {
                    return '';
                }
            } catch (e) {
                return '';
            }
        } else {
            return '';
        }
    }

    close() {
        this.closeModal.emit();
    }

    setVatNumberConfirmed(event: boolean) {
        this.vatNumberFormatConfirmed = event;
    }

    getPhoneValue(e) {
        this.formUserProfile.controls['phoneNumber'].setValue(e);
        this.telInput.setNumber(e);
    }

    getPhoneIstance(e) {
        this.telInput = e;
    }

    getEmergencyPhoneValue(e) {
        (this.formUserProfile.controls['emergencyContact'] as UntypedFormGroup).controls['phoneNumber'].setValue(e);
        this.emergencyTelInput.setNumber(e);
    }

    getEmergencyPhoneIstance(e) {
        this.emergencyTelInput = e;
    }

    trackByFn(index, row) {
        return index;
    }

    updateCountry(e) {
        (this.formUserProfile.controls['address'] as UntypedFormGroup).controls['country'].setValue(e);
    }

    updateSelectedCountryInModel(event) {
        this.userProfile.nationality = event;
        this.formUserProfile.controls['nationality'].setValue(
            this.userProfile.nationality
        );
        this.formUserProfile.updateValueAndValidity();
    }

    onCustomFieldSubmit(customFields: Array<FieldConfigDto>) {
        let fields: Array<CustomFieldDto>;
        if (customFields) {
            fields = [];
            customFields.map(field => {
                if (Array.isArray(field.values)) {
                    field.values.map((value) => {
                        fields.push(new CustomFieldDto({
                            field: new TagOfStringString({
                                name: field.name,
                                value: value
                            }),
                            id: field.id
                        }));
                    });
                } else {
                    fields.push(
                        new CustomFieldDto({
                            field: new TagOfStringString({
                                name: field.name,
                                value: null,
                            }),
                            id: field.id,
                        })
                    );
                }

            });
        } else {
            fields = undefined;
        }

        this.userProfile.selectedCustomFields = fields;
    }

    // Fix controls in Reactive Form, per compilazione stretta in "angularCompilerOptions" "strictInjectionParameters"
    checkformtravelDocuments(form) {
        return form.controls['travelDocuments'].controls;
    }
    checkformfrequentFlyerPrograms(form) {
        return form.controls['frequentFlyerPrograms'].controls;
    }
    checkformhotelFidelityCardPrograms(form) {
        return form.controls['hotelFidelityCardPrograms'].controls;
    }
    checkformtrainFidelityCardPrograms(form) {
        return form.controls['trainFidelityCardPrograms'].controls;
    }
    checkformHotelphoneNumber(form) {
        return form.controls['emergencyContact'].controls['phoneNumber'];
    }
    checkformAddressCountry(form) {
        return form.controls['address'].controls['country'];
    }
}