import {BaseService} from "../base-service";
import {ApiConfiguration} from "../api-configuration";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {RequestBuilder} from "@app/api/request-builder";
import {filter, map} from "rxjs/operators";
import {Observable} from "rxjs";
import {AjaxResponseOfCo2Dto} from "@app/api/models/ajax-response-of-co2-dto";
import {StrictHttpResponse} from "@app/api/strict-http-response";

@Injectable({
    providedIn: 'root',
})
export class Co2Service extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    static readonly GetCo2_Path = '/Co2Service/GetCo2Data'

    getCo2(params: {
        part: string,
        dest: string
    }): Observable<AjaxResponseOfCo2Dto> {
        const rb = new RequestBuilder(this.rootUrl, Co2Service.GetCo2_Path, 'get');

        rb.query('cityPart', params.part);
        rb.query('cityDest', params.dest);

        return this.http.request(rb.build({
            responseType: 'json',
            accept: 'application/json'
        })).pipe(
            filter((r: any) => r instanceof HttpResponse),
            map((r: StrictHttpResponse<AjaxResponseOfCo2Dto>) => r.body as AjaxResponseOfCo2Dto)
        )
    }
}
