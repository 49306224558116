import { Injectable, Injector } from '@angular/core';
import {
    PassengerTypeDTO
} from '@shared/service-proxies/service-proxies';
import {
    FlightClassType,
    Passenger
} from '@app/shared/models/ClientFlightModels';

import {flightPaxType, SelectionType } from '@shared/AppBusiness';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';

@Injectable()
export class FlightPassengerService extends AppComponentBase {
    private _selectionType;
    arrayPax: Array<Passenger> = new Array<Passenger>();
    arrayAdvancedPax: Array<Passenger> = new Array<Passenger>();
    flightClasses: FlightClassType;
    constructor(private injector: Injector) {
        super(injector);
        this.createDefaultPassengerList();
        this.createAdvancedPassengerList();
        this.flightClasses = new FlightClassType();
        this._selectionType = AppConsts.configPax.selectionType;
    }
    public get SelectionType() {
        return this._selectionType;
    }

    public set SelectionType(value) {
        this._selectionType = value;
          }
    //metodi per array preselezione di default
    createDefaultPassengerList(
        adt: number = 1,
        chd: number = 0,
        inf: number = 0
    ) {
        this.arrayPax.push(new Passenger(flightPaxType.ADT, '', adt));
        this.arrayPax.push(new Passenger(flightPaxType.CHD, '', chd));
        this.arrayPax.push(new Passenger(flightPaxType.INF, '', inf));
    }

    setDefaultPassengerList(paxComposition: Array<PassengerTypeDTO>) {
        paxComposition.forEach(item => {
            this.arrayPax.find(pax => pax.type === item.code).num = item.quantity;
        });
    }

    setDefaultPaxNumber(type: flightPaxType, num: number) {
        this.arrayPax.find(function(item: Passenger) {
            return item.type === type;
        }).num = num;
    }
    //metodi per la gestione selezione passeggeri avanzata
    createAdvancedPassengerList() {
        Object.keys(flightPaxType).forEach(k => this.arrayAdvancedPax.push(new Passenger(<flightPaxType>flightPaxType[k as any], this.l(flightPaxType[k as any]))));
    }

    setAdvancedPaxNumber(type: flightPaxType, num: number) {
        this.arrayAdvancedPax.find(function(item: Passenger) {
            return item.type === type;
        }).num = num;
    }
}
