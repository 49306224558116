import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemoveNgxSpinnerDirective } from './remove-ngx-spinner.directive';



@NgModule({
  declarations: [RemoveNgxSpinnerDirective],
  imports: [
    CommonModule
  ],
  exports: [RemoveNgxSpinnerDirective]
})
export class RemoveNgxSpinnerModule { }
