import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageCustomFieldsComponent } from './manage-custom-fields.component';
import { EditCustomFieldComponent } from './edit-custom-field/edit-custom-field.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@shared/utils/utils.module';
import { GetValidationNamePipe } from './pipes/get-validation-name.pipe';
import { InputTypeNamePipe } from './pipes/input-type-name.pipe';
import { TagTypeNamePipe } from './pipes/tag-type-name.pipe';
import { ValidatorHasValuePipe } from './pipes/validator-has-value.pipe';
import { ReactiveValidationMessageModule } from '../reactive-validation-message/reactive-validation-message.module';
import { ContextControlNamePipe } from './pipes/context-control-name.pipe';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CompanySettingsService } from '@app/corporate/company-settings/company-settings.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilsModule,
        MatButtonModule,
        MatIconModule,
        ReactiveValidationMessageModule,
        MatCardModule,
        TooltipModule
    ],
    declarations: [
        ManageCustomFieldsComponent,
        EditCustomFieldComponent,
        GetValidationNamePipe,
        InputTypeNamePipe,
        TagTypeNamePipe,
        ValidatorHasValuePipe,
        ContextControlNamePipe
    ],
    providers: [CompanySettingsService],
    exports: [ManageCustomFieldsComponent, EditCustomFieldComponent, GetValidationNamePipe],
})
export class ManageCustomFieldsModule {}
