import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { formatMask } from '@app/shared/helpers/formatMask';
import * as moment from 'moment';

export function ValidDateValidator(allowNull: boolean = false): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
        if (form.value) {
            let value_ = form.value as string;
            return moment(value_, formatMask()).isValid() ? null : {validDate: {}};
        }
        else {
            if(allowNull)
                return null;
            
            return {required: {}};
        }
    };
}
