import { Injectable } from '@angular/core';
import { IWhatsAppOptions, ITelegramOptions, IMessengerOptions, ILineOptions } from '@shared/interfaces/social-share-options.interface';
import { whatsapp, telegram, messenger, line } from 'vanilla-sharing';

@Injectable()
export class SocialShareService {

    /**
     * Method for share things on WhatsApp
     * @param options param for share content on whatsapp
     */
    shareOnWhatsApp(options: IWhatsAppOptions) {
        whatsapp(options);
    }

    /**
     * Method for share things on Telegram
     * @param options param for share content on telegram
     */
    shareOnTelegram(options: ITelegramOptions) {
        telegram(options);
    }

    /**
     * Method for share things on Facebook Messenger
     * @param options param for share content on Facebook Messenger
     */
    shareOnMessenger(options: IMessengerOptions) {
        if (options.fbAppId) {
            messenger(options);
        }else {
            return window.location.assign('fb-messenger://share?link=' + options.url);
        }
    }

    /**
     * Method for share things on Line
     * @param options param for share content on Line
     */
    shareOnLine(options: ILineOptions) {
        line(options);
    }
}
