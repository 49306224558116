<div class="card card-custom kt-portlet--height-fluid" [busyIf]="isLoading">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">
                {{'WidgetSalesByAgency' | localize}}
            </h3>
        </div>
    </div>
    <div class="card-body" [perfectScrollbar]>
        <div class="kt-widget14 p-0">
            <div class="kt-widget14__content">

                <ngx-charts-bar-horizontal *ngIf="data?.length > 0" [results]="data" [legend]="true" [animations]="true"
                    [xAxis]="true" [yAxis]="true" [xAxisTickFormatting]="formatXAxis"
                    [legendTitle]="'Common_Legend' | localize">
                    <ng-template #tooltipTemplate let-model="model">
                        <div class="p-1">
                            <h6>{{model.name}}</h6>
                            <h4>€ {{model.value}}</h4>
                        </div>
                    </ng-template>
                </ngx-charts-bar-horizontal>



                <div class="text-center chart" [hidden]="data?.length > 0">
                    <small class="text-muted">{{"NoData" | localize}}</small>
                </div>
            </div>
        </div>
    </div>
</div>
