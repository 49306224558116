import { Component, OnInit } from '@angular/core';
import { NgxSpinnerTextService } from '@app/shared/ngx-spinner-text.service';
import { Title, Meta } from '@angular/platform-browser';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { capitalizeFirstLetter } from '@shared/helpers/capitalizeFirstLetter';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PwaService } from '@app/shared/services/pwa/pwa.service';
import { InstallPwaPromptComponent } from '@app/shared/components/install-pwa-prompt/install-pwa-prompt.component';
import { MyMonitoringService } from '@shared/services/logging.service';
import { AppConsts } from '@shared/AppConsts';

@Component({
    selector: 'app-root',
    template: `
        <router-outlet></router-outlet>
        <ngx-spinner type="ball-clip-rotate" size="medium" color="#5ba7ea">
            <p *ngIf="ngxSpinnerText">{{ getSpinnerText() }}</p>
        </ngx-spinner>
        <app-pwa-update-modal></app-pwa-update-modal>
    `,
})
export class RootComponent implements OnInit {
    ngxSpinnerText: NgxSpinnerTextService;
    installPrompt;
    showed = false;

    constructor(
        _ngxSpinnerText: NgxSpinnerTextService,
        private titleService: Title,
        private appSessionService: AppSessionService,
        private snackBar: MatSnackBar,
        private metaService: Meta,
        private pwaService: PwaService,
        private monitorningService: MyMonitoringService,
    ) {
        this.ngxSpinnerText = _ngxSpinnerText;
        this.getTitle();

        if (AppConsts.instrumentationKey) {
            monitorningService.loadAppInsights();
            monitorningService.logPageView();
        }

        this.pwaService.showInstallPrompt.subscribe(() => {
            let snackbarRef = this.snackBar.openFromComponent(
                InstallPwaPromptComponent,
                {
                    duration: 600000,
                    panelClass: 'install-prompt',
                }
            );
            snackbarRef.instance.matSnackbarRef = snackbarRef;
            snackbarRef.onAction().subscribe(() => {
                this.showPrompt();
            });
        });

        //google map api initializer
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?v=quarterly&key=${abp.setting.get(
            'GoogleMaps:ApiKey'
        )}&libraries=places&language=${
            abp.localization.currentLanguage.name || 'en'
        }`;
        document.head.appendChild(script);
    }

    ngOnInit(): void {
        if (AppConsts.instrumentationKey && this.appSessionService.user) {
            this.monitorningService.setUserId(this.appSessionService.user.id as any, this.appSessionService.user.name);
        }
    }

    getTitle() {
        if (
            this.appSessionService.tenant &&
            typeof this.appSessionService.tenant.name !== 'undefined' &&
            typeof this.appSessionService.tenant.tenancyName !== 'undefined'
        ) {
            let title = `${
                this.appSessionService.tenant.tenancyName
            } - ${capitalizeFirstLetter(this.appSessionService.tenant.name)}`;
            this.titleService.setTitle(title);
            this.metaService.updateTag(
                { content: title },
                "property='og:title'"
            );
            this.metaService.updateTag(
                { content: title },
                "name='apple-mobile-web-app-title'"
            );
            this.metaService.updateTag(
                { content: title },
                "name='application-name'"
            );
            this.metaService.updateTag({
                name: 'theme-color',
                content: getComputedStyle(
                    document.documentElement
                ).getPropertyValue('--primary'),
            });
        }
    }

    showPrompt() {
        this.pwaService.installPrompt.prompt();
    }

    getSpinnerText(): string {
        return this.ngxSpinnerText.getText();
    }
}
