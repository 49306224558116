import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarChartComponent } from './calendar-chart.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CalendarChartMonthLineDirective } from './directives/calendar-chart-month-line.directive';
import { GhostModule } from '@app/shared/ghost/ghost.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    TooltipModule.forRoot(),
    GhostModule
  ],
  declarations: [CalendarChartComponent, CalendarChartMonthLineDirective],
  exports: [CalendarChartComponent]
})
export class CalendarChartModule { }
