import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Data, CanActivateChild, CanLoad, Route } from '@angular/router';
import { FeatureCheckerService } from 'abp-ng2-module';

interface IFeatureGuardData extends Data {
    feature?: string;
    enabled?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class AppFeatureGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(private featureChecker: FeatureCheckerService) {}

    canActivateInternal(data: IFeatureGuardData): boolean {
        if (data && data.feature) {
            return data.enabled ? this.featureChecker.isEnabled(data.feature)
                : !this.featureChecker.isEnabled(data.feature);
        }
    }

    canActivate(next: ActivatedRouteSnapshot): boolean {
        return this.canActivateInternal(next.data);
    }

    canActivateChild(next: ActivatedRouteSnapshot): boolean {
        return this.canActivateInternal(next.data);
    }

    canLoad(route: Route): boolean {
        return this.canActivateInternal(route.data);
    }
}
