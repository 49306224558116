import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstallPwaPromptComponent } from './install-pwa-prompt.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatSnackBarModule,
        MatIconModule
    ],
    declarations: [InstallPwaPromptComponent],
    exports: [InstallPwaPromptComponent]
})
export class InstallPwaPromptModule { }
