
export enum SelectionType {
    default = 'default',
    advanced = 'advanced'
}

export enum flightPaxType {
    ADT = 'ADT',
    ADR = 'ADR',
    ASB = 'ASB',
    BNN = 'BNN',
    AGT = 'AGT',
    ADN = 'ADN',
    ADA = 'ADA',
    UDN = 'UDN',
    UDA = 'UDA',
    BUD = 'BUD',
    AST = 'AST',
    NSB = 'NSB',
    BAG = 'BAG',
    CCH = 'CCH',
    CCM = 'CCM',
    CFM = 'CFM',
    CEV = 'CEV',
    CDT = 'CDT',
    MCS = 'MCS',
    PCR = 'PCR',
    PNN = 'PNN',
    YCR = 'YCR',
    CSB = 'CSB',
    YTH = 'YTH',
    YSB = 'YSB',
    UNN = 'UNN',
    CNN = 'CNN',
    ANN = 'ANN',
    CMP = 'CMP',
    CMA = 'CMA',
    JCB = 'JCB',
    JNN = 'JNN',
    JNF = 'JNF',
    CPN = 'CPN',
    CNT = 'CNT',
    ICP = 'ICP',
    STU = 'STU',
    SDB = 'SDB',
    TEA = 'TEA',
    UNV = 'UNV',
    EDT = 'EDT',
    ECH = 'ECH',
    ENF = 'ENF',
    WEB = 'WEB',
    DNN = 'DNN',
    OEB = 'OEB',
    OEC = 'OEC',
    OEI = 'OEI',
    OEP = 'OEP',
    JEB = 'JEB',
    JEC = 'JEC',
    JEP = 'JEP',
    MEP = 'MEP',
    AEP = 'AEP',
    SEP = 'SEP',
    HOF = 'HOF',
    ACC = 'ACC',
    SPH = 'SPH',
    FIF = 'FIF',
    FNN = 'FNN',
    FNF = 'FNF',
    SPA = 'SPA',
    SPS = 'SPS',
    FFY = 'FFY',
    FFP = 'FFP',
    TNN = 'TNN',
    GCT = 'GCT',
    GCF = 'GCF',
    GDP = 'GDP',
    LTC = 'LTC',
    GEX = 'GEX',
    GVT = 'GVT',
    YNN = 'YNN',
    GST = 'GST',
    MSG = 'MSG',
    GV1 = 'GV1',
    GRP = 'GRP',
    GNN = 'GNN',
    GIT = 'GIT',
    ENN = 'ENN',
    GSP = 'GSP',
    VAG = 'VAG',
    ZNN = 'ZNN',
    BLD = 'BLD',
    DIS = 'DIS',
    MED = 'MED',
    INY = 'INY',
    INS = 'INS',
    INF = 'INF',
    LBR = 'LBR',
    LNN = 'LNN',
    LIF = 'LIF',
    MRE = 'MRE',
    MBT = 'MBT',
    MIL = 'MIL',
    MCR = 'MCR',
    MNN = 'MNN',
    MPA = 'MPA',
    REC = 'REC',
    MIR = 'MIR',
    MSB = 'MSB',
    MUS = 'MUS',
    MXS = 'MXS',
    MDP = 'MDP',
    NEG = 'NEG',
    CNE = 'CNE',
    INE = 'INE',
    PFA = 'PFA',
    CBC = 'CBC',
    CBI = 'CBI',
    CLG = 'CLG',
    MIC = 'MIC',
    MIF = 'MIF',
    MSS = 'MSS',
    MIS = 'MIS',
    SNN = 'SNN',
    SRC = 'SRC',
    YCB = 'YCB',
    SCC = 'SCC',
    ITX = 'ITX',
    ITF = 'ITF',
    ITS = 'ITS',
    INN = 'INN',
    TUX = 'TUX',
    TUR = 'TUR',
    VAC = 'VAC',
    VNN = 'VNN',
    VFR = 'VFR',
    ASF = 'ASF',
    BRV = 'BRV',
    CTZ = 'CTZ',
    CMM = 'CMM',
    CNV = 'CNV',
    DEP = 'DEP',
    EMI = 'EMI',
    NAT = 'NAT',
    NTL = 'NTL',
    OTS = 'OTS',
    PLM = 'PLM',
    REF = 'REF',
    SEA = 'SEA',
    SPT = 'SPT',
    STR = 'STR',
    TIM = 'TIM',
    WBC = 'WBC',
    WBI = 'WBI',
    WBS = 'WBS',
    CHD = 'CHD',
    SEN = 'SEN'
}

export enum ShopAcrossPassengerType {
    LNN = "CNN", MIC = "CNN", VNN = "CNN", UNN = "CNN", CHR = "CNN", YTH = "CNN", JNN = "CNN", CNE = "CNN", INN = "CNN",
    INS = "INF", LIF = "INF", LNS = "INF", JNF = "INF", JNS = "INF", INE = "INF", ITF = "INF", ITS = "INF",
    SRC = "ADT", CMP = "ADT", STU = "ADT", SPH = "ADT", SPA = "ADT", CFM = "ADT", LBR = "ADT", ADD = "ADT", MIS = "ADT", STE = "ADT", PRO = "ADT", MIR = "ADT", VAC = "ADT", VFR = "ADT", SEA = "ADT", CMA = "ADT", SPT = "ADT", TIM = "ADT", JCB = "ADT", NEG = "ADT", DIS = "ADT", BLD = "ADT", GDP = "ADT", GVT = "ADT", GV1 = "ADT", MIL = "ADT", MDP = "ADT", ITX = "ADT", CMM = "ADT"
}

export enum trainPaxCategories {
    CHD = 'CHD',
    ADT = 'ADT',
    SEN = 'SEN'
}

export enum flightClass {
    Y = 'economy',
    C = 'premium',
    S = 'business',
    F = 'first'
}

export class ProductType {
    static hotel: 'hotel';
    static flight: 'flight';
    static train: 'train';
    static car: 'car';
}

export type ICustomFieldType = 'company' | 'userProfile' | 'trip';

export const SSRMealType = [
    'AVML',
    'BBML',
    'BLML',
    'CHML',
    'DBML',
    'FPML',
    'GFML',
    'HNML',
    'KSML',
    'LCML',
    'LFML',
    'LSML',
    'MOML',
    'NLML',
    'NSML',
    'PFML',
    'RVML',
    'SFML',
    'SPML',
    'VGML',
    'VJML',
    'VLML',
    'VOML',
];

export const WheelChairType = [
    'WCHR',
    'WCHS',
    'WCHC',
    'WCBD',
    'WCBW',
    'WCMP',
    'WCOB',
    'WCLB'
];

export function isInfant(passengerType: string): boolean {
    return passengerType === flightPaxType.INF || passengerType === flightPaxType.ITF;
}

export function isAdult(passengerType: string): boolean {
    return passengerType === flightPaxType.ADT || passengerType === flightPaxType.ITX;
}

export function isChild(passengerType: string): boolean {
    return passengerType === flightPaxType.CHD || passengerType === flightPaxType.INN || passengerType === 'CNN';
}