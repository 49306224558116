import { ReactiveValidationMessageComponent } from './reactive-validation-message.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@shared/utils/utils.module';

@NgModule({
    imports: [
        CommonModule,
        UtilsModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [ReactiveValidationMessageComponent],
    exports: [ReactiveValidationMessageComponent]
})
export class ReactiveValidationMessageModule { }
