export class AppMenuItem {
    name = '';
    permissionName = '';
    icon = '';
    route = '';
    routeTemplates = [];
    items: AppMenuItem[];
    itemsNew: AppMenuItem[];
    itemsPermission: AppMenuItem[];
    hasSubMenu?: boolean;
    external: boolean;
    requiresAuthentication: boolean;
    featureDependency: any;
    parameters: {};
    level = 0;

    constructor(
        name: string,
        permissionName: string,
        icon: string,
        route: string,
        routeTemplates?: string[],
        items?: AppMenuItem[],
        hasSubMenu?: boolean,
        external?: boolean,
        parameters?: Object,
        featureDependency?: any,
        requiresAuthentication?: boolean,
        itemsNew?: AppMenuItem[],
        level?: number,
        itemsPermission?: AppMenuItem[],
    ) {
        this.name = name;
        this.permissionName = permissionName;
        this.icon = icon;
        this.route = route;
        this.routeTemplates = routeTemplates;
        this.external = external;
        this.parameters = parameters;
        this.featureDependency = featureDependency;
        this.hasSubMenu = hasSubMenu;
        this.itemsNew != itemsNew;
        this.level = level;
        this.itemsPermission != itemsPermission;

        if (items === undefined) {
            this.items = [];
        } else {
            this.items = items;
        }

        if (this.permissionName) {
            this.requiresAuthentication = true;
        } else {
            this.requiresAuthentication = requiresAuthentication ? requiresAuthentication : false;
        }
        hasSubMenu ? this.hasSubMenu = true : this.hasSubMenu = false;
    }

    hasFeatureDependency(): boolean {
        return this.featureDependency !== undefined;
    }

    featureDependencySatisfied(): boolean {
        if (this.featureDependency) {
            return this.featureDependency();
        }

        return false;
    }
}
