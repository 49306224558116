import {
    Component,
    OnInit,
    Injector,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    AfterViewInit,
    OnChanges,
    SimpleChanges,
    DoCheck
} from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CountryServiceProxy, CountryListDto } from '@shared/service-proxies/service-proxies';
import { Dropdown } from 'primeng/dropdown';
import { SelectItem } from 'primeng';

@Component({
    selector: 'app-countries',
    templateUrl: './countries.component.html',
})
export class CountriesComponent extends AppComponentBase implements OnInit, AfterViewInit, OnChanges, DoCheck {
    @Input()
    codSelectedCountry: string;
    @Input()
    isLabelVisible;
    @Input()
    materialStyle?: boolean;
    selectedCountry: SelectItem;
    @Output()
    selectedCountryChanged = new EventEmitter();
    @ViewChild('userNationality', { static: true })
    userNationality: Dropdown;
    nazioniSelectItems: SelectItem[] = [];
    @Input()
    requiredCountry;
    //campi per l'init da lista presa dal server nel father component. Serve settare anche
    //un booleano che dica al componente di non fare la chiamata al server all'init ma aspettare che arrivi il dato tramite OnChanges
    @Input()
    countryList: CountryListDto[];
    @Input()
    waitForCountryList = false;
    @Input() disabled = false;
    ////////
    constructor(
        private injector: Injector,
        private countryServiceProxy: CountryServiceProxy
    ) {
        super(injector);
    }
    ngDoCheck(): void {
        if (this.nazioniSelectItems.length >= 0 && (this.codSelectedCountry !== undefined)) {
            if (this.userNationality.selectedOption === null) {
                let selectedNation = this.nazioniSelectItems.find(o => o.value === this.codSelectedCountry);
                this.userNationality.selectedOption = selectedNation;
                this.userNationality.selectedOptionUpdated = true;
            }
        }
    }
    ngOnInit() {
        let selectedNation;
        if (!this.waitForCountryList) {
            this.countryServiceProxy.getAllCountries().subscribe(res => {
                res.forEach(element => {
                    this.nazioniSelectItems.push({ label: element.name, value: element.code });
                });
                selectedNation = this.nazioniSelectItems.find(o => o.value === this.codSelectedCountry);
                this.userNationality.selectedOption = selectedNation;
                this.userNationality.selectedOptionUpdated = true;
            });
        }
        selectedNation = this.nazioniSelectItems.find(o => o.value === this.codSelectedCountry);
        this.userNationality.selectedOption = selectedNation;
        this.userNationality.selectedOptionUpdated = true;
        this.selectedCountryChanged.emit(this.codSelectedCountry);
    }
    ngAfterViewInit() { }
    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.codSelectedCountry && changes.codSelectedCountry.currentValue) {
            if (this.userNationality) {
                if (this.nazioniSelectItems.length > 0) {
                    let selectedNation = this.nazioniSelectItems.find(o => o.value === changes.codSelectedCountry.currentValue);
                    this.userNationality.selectedOption = selectedNation;
                    this.userNationality.selectedOptionUpdated = true;
                } else if (this.nazioniSelectItems.length === 0 && (this.countryList && this.countryList.length !== 0)) {
                    this.countryList = changes.countryList.currentValue;
                    this.countryList.forEach(element => {
                        this.nazioniSelectItems.push({ label: element.name, value: element.code });
                    });
                    let selectedNation = this.nazioniSelectItems.find(o => o.value === this.codSelectedCountry);
                    this.userNationality.selectedOption = selectedNation;
                    this.userNationality.selectedOptionUpdated = true;
                }
            }
        }
        if (changes && changes.countryList && changes.countryList.currentValue) {
            if (changes.countryList.currentValue.length > 0) {
                if (this.nazioniSelectItems.length === 0) {
                    this.countryList = changes.countryList.currentValue;
                    this.countryList.forEach(element => {
                        this.nazioniSelectItems.push({ label: element.name, value: element.code });
                    });
                    let selectedNation = this.nazioniSelectItems.find(o => o.value === this.codSelectedCountry);
                    this.userNationality.selectedOption = selectedNation;
                    this.userNationality.selectedOptionUpdated = true;
                }
            }
        }
    }

    SendChangeCountryNotification() {
        this.selectedCountryChanged.emit(this.selectedCountry);
    }
}
