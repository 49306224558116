<div class="card card-custom kt-portlet--height-fluid">
    <div class="card-body kt-portlet__body--fit" [perfectScrollbar]>
        <div class="row row-no-padding row-col-separator-xl">
            <div class="col-md-12 col-lg-6 col-xl-3">
                <div class="kt-widget24">
                    <div class="kt-widget24__details">
                        <div class="kt-widget24__info">
                            <h4 class="kt-widget24__title">
                                {{"Tenant_Dashboard_TotalProfit" | localize}}
                            </h4>
                            <span class="kt-widget24__desc">
                                {{"Tenant_Dashboard_TotalProfit_Subtitle" | localize}}
                            </span>
                        </div>
                        <span class="kt-widget24__stats kt--font-brand">
                            <span counto [step]="30" [duration]="1" [countFrom]="0" [countTo]="totalProfit"
                                (countoChange)="totalProfitCounter = $event">
                                {{totalProfitCounter | currency:"EUR":'symbol':"1.0-0"}}
                            </span>
                        </span>
                    </div>
                    <div class="progress progress--sm">
                        <div class="progress-bar kt-bg-brand" role="progressbar" style="width: 76%;" aria-valuenow="50"
                            aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>

            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">
                <div class="kt-widget24">
                    <div class="kt-widget24__details">
                        <div class="kt-widget24__info">
                            <h4 class="kt-widget24__title">
                                {{"Tenant_Dashboard_Orders" | localize}}
                            </h4>
                            <span class="kt-widget24__desc">
                                {{"Tenant_Dashboard_Orders_Subtitle" | localize}}
                            </span>
                        </div>
                        <div class="kt-widget24__stats kt--font-brand"><span counto [step]="30" [duration]="1"
                                [countFrom]="0" [countTo]="newOrders" (countoChange)="newOrdersCounter = $event">
                                {{newOrdersCounter?.toFixed(0)}}
                            </span>
                        </div>
                    </div>
                    <div class="progress progress--sm">
                        <div class="progress-bar kt-bg-info" role="progressbar" style="width: 85%;" aria-valuenow="50"
                            aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">
                <div class="kt-widget24">
                    <div class="kt-widget24__details">
                        <div class="kt-widget24__info">
                            <h4 class="kt-widget24__title">
                                {{"Tenant_Dashboard_Segments" | localize}}
                            </h4>
                            <span class="kt-widget24__desc">
                                {{"Tenant_Dashboard_Segments_Subtitle" | localize}}
                            </span>
                        </div>
                        <div class="kt-widget24__stats kt--font-brand"><span counto [step]="30" [duration]="1"
                                [countFrom]="0" [countTo]="segments" (countoChange)="segmentsCounter = $event">
                                {{segments?.toFixed(0)}}
                            </span>
                        </div>
                    </div>
                    <div class="progress progress--sm">
                        <div class="progress-bar kt-bg-danger" role="progressbar" style="width: 45%;" aria-valuenow="50"
                            aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">
                <div class="kt-widget24">
                    <div class="kt-widget24__details">
                        <div class="kt-widget24__info">
                            <h4 class="kt-widget24__title">
                                {{"Analytics_Dashboard_Reservation_Schedule_Ratio" | localize}}
                            </h4>
                            <span class="kt-widget24__desc">
                                {{"Analytics_Dashboard_Reservation_Schedule_Ratio_Subtitle" | localize}}
                            </span>
                        </div>
                        <div class="kt-widget24__stats kt--font-brand" *ngIf="orderSegmentRatio"><span counto
                                [step]="30" [duration]="1" [countFrom]="0" [countTo]="orderSegmentRatio"
                                (countoChange)="orderSegmentRatioCounter = $event">
                                {{orderSegmentRatio | number: '1.0-2'}}
                            </span>
                        </div>
                    </div>
                    <div class="progress progress--sm">
                        <div class="progress-bar kt-bg-danger" role="progressbar" style="width: 45%;" aria-valuenow="50"
                            aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
