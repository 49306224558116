import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { FormSearchFlightComponent } from '@app/flight/form-search-flight/form-search-flight.component';
import { AppCommonModule } from '@app/shared/common/app-common.module';
import { ReactiveValidationMessageModule } from '@app/shared/components/reactive-validation-message/reactive-validation-message.module';
import { QueryStringBuilderService } from '@app/shared/helpers/query-string-builder.service';
import { TypePaxFilterPipe } from '@app/shared/pipes/type-pax-filter.pipe';
import { AvailabilityRequestMulticityDTOService } from '@app/shared/services/flight/availability-request-client.service';
import { FlightPassengerService } from '@app/shared/services/flight/flight-passenger.service';
import { FormSearchFlightClientService } from '@app/shared/services/flight/form-search-flight.service';
import { UtilsModule } from '@shared/utils/utils.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { GreaterThanOrEqualToTimeStringPipe, GreaterThanTimeStringPipe } from '../../shared/pipes/greater-than-time-string.pipe';
import { FlightHistoryListModule } from '../flight-history-list/flight-history-list.module';
import { FlightWizardModule } from './../flight-wizard/flight-wizard.module';
import { LegDatetimepickerComponent } from './multicity-flight/leg-datetimepicker/leg-datetimepicker.component';
import { MulticityFlightComponent } from './multicity-flight/multicity-flight.component';
import { NumberPickerModule } from './passengers-selection/number-picker/number-picker.module';
import { PassengersSelectionComponent } from './passengers-selection/passengers-selection.component';
import { RegularFlightComponent } from './regular-flight/regular-flight.component';
import { DepartureRangeTimeComponent } from './shared/departure-range-time/departure-range-time.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule, BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { MultiSourceAutocompleteModule } from './shared/multi-source-autocomplete/multi-source-autocomplete.module';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
    imports: [
        CommonModule,
        UtilsModule,
        FormsModule,
        AppCommonModule,
        ReactiveFormsModule,
        AutoCompleteModule,
        MatAutocompleteModule,
        MatInputModule,
        MatFormFieldModule,
        MatCardModule,
        BsDropdownModule.forRoot(),
        FlightWizardModule,
        MatButtonModule,
        MatSlideToggleModule,
        NumberPickerModule,
        MultiSelectModule,
        MatCheckboxModule,
        FlightHistoryListModule,
        TooltipModule.forRoot(),
        MatInputModule,
        ReactiveValidationMessageModule,
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        MultiSourceAutocompleteModule,
        MatIconModule
    ],
    declarations: [
        PassengersSelectionComponent,
        RegularFlightComponent,
        MulticityFlightComponent,
        LegDatetimepickerComponent,
        FormSearchFlightComponent,
        TypePaxFilterPipe,
        GreaterThanTimeStringPipe,
        GreaterThanOrEqualToTimeStringPipe,
        DepartureRangeTimeComponent
    ],
    exports: [FormSearchFlightComponent],
    providers: [
        FlightPassengerService,
        FormSearchFlightClientService,
        AvailabilityRequestMulticityDTOService,
        QueryStringBuilderService,
        { provide: BsDatepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig },
        { provide: BsLocaleService, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerLocale },
    ]
})
export class FormSearchFlightModule {}
