import { Injectable, OnInit } from '@angular/core';
import {
    WidgetViewDefinition,
    WidgetFilterViewDefinition,
} from './definitions';
import { DashboardCustomizationConst } from './DashboardCustomizationConsts';
import { WidgetGeneralStatsComponent } from './widgets/widget-general-stats/widget-general-stats.component';
import { WidgetDailySalesComponent } from './widgets/widget-daily-sales/widget-daily-sales.component';
import { WidgetProfitShareComponent } from './widgets/widget-profit-share/widget-profit-share.component';
import { WidgetMemberActivityComponent } from './widgets/widget-member-activity/widget-member-activity.component';
import { WidgetRegionalStatsComponent } from './widgets/widget-regional-stats/widget-regional-stats.component';
import { WidgetSalesSummaryComponent } from './widgets/widget-sales-summary/widget-sales-summary.component';
import { WidgetIncomeStatisticsComponent } from './widgets/widget-income-statistics/widget-income-statistics.component';
import { WidgetRecentTenantsComponent } from './widgets/widget-recent-tenants/widget-recent-tenants.component';
import { WidgetEditionStatisticsComponent } from './widgets/widget-edition-statistics/widget-edition-statistics.component';
import { WidgetSubscriptionExpiringTenantsComponent } from './widgets/widget-subscription-expiring-tenants/widget-subscription-expiring-tenants.component';
import { WidgetHostTopStatsComponent } from './widgets/widget-host-top-stats/widget-host-top-stats.component';
import { FilterDateRangePickerComponent } from './filters/filter-date-range-picker/filter-date-range-picker.component';
import { WidgetTopStatsComponent } from './widgets/widget-top-stats/widget-top-stats.component';
import { WidgetOrderTypesComponent } from './widgets/widget-order-types/widget-order-types.component';
import { WidgetSalesByCompanyComponent } from './widgets/widget-sales-by-company/widget-sales-by-company.component';
import { WidgetSalesAirlineCompaniesComponent } from './widgets/widget-sales-airline-companies/widget-sales-airline-companies.component';
import { WidgetSalesScheduleCompaniesComponent } from './widgets/widget-sales-schedule-companies/widget-sales-schedule-companies.component';
import { WidgetSalesFlightTypeComponent } from './widgets/widget-sales-flight-type/widget-sales-flight-type.component';
import { WidgetAnalyticsTopStatsComponent } from './widgets/widget-analytics-top-stats/widget-analytics-top-stats.component';
import { FilterAgencyComponent } from './filters/filter-agency/filter-agency.component';
import { WidgetDownloadShowFileComponent } from './widgets/widget-download-show-file/widget-download-show-file.component';
import { WidgetNewsComponent } from './widgets/widget-news/widget-news.component';

@Injectable({
    providedIn: 'root',
})
export class DashboardViewConfigurationService {
    public WidgetViewDefinitions: WidgetViewDefinition[] = [];
    public widgetFilterDefinitions: WidgetFilterViewDefinition[] = [];

    constructor() {
        this.initializeConfiguration();
    }

    private initializeConfiguration() {
        let filterDateRangePicker = new WidgetFilterViewDefinition(
            DashboardCustomizationConst.filters.filterDateRangePicker,
            FilterDateRangePickerComponent
        );
        //add your filters here

        let agencyFilter = new WidgetFilterViewDefinition(
            DashboardCustomizationConst.filters.agencyFilter,
            FilterAgencyComponent
        );

        this.widgetFilterDefinitions.push(filterDateRangePicker);

        //custom filters
        this.widgetFilterDefinitions.push(agencyFilter);

        let generalStats = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.generalStats,
            WidgetGeneralStatsComponent,
            6,
            4
        );

        let dailySales = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.dailySales,
            WidgetDailySalesComponent
        );

        let profitShare = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.profitShare,
            WidgetProfitShareComponent
        );

        let memberActivity = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.memberActivity,
            WidgetMemberActivityComponent
        );

        let regionalStats = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.regionalStats,
            WidgetRegionalStatsComponent
        );

        let salesSummary = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.salesSummary,
            WidgetSalesSummaryComponent
        );

        let topStats = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.topStats,
            WidgetTopStatsComponent
        );
        //add your tenant side widgets here
        let fileShowDownload = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.fileShowDownload,
            WidgetDownloadShowFileComponent
        );

        let news = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.news,
            WidgetNewsComponent
        );

        let incomeStatistics = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.host.incomeStatistics,
            WidgetIncomeStatisticsComponent
        );

        let editionStatistics = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.host.editionStatistics,
            WidgetEditionStatisticsComponent
        );

        let recentTenants = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.host.recentTenants,
            WidgetRecentTenantsComponent
        );

        let subscriptionExpiringTenants = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.host.subscriptionExpiringTenants,
            WidgetSubscriptionExpiringTenantsComponent
        );

        let hostTopStats = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.host.topStats,
            WidgetHostTopStatsComponent
        );
        //add your host side widgets here
        let hostFileShowDownload = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.host.fileShowDownload,
            WidgetDownloadShowFileComponent
        );

        let hostNews = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.host.news,
            WidgetNewsComponent
        );
        //analytics widget

        let orderTypeStats = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.analytics.orderTypeStats,
            WidgetOrderTypesComponent
        );

        let salesByAgency = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.analytics.salesByAgency,
            WidgetSalesByCompanyComponent
        );

        let salesByAirlineCompanies = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.analytics.salesByAirlineCompanies,
            WidgetSalesAirlineCompaniesComponent
        );

        let salesBySchedulesAndCompanies = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.analytics.salesBySchedulesAndCompanies,
            WidgetSalesScheduleCompaniesComponent
        );

        let salesFlightType = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.analytics.salesFlightType,
            WidgetSalesFlightTypeComponent
        );

        let topStatsAnalytics = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.analytics.topStats,
            WidgetAnalyticsTopStatsComponent
        );

        // this.WidgetViewDefinitions.push(generalStats);
        // this.WidgetViewDefinitions.push(dailySales);
        // this.WidgetViewDefinitions.push(profitShare);
        // this.WidgetViewDefinitions.push(memberActivity);
        // this.WidgetViewDefinitions.push(regionalStats);
        // this.WidgetViewDefinitions.push(salesSummary);
        // this.WidgetViewDefinitions.push(topStats);

        this.WidgetViewDefinitions.push(fileShowDownload);
        this.WidgetViewDefinitions.push(news);
        this.WidgetViewDefinitions.push(incomeStatistics);
        this.WidgetViewDefinitions.push(editionStatistics);
        this.WidgetViewDefinitions.push(recentTenants);
        this.WidgetViewDefinitions.push(subscriptionExpiringTenants);
        this.WidgetViewDefinitions.push(hostFileShowDownload);
        this.WidgetViewDefinitions.push(hostNews);
        this.WidgetViewDefinitions.push(hostTopStats);
        this.WidgetViewDefinitions.push(orderTypeStats); //analytics widget
        this.WidgetViewDefinitions.push(salesByAgency);
        this.WidgetViewDefinitions.push(salesByAirlineCompanies);
        this.WidgetViewDefinitions.push(salesBySchedulesAndCompanies);
        this.WidgetViewDefinitions.push(salesFlightType);
        this.WidgetViewDefinitions.push(topStatsAnalytics);
    }
}
