import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PwaUpdateModalComponent } from './pwa-update-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MatIconModule } from '@angular/material/icon';
import { UtilsModule } from '@shared/utils/utils.module';

@NgModule({
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    MatIconModule,
    UtilsModule
  ],
  declarations: [PwaUpdateModalComponent],
  exports: [PwaUpdateModalComponent]
})
export class PwaUpdateModalModule { }
