import * as moment from 'moment';
import { ReservationStatusDto, PaymentStatusDto, AvailabilityItineraryMulticityDTO, AvailabilityRequestMulticityDTO, NameValueDto, PassengerTypeDTO, AirportDTO, PassengerDTO, LocationDTO, GuestCompositionDto, FidelityDTO } from '@shared/service-proxies/service-proxies';
import { orderBy } from 'lodash';
import { IScheduleInfo } from '@app/flight/search-result/filter-panel/filter-panel.component';
import { MyMulticityDTO } from '@app/shared/models/ClientFlightModels';
import { MyTrainAvailabilityRequestDTO } from '@app/shared/models/client-train-models';
import { ISearchHistory } from '@app/shared/models/search-history.model';
import { AppConsts } from '@shared/AppConsts';

export function getBadgeReservationStatus(reservStatus: number) {
    switch (reservStatus) {
        case ReservationStatusDto.Booked: {
            return 'info';
        }
        case ReservationStatusDto.Confirmed: {
            return 'success';
        }
        case ReservationStatusDto.Error: {
            return 'danger';
        }
        case ReservationStatusDto.Queued:
        case ReservationStatusDto.Pending: {
            return 'warning';
        }
        case ReservationStatusDto.Deleted:
        case ReservationStatusDto.Created:
        default: {
            return 'secondary';
        }
    }
}

export function getBadgePaymentStatus(PaymentStatus: number) {
    switch (PaymentStatus) {
        case PaymentStatusDto.Successful: {
            return 'success';
        }
        case PaymentStatusDto.Cancelled: {
            return 'warning';
        }
        case PaymentStatusDto.Failed: {
            return 'danger';
        }
        default: {
            return 'secondary';
        }
    }
}

export function getTktNumberString(ticketInfo, nameNumber) {
    let ticketsArray = getEticketNumber(ticketInfo, nameNumber);
    return ticketsArray.filter(tkt => tkt.transactionIndicator !== 'ME' && tkt.transactionIndicator !== 'MV').map(tkt => tkt.eTicketNumber + getTransactionIndicatorString(tkt.transactionIndicator)).join(' | ');
}

export function getEMDNumberString(ticketInfo, nameNumber) {
    let ticketsArray = getEticketNumber(ticketInfo, nameNumber);
    return ticketsArray.filter(tkt => tkt.transactionIndicator === 'ME' || tkt.transactionIndicator === 'MV').map(tkt => tkt.eTicketNumber + getTransactionIndicatorString(tkt.transactionIndicator)).join(' | ');
}

function getEticketNumber(ticketInfo, nameNumber) {
    let arrayNameNumberSplitted = nameNumber.split('.');

    let nameNumberResult = arrayNameNumberSplitted[0].replace('0', '') + '.' + arrayNameNumberSplitted[1].replace('0', '');

    let TicketItems = [];
    let TicketIssue = [];
    let TicketVoid = [];

    // Recupero tutti i ticket per il pax corrente dividendo i tkt emessi da quelli void
    ticketInfo.filter(function (element, index) {
        if (element.nameNumber === nameNumberResult) {
            if (element.transactionIndicator !== 'TV' && element.transactionIndicator !== 'TR' && element.transactionIndicator !== 'MV') {
                TicketIssue.push(element);
            } else {
                TicketVoid.push(element);
            }
        }
    });
    // Imposto l'array per la visualizzazione ciclando sui tkt emessi e verificando se è presente il corrispondente tkt void
    TicketIssue.forEach(function (element) {
        let tktVoid = TicketVoid.find(x => x.eTicketNumber === element.eTicketNumber);
        if (tktVoid != undefined && tktVoid != null) {
            TicketItems.push(tktVoid);
        } else {
            TicketItems.push(element);
        }
    });

    return TicketItems;
}

function getTransactionIndicatorString(transactionIndicator): string {
    if (transactionIndicator == null) {
        return '';
    }

    switch (transactionIndicator) {
        case 'TE':
            return '';
        case 'TV':
            return ' (TKT VOID)';
        case 'TR':
            return ' (TKT REFUND)';
        case 'ME':
            return ' (EMD)';
        case 'MV':
            return ' (EMD VOID)';
        default:
            return ' (' + transactionIndicator + ')';
    }
}

export function setHourAndMinutes(date: moment.Moment, hourMinutes: string) {
    try {
        if (hourMinutes) {
            let splitHourMinutes = hourMinutes.split(':');
            return moment(date, 'YYYY-MM-DD').hours(parseInt(splitHourMinutes[0])).minutes(parseInt(splitHourMinutes[1]));
        }
    } catch (e) {
        return date;
    }
}

export function scheduleInfo(data: Array<AvailabilityItineraryMulticityDTO>, numberOfSchedule: number, scheduleCondition: 'max' | 'only' = 'max'): Array<IScheduleInfo> {
    const scheduleInfos: Array<IScheduleInfo> = [];
    data.map(element => {
        element.legs.map((leg, legIndex) => {
            if (numberOfSchedule !== -1) {
                if (scheduleCondition === 'only') {
                    data = data.filter(element => element.legs[legIndex].schedulesCount === numberOfSchedule);
                } else if (scheduleCondition === 'max'){
                    data = data.filter(element => element.legs[legIndex].schedulesCount <= numberOfSchedule);
                }
            }
            data = orderBy(data, (element) => element.selectedPricingInformation.fare.totalFare.totalDue, 'asc');

            scheduleInfos.push({
                scheduleValue: numberOfSchedule,
                totalElements: data.length,
                bestPrice: data.length > 0 ? data[0].selectedPricingInformation.fare.totalFare.totalDue : undefined
            });
        });
    });

    return scheduleInfos;
}

export function typeAvailabilityRequestMulticityDTO(model: AvailabilityRequestMulticityDTO) : AvailabilityRequestMulticityDTO {
    const request = new AvailabilityRequestMulticityDTO(model);


    if (request.companiesFilter) {
        const companiesFilter = new Array<NameValueDto>();
        request.companiesFilter.map(company => {
            companiesFilter.push(new NameValueDto(company));
        });

        request.companiesFilter = companiesFilter;
    }

    if (request.passengers) {
        const passengers: Array<PassengerTypeDTO> = [];
        request.passengers.map(passenger => {
            passengers.push(new PassengerTypeDTO(passenger));
        });
        request.passengers = passengers;
    }
    if (request.legs) {
        const legs = Array<MyMulticityDTO>();
        request.legs.map(leg => {
            leg.departureDate = moment(leg.departureDate);
            leg.originAirport = new AirportDTO(leg.originAirport);
            leg.destinationAirport = new AirportDTO(leg.destinationAirport);
            legs.push(new MyMulticityDTO(leg));
        });

        request.legs = legs;
    }
    return request;
}

export function typeTrainAvailabilityRequestDTO(model: MyTrainAvailabilityRequestDTO) {
    const request = new MyTrainAvailabilityRequestDTO(model);

    if (model.departureDate) {
        request.departureDate = moment(model.departureDate);
    }

    if (model.returnDate) {
        request.returnDate = moment(model.returnDate);
    }
    if (request.passengers) {
        const passengers: Array<PassengerDTO> = [];
        request.passengers.map(passenger => {
            const newPassenger = new PassengerDTO(passenger);
            if (newPassenger.fidelity) {
                newPassenger.fidelity = new FidelityDTO(newPassenger.fidelity);
            }
            passengers.push(newPassenger);
        });
        request.passengers = passengers;
    }

    request.originStation = new LocationDTO(request.originStation);
    request.destinationStation = new LocationDTO(request.destinationStation);
    return request;
}

export function typeGuestComposition(guestComposition: Array<any>): Array<GuestCompositionDto> {
    const typedGuestComposition: Array<GuestCompositionDto> = [];
    guestComposition.map((guestComposition) => {
        typedGuestComposition.push(new GuestCompositionDto(guestComposition));
    });

    return typedGuestComposition;
}

export function checkHistoryItemsVersion(items: Array<ISearchHistory>) {
    return items.filter(item => (item.version && item.version >= AppConsts.HISTORY_MIN_VERSION));
}
