import { Component, OnInit, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ActivatedRoute } from '@angular/router';
import { TripExternalApprovalServiceProxy } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'app-trip-approval-outcome',
    templateUrl: './trip-approval-outcome.component.html'
})
export class TripApprovalOutcomeComponent extends AppComponentBase
    implements OnInit {
    waiting: boolean;
    waitMessage: string;
    successfulMessage: string;
    queryParams: string;

    constructor(
        injector: Injector,
        private _activatedRoute: ActivatedRoute,
        private _tripService: TripExternalApprovalServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit() {
        this.spinnerService.show();
        this.waitMessage = this.l('Common_Wait');
        this.successfulMessage = this.l('Common_Successful_Operation');
        this.waiting = true;
        this.queryParams = this._activatedRoute.snapshot.queryParams['c'];
        this._tripService.tripApprovalOutcome(this.queryParams).subscribe(
            res => {
                let tmp = res;
                this.waiting = false;
                this.spinnerService.hide();
                this.message.success(this.l('Common_Successful_Operation'));
            },
            err => {
                this.waiting = false;
                this.spinnerService.hide();
            }
        );
    }
}
