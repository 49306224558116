import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TwoStepFlightSerpComponent } from './two-step-flight-serp.component';
import { TravelItemModule } from '../serp-body/travel-item/travel-item.module';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { LegTravelItemModule } from '../serp-body/leg-travel-item/leg-travel-item.module';
import { TwoStepSerpOrderModule } from './two-step-serp-order/two-step-serp-order.module';
import { SearchResultUtilitiesModule } from '@app/shared/directives/search-result-utilities/search-result-utilities.module';
import { PopoverOutViewportModule } from '@app/shared/directives/popover-out-viewport/popover-out-viewport.module';
import { UtilsModule } from '@shared/utils/utils.module';

@NgModule({
    imports: [
        CommonModule,
        TravelItemModule,
        MatStepperModule,
        LegTravelItemModule,
        MatIconModule,
        UtilsModule,
        TwoStepSerpOrderModule,
        SearchResultUtilitiesModule,
        PopoverOutViewportModule
    ],
    exports: [TwoStepFlightSerpComponent],
    declarations: [TwoStepFlightSerpComponent]
})
export class TwoStepFlightSerpModule { }
