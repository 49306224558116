import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(
        private route: Router
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>>
    {
        let url: string = this.route.routerState.snapshot.url;

        // Regex per stringhe nel formato "..../app/trip/<trip-id-24-caratteri-alfanumerici>/...."
        let regex = new RegExp('^.*\/app\/trip\/[a-zA-Z0-9]{24}\/.*$');

        if(regex.test(url))
        {
            // Regex per estrarre solo "/trip/<trip-id-24-caratteri-alfanumerici>"
            let rx = /\/trip\/[a-zA-Z0-9]{24}/g;
            let urlPiece: string = rx.exec(url)[0];

            // Regex per estrarre solo "<trip-id-24-caratteri-alfanumerici>"
            let rx2 = /[a-zA-Z0-9]{24}/g;
            let tripId: string = rx2.exec(urlPiece)[0];

            if(tripId)
            {
                const newReq = req.clone({
                    headers: req.headers.set("TripId", tripId)
                });
                return next.handle(newReq);
            }
        }

        return next.handle(req);
    }
}
