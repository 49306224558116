<div class="card card-custom h-100">
    <div class="card-header align-items-center border-0">
        <h3 class="card-title align-items-start flex-column">
                <span class="font-weight-bolder text-dark">
                    Member Activity
                </span>
        </h3>
        <div class="card-toolbar">
            <button (click)="memberActivityTable.reload()" class="btn btn-primary"><i
                class="fa fa-sync"></i> Refresh</button>
        </div>
    </div>
    <div class="card-body" [perfectScrollbar]>
        <div class="table-scrollable table-scrollable-borderless">
            <table class="table table-hover table-light">
                <thead>
                <tr class="uppercase">
                    <th colspan="2">
                        MEMBER
                    </th>
                    <th>
                        Earnings
                    </th>
                    <th>
                        CASES
                    </th>
                    <th>
                        CLOSED
                    </th>
                    <th>
                        RATE
                    </th>
                </tr>
                </thead>
                <tr *ngFor="let m of memberActivityTable.memberActivities; let index = index">
                    <td class="pl-0">
                        <div class="symbol symbol-50 symbol-light mt-2 mr-2">
                            <div class="symbol-label">
                                <img alt="photo"
                                     src="/assets/metronic/assets/media/svg/avatars/00{{index + 1}}.svg" class="h-75 align-self-end">
                            </div>
                        </div>
                    </td>
                    <td class="pl-0">
                        <a href="javascript:;" class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">{{m.name}}</a>
                    </td>
                    <td>
                        {{m.earnings}}
                    </td>
                    <td>
                        {{m.cases}}
                    </td>
                    <td>
                        {{m.closed}}
                    </td>
                    <td>
                        <span class="bold font-green-haze">{{m.rate}}</span>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>